const gql = require('graphql-tag');
const { CUSTOM_QUERIES } = require('../../../../constants/customQueries');

const setDefaultShippingAddressQuery = {
  [CUSTOM_QUERIES.SHIPPING.setDefaultAddress.customSetDefaultShippingAddress]: ({ variables }) => ({
    query: gql`
      mutation setDefaultShippingAddress(
        $addressId: String!
        $version: Long!
        $locale: Locale!
        $acceptLanguage: [Locale!]
      ) {
        customer: updateCustomer(
          version: $version
          actions: [
            {
              setDefaultShippingAddress: {
                addressId: $addressId
              }
            }
          ]
        ) {
          version
          shippingAddressIds
          addresses {
            id
            firstName
            lastName
            streetName
            streetNumber
            postalCode
            city
            country
            phone
            isDefaultShippingAddress
            isDefaultBillingAddress
          }
        }
      }
    `,
    variables: {
      addressId: variables.addressId,
      version: variables.version,
      locale: variables.locale,
      acceptLanguage: variables.acceptLanguage,
    },
  }),
};

module.exports = setDefaultShippingAddressQuery;
