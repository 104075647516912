import { AxiosResponse } from 'axios';
import { CiaIntegration } from '~/types/integrations/cia/CiaIntegration';
import type { ViewGuaranteeRequest } from '~/types/integrations/cia/event/view-guarantee/ViewGuaranteeRequest';
import { BLOOMREACH_COOKIE } from '~/constants/bloomreach';

export default (instance: CiaIntegration) => {
  const { axios, cookies } = instance.services;
  const { email, locale, userId, posthogDistinctId } = instance.state;

  return (sku: string, guaranteePlacement: string, pricePercent: string): Promise<AxiosResponse> | undefined => {
    if (!sku) {
      return;
    }

    const payload: ViewGuaranteeRequest = {
      locale: locale.value,
      sku,
      cookie: cookies?.get(BLOOMREACH_COOKIE),
      posthog_identifier: posthogDistinctId.value,
      guarantee_placement: guaranteePlacement,
      price_percent: pricePercent.toString(),
    };

    if (email.value) {
      payload.email = email.value;
    }

    if (userId.value) {
      payload.user_id = userId.value;
    }

    return axios.post('view-guarantee', payload);
  };
};
