import { computed } from '@nuxtjs/composition-api';
import {
  useStoreVsfExtended,
  useComplementarySidebar,
  useFilterModalUi,
  useExtraGuaranteeSidebar,
} from '~/composables';

const useUiState = () => {
  const {
    isComplementarySidebarOpen: isSidebarOpen,
    setIsComplementarySidebarOpen,
  } = useComplementarySidebar();
  const { isExtraGuaranteeOpen, setIsExtraGuaranteeOpen } = useExtraGuaranteeSidebar();
  const { isComplementarySidebarEnabled } = useStoreVsfExtended();
  const { isFilterModalOpen, setFilterModalOpen } = useFilterModalUi();

  const isComplementarySidebarOpen = computed(() =>
    isSidebarOpen.value && isComplementarySidebarEnabled.value);

  const toggleFilterModal = () => {
    setFilterModalOpen(!isFilterModalOpen.value);
  };

  const toggleComplementarySidebar = () => {
    setIsComplementarySidebarOpen(!isSidebarOpen.value);
  };

  const toggleExtraGuarantee = () => {
    setIsExtraGuaranteeOpen(!isExtraGuaranteeOpen.value);
  };

  return {
    isFilterModalOpen,
    toggleFilterModal,
    isExtraGuaranteeOpen,
    toggleExtraGuarantee,
    isComplementarySidebarOpen,
    toggleComplementarySidebar,
  };
};

export default useUiState;
