import { debounce } from 'lodash';
import type { DebounceSettings } from 'lodash';

type AsyncDebounceFunction = (...args: any[]) => Promise<any>;
type AsyncDebounceReturn<T extends AsyncDebounceFunction> = (...args: Parameters<T>) => Promise<ReturnType<T>>;

export function asyncDebounce<T extends AsyncDebounceFunction>(
  func: T, wait?: number, options?: DebounceSettings): AsyncDebounceReturn<T> {
  const debounced = debounce((resolve, reject, args) => {
    func(...args).then(resolve).catch(reject);
  }, wait, options);
  return (...args) =>
    new Promise((resolve, reject) =>
      debounced(resolve, reject, args),
    );
}
