import { Logger } from '@vue-storefront/core';

const redirectToAbsolutePath = ({
  oldPrefix, redirect, path, query,
}) => {
  Logger.warn(`Old url structure ${oldPrefix} is still being used.`);
  redirect(path.replace(oldPrefix, '/'), query);
};

export default ({ app: { context: { route } }, redirect }) => {
  const { query, path } = route;
  const oldPrefixForProductPage = '/p/';
  const oldPrefixForCategoryCmsPage = '/category/';
  const oldPrefixForCategorySearchPage = '/search/';

  if (path.includes(oldPrefixForProductPage)) {
    redirectToAbsolutePath({
      oldPrefix: oldPrefixForProductPage,
      redirect,
      path,
      query,
    });
  } else if (path.includes(oldPrefixForCategoryCmsPage)) {
    redirectToAbsolutePath({
      oldPrefix: oldPrefixForCategoryCmsPage,
      redirect,
      path,
      query,
    });
  } else if (path.includes(oldPrefixForCategorySearchPage)) {
    redirectToAbsolutePath({
      oldPrefix: oldPrefixForCategorySearchPage,
      redirect,
      path,
      query,
    });
  }
};
