const { gql } = require('graphql-tag');
const DefaultCart = require('../fragments/defaultCart').fragment;
const { CUSTOM_QUERIES } = require('#~/constants/customQueries.js');

/*
 * No changes in mutation itself. Uses modified fragments - changes documented in respective files.
 */
module.exports = {
  [CUSTOM_QUERIES.UPDATE_CART_CUSTOM.updateCart]: (params) => {
    const variables = params.variables;
    return {
      query: gql`
        ${DefaultCart}

        mutation updateCart(
          $id: String!,
          $version: Long!,
          $actions: [MyCartUpdateAction!]!,
          $locale: Locale!,
          $acceptLanguage: [Locale!],
          $currency: Currency!,
          $storeKey: KeyReferenceInput
        ) {
          cart: updateMyCart(id: $id, version: $version, actions: $actions, storeKey: $storeKey) {
            ...DefaultCart
          }
        }
      `,
      variables,
    };
  },
};
