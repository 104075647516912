import { UseMyAccountPage } from '~/types/myaccount/UseMyAccountPage';
import { ROUTES } from '~/constants/routes';
import { useAccountInternalPage } from '~/composables/useAccountInternalPage';

export const useLogoutPage: UseMyAccountPage = (route = ROUTES.LOG_OUT, translationKey = 'Log out') => {
  const page = useAccountInternalPage(route, translationKey);
  const isInternal = false;

  return {
    ...page,
    isInternal,
  };
};
