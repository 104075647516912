












import { computed, defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'ScrollIndicator',
  props: {
    viewportWidth: {
      type: Number,
      required: true,
    },
    totalWidth: {
      type: Number,
      required: true,
    },
    position: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const calculatePercentage = (value: number, total: number): string => {
      if (!value || !total) {
        return '0%';
      }
      const position = Math.min((value / total), 1);
      return `${position * 100}%`;
    };

    const indicatorWidth = computed<string>(() => {
      return calculatePercentage(props.viewportWidth, props.totalWidth);
    });
    const indicatorPosition = computed<string>(() => {
      return calculatePercentage(props.position, props.totalWidth);
    });

    return {
      indicatorWidth,
      indicatorPosition,
    };
  },
});
