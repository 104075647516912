import { sharedRef, useVSFContext, Logger } from '@vue-storefront/core';
import type { InventoryEntry, LineItem, Cart } from '@vsf-enterprise/commercetools-types';
import type { Ref } from '@nuxtjs/composition-api';
type SkuType = InventoryEntry['sku'];

/*
 * Custom composable for fetching product stock from commercetools Inventory endpoint.
 */
export const useStock = (cachingKey: string) => {
  const context = useVSFContext();
  const result: Ref<InventoryEntry[] | null> = sharedRef(null, `useStock-${cachingKey}`);

  const loading: Ref<boolean> = sharedRef(false, `useStock-loading-${cachingKey}`);

  const error: Ref<{search: null | string}> = sharedRef({
    search: null,
  }, `useStock-error-${cachingKey}`);

  const getStocks = async (params: { sku: SkuType | SkuType[], cartId?: Cart['id'] }) => {
    Logger.debug('inventory', params);

    try {
      loading.value = true;
      if (Array.isArray(params.sku) && params.sku.length === 0) {
        result.value = [];
      } else {
        const apiCallResult = await context.$ct.api.getInventory(params);
        result.value = apiCallResult?.data?.inventoryEntries?.results || [];
      }
      error.value.search = null;
    } catch (err) {
      error.value.search = err ? String(err) : null;
      Logger.error('inventory', err);
    } finally {
      loading.value = false;
    }
  };

  const setStock = (value: InventoryEntry[]) => {
    result.value = value;
  };

  const findStockBySku = (sku: SkuType): InventoryEntry | undefined => {
    return result.value?.find((stock) =>
      stock.sku === sku,
    );
  };

  const findProductStockBySku = (sku: string): InventoryEntry['availableQuantity'] | 0 => {
    const stock = findStockBySku(sku);
    return stock?.availableQuantity || 0;
  };

  const loadStock = async ({
    items,
    cartId,
  }: { items: LineItem[], cartId?: Cart['id'] }) => {
    if (!items || items.length === 0) return;
    const skuList: string[] = items?.map((item: LineItem) => item?.variant?.sku ?? '').filter(sku => sku !== '');
    if (skuList.length) {
      await getStocks({
        sku: skuList,
        cartId,
      });
    }
  };

  return {
    getStocks,
    stocks: result,
    findStockBySku,
    findProductStockBySku,
    loadStock,
    loading,
    error,
    setStock,
  };
};
