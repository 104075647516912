import type { ProductVariant } from '~/utils/ct/types/product/ProductVariant';
import { useIntersectionObserverEvent } from '~/composables/modules/useIntersectionObserverEvent';
import { useIntegrations } from '~/composables';

export const useAccessories = ({
  sourceProductSku,
  suggestedAccessories,
  suggestedParentProducts,
}: {
  sourceProductSku: string,
  suggestedAccessories?: ProductVariant[],
  suggestedParentProducts?: ProductVariant[]
}) => {
  const { $tagManager } = useIntegrations();

  const { onIntersectOnce: onIntersectAccessoriesOnce } = useIntersectionObserverEvent({
    onIntersectCallback: () => {
      if (suggestedAccessories?.length && sourceProductSku) {
        $tagManager.events.triggerViewAccessoriesTags(suggestedAccessories, sourceProductSku);
      }
    },
  });

  const { onIntersectOnce: onIntersectParentProductsOnce } = useIntersectionObserverEvent({
    onIntersectCallback: () => {
      if (suggestedParentProducts?.length && sourceProductSku) {
        $tagManager.events.triggerViewParentProductsTags(suggestedParentProducts, sourceProductSku);
      }
    },
  });

  const onClickAccessory = (accessorySku: string) => {
    if (accessorySku && sourceProductSku) {
      $tagManager.events.triggerClickAccessoryTags(accessorySku, sourceProductSku);
    }
  };

  const onClickParentProduct = (parentSku: string) => {
    if (sourceProductSku && sourceProductSku) {
      $tagManager.events.triggerClickParentProductTags(parentSku, sourceProductSku);
    }
  };

  return {
    onIntersectAccessoriesOnce,
    onIntersectParentProductsOnce,
    onClickAccessory,
    onClickParentProduct,
  };
};
