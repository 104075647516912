const CHANNEL_TYPE_ID = 'channel';

module.exports.createAddLineItemAction = (params) => {
  return {
    addLineItem: {
      variantId: params.product.id,
      quantity: params.quantity,
      sku: params.product.sku,
      ...(params.supplyChannel && {
        supplyChannel: { id: params.supplyChannel, typeId: CHANNEL_TYPE_ID },
      }),
      ...(params.distributionChannel && {
        distributionChannel: {
          id: params.distributionChannel,
          typeId: CHANNEL_TYPE_ID,
        },
      }),
    },
  };
};
