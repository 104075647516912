import { Logger, sharedRef } from '@vue-storefront/core';
import { computed } from '@nuxtjs/composition-api';
import { useCategoryApi } from '~/utils/ct/composables/api/useCategoryApi';
import type { ApiParams } from '~/utils/ct';
import { errorToString } from '~/helpers/error/errorToString';

export function useCategory(id: string) {
  const { search: apiSearch } = useCategoryApi();
  const categories = sharedRef([], `useCategory-categories-${id}`);
  const loading = sharedRef(false, `useCategory-loading-${id}`);
  const error = sharedRef<{
    search: null | string
  }>({
    search: null,
  }, `useCategory-error-${id}`);

  async function search(searchParams: ApiParams) {
    Logger.debug(`useCategory/${id}/search`, searchParams);
    try {
      loading.value = true;
      categories.value = await apiSearch(searchParams);
      error.value.search = null;
    } catch (err: unknown) {
      error.value.search = errorToString(err);
      Logger.error(`useCategory/${id}/search`, err);
    } finally {
      loading.value = false;
    }
  }
  return {
    search,
    loading: computed(() => loading.value),
    categories: computed(() => categories.value),
    error: computed(() => error.value),
  };
}
