

















import {
  defineComponent,
  computed,
  toRefs,
  useRoute,
} from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import type { Category } from '@vsf-enterprise/commercetools-types';
import { SimpleCarouselArrow } from '~/types/components/SimpleCarousel/SimpleCarouselArrows';
import NavMainBarDesktop from '~/components/molecules/Navigation/NavMainBar/NavMainBarDesktop/NavMainBarDesktop.vue';
import NavMainBarMobile from '~/components/molecules/Navigation/NavMainBar/NavMainBarMobile/NavMainBarMobile.vue';
import {
  useProductFirstLevelCategoryChildren,
} from '~/composables';

export default defineComponent({
  name: 'NavMainBar',
  components: {
    NavMainBarMobile,
    NavMainBarDesktop,
  },
  props: {
    categories: {
      type: Array as PropType<Category[]>,
      default: () => {
        return [];
      },
    },
  },
  setup(props) {
    const route = useRoute();
    const { categories } = toRefs(props);
    const arrowClass = {
      container: `absolute bg-EXPONDO-primary-10 h-full w-10 z-1 top-0
      flex items-center justify-center cursor-pointer`,
      arrow: 'text-EXPONDO-primary text-xl',
    };

    const {
      getProductFirstLevelCategoryChildren,
    } = useProductFirstLevelCategoryChildren();

    const productFirstLevelCategoryChildren = computed(() =>
      getProductFirstLevelCategoryChildren(route.value.params?.slug, categories.value),
    );

    const navCategories = computed(() =>
      productFirstLevelCategoryChildren.value?.length ? productFirstLevelCategoryChildren.value : categories.value,
    );

    const isPdpCategory = computed(() => productFirstLevelCategoryChildren.value?.length > 0);

    return {
      SimpleCarouselArrow,
      arrowClass,
      productFirstLevelCategoryChildren,
      navCategories,
      isPdpCategory,
    };
  },
});
