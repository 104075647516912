import type { Category } from '@vsf-enterprise/commercetools-types';
import { buildCategoryPageUrl } from '../category/buildCategoryPageUrl';
import { ROUTES } from '~/constants/routes';
import { createOnSaleLink } from '~/helpers/navigation/createNavLinks';
import type { FooterLink } from '~/types/cms/Footer/FooterLink/FooterLink';

const getFirstLevelCategories = (categoriesContainer: Category[]): FooterLink[] => {
  return categoriesContainer?.[0]?.children?.map((category) => ({
    name: category.name || '',
    url: category.slug ? buildCategoryPageUrl(category.slug) : '',
  })) || [];
};

export const getFooterLinks = (categoriesContainer: Category[]) => {
  const CUSTOMER_SERVICE = [
    { translationKey: 'footer.Dispatch Information', routeKey: ROUTES.DISPATCH_INFORMATION },
    { translationKey: 'footer.Order tracking', routeKey: ROUTES.MY_ORDERS },
    { translationKey: 'footer.My account', routeKey: ROUTES.LOGIN },
    { translationKey: 'footer.Return & Complaint Policy', routeKey: ROUTES.RETURN_POLICY },
    { translationKey: 'footer.Premium Guarantee', routeKey: ROUTES.PREMIUM_GUARANTEE },
    { translationKey: 'footer.FAQs', routeKey: ROUTES.FAQ },
    { translationKey: 'footer.Secure payment methods', routeKey: ROUTES.PAYMENT_METHODS },
  ];
  const ABOUT_US = [
    { translationKey: 'footer.Terms and conditions', routeKey: ROUTES.TERMS_CONDITIONS },
    { translationKey: 'footer.Privacy policy', routeKey: ROUTES.PRIVACY },
    { translationKey: 'footer.Company information', routeKey: ROUTES.COMPANY_INFORMATION },
    { translationKey: 'footer.Jobs', routeKey: ROUTES.CAREER },
    { translationKey: 'footer.Inspirations', routeKey: ROUTES.INSPIRATIONS, tag: 'footer.Blog' },
  ];
  const OUR_PRODUCTS = [
    { translationKey: 'footer.Product recalls', routeKey: ROUTES.RECALLS },
    ...getFirstLevelCategories(categoriesContainer),
    { translationKey: 'footer.Sale', url: createOnSaleLink() },
  ];
  const OUR_BRANDS = [
    { name: 'Royal Catering', url: '/royal-catering/' },
    { name: 'STAMOS', url: '/stamos/' },
    { name: 'MSW Motor Technics', url: '/msw/' },
    { name: 'Steinberg Systems', url: '/steinberg-systems/' },
    { name: 'ulsonix', url: '/ulsonix/' },
    { name: 'hillvert', url: '/hillvert/' },
    { name: 'physa', url: '/physa/' },
    { name: 'Wiesenfield', url: '/wiesenfield/' },
    { name: 'Fromm & Starck', url: '/frommstarck/' },
    { name: 'Uniprodo', url: '/uniprodo/' },
  ];

  return {
    CUSTOMER_SERVICE,
    ABOUT_US,
    OUR_PRODUCTS,
    OUR_BRANDS,
  };
};
