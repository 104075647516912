import { extendModule } from '~/helpers/vuex/extendModule/extendModule';
import { AnchorNavState } from '~/types/vuex/AnchorNav';

const state = (): AnchorNavState => ({
  activeSection: null,
  stickyAnchorNavHeight: 0,
});
const mutations = {
  setActiveSection(state: AnchorNavState, payload: AnchorNavState['activeSection']) {
    state.activeSection = payload;
  },
  setStickyAnchorNavHeight(state: AnchorNavState, payload: AnchorNavState['stickyAnchorNavHeight']) {
    state.stickyAnchorNavHeight = payload;
  },
};

export default extendModule({
  state,
  mutations,
});
