import { Logger } from '@vue-storefront/core';
import { APPLICATION_ENVIRONMENT } from '~/constants/env';

const convertNonProductionDomainToStage = (inputUrl: URL): string => {
  const hostname = inputUrl.hostname;

  const productionIndex = hostname.indexOf('expondo.');
  if (productionIndex !== -1) {
    const productionDomain = hostname.substring(productionIndex);
    inputUrl.hostname = `${APPLICATION_ENVIRONMENT.STAGE}.${productionDomain}`;
  } else if (hostname.includes('localhost')) {
    const parts = hostname.split('.');
    const region = parts[0] === 'localhost' ? 'ie' : parts[0];
    const domain = region === 'uk' ? 'expondo.co.uk' : `expondo.${region}`;
    inputUrl.hostname = `${APPLICATION_ENVIRONMENT.STAGE}.${domain}`;
  } else {
    inputUrl.hostname = `${APPLICATION_ENVIRONMENT.STAGE}.${hostname}`;
  }
  inputUrl.port = '';
  return inputUrl.toString();
};

const isProductionUrl = (inputUrl: URL): boolean => {
  const hostname = inputUrl.hostname;
  return hostname.startsWith('expondo.') || hostname.startsWith('www.expondo.');
};

export const adjustUrlForSeoRedirection = (sourceUrl: string): string => {
  try {
    if (!/^https?:\/\//i.test(sourceUrl)) {
      sourceUrl = 'https://' + sourceUrl;
    }
    const url = new URL(sourceUrl);
    let resultUrl = isProductionUrl(url) ? url.toString() : convertNonProductionDomainToStage(url);

    // Remove trailing slash.
    resultUrl = resultUrl.replace(/\/$/, '');
    return resultUrl;
  } catch (e) {
    Logger.error('Invalid URL provided:', sourceUrl);
    return sourceUrl;
  }
};
