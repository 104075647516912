import { EVENT_BUNDLE_BUILDER } from '~/constants/googleTagManager';
export class BundleBuilderClickItemTags {
  event = EVENT_BUNDLE_BUILDER.ITEM_CLICK;
  product_id: string;
  constructor({ sku }: {
    sku: string
  }) {
    this.product_id = sku;
  }
}
