






import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  setup () {
    const currentYear = new Date().getFullYear();

    return { currentYear };
  },
});
