









import { defineComponent } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import { trustpilotMicroComboWidgetId } from '~/config';
import { TRUSTPILOT_MICRO_COMBO_WIDGET_HEIGHT } from '~/constants/ui';
import { TRUSTPILOT_THEMES } from '~/constants/trustPilot';
import TrustpilotWrapper from '~/components/atoms/TrustPilot/TrustpilotWrapper.vue';

export default defineComponent({
  name: 'TrustpilotMicroCombo',
  components: {
    TrustpilotWrapper,
  },
  props: {
    showWidget: {
      type: Boolean,
      default: true,
    },
    theme: {
      type: String as PropType<ValueOf<typeof TRUSTPILOT_THEMES>>,
      default: TRUSTPILOT_THEMES.LIGHT,
    },
  },
  setup () {
    return {
      trustpilotMicroComboWidgetId,
      TRUSTPILOT_MICRO_COMBO_WIDGET_HEIGHT,
    };
  },
});

