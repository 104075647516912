const { ShippingMethodState } = require('@vsf-enterprise/commercetools-types');
const { getShippingMethods } = require('@vsf-enterprise/commercetools-api');
const { updateCartBase } = require('../updateCartBase');
const { setShippingMethodAction } = require('#~/helpers/cart/cartActions/setShippingMethodAction.js');
const { SHIPPING_METHOD_KEY_DEFAULT } = require('#~/constants/shippingTypes/shippingMethodKeys.js');

const setShippingMethod = (context, cart, shippingMethodId, customQuery) => {
  return updateCartBase(context, {
    ...cart,
    actions: [setShippingMethodAction(shippingMethodId)],
  }, customQuery);
};
module.exports.setDefaultShippingMethod = async (context, cart, customQuery) => {
  const shippingMethodKey = cart?.shippingInfo?.shippingMethod?.key;
  if (shippingMethodKey !== SHIPPING_METHOD_KEY_DEFAULT ||
    cart?.shippingInfo?.shippingMethodState === ShippingMethodState.DoesNotMatchCart) {
    const shippingMethodsResponse = await getShippingMethods(context, cart?.id);
    const firstShippingMethodId = shippingMethodsResponse?.data?.shippingMethods?.[0]?.id;
    return setShippingMethod(context, cart, firstShippingMethodId, customQuery);
  }
};
