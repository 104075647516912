








import { defineComponent } from '@nuxtjs/composition-api';
import { trustpilotMicroButtonId } from '~/config';
import { TRUSTPILOT_MICRO_BUTTON_WIDGET_HEIGHT } from '~/constants/ui';
import TrustpilotWrapper from '~/components/atoms/TrustPilot/TrustpilotWrapper.vue';

export default defineComponent({
  name: 'TrustpilotMicroButton',
  components: {
    TrustpilotWrapper,
  },
  props: {
    showWidget: {
      type: Boolean,
      default: true,
    },
  },
  setup () {
    return {
      trustpilotMicroButtonId,
      TRUSTPILOT_MICRO_BUTTON_WIDGET_HEIGHT,
    };
  },
});

