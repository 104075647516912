import { useMeta, computed, useRoute } from '@nuxtjs/composition-api';
import { useVSFContext } from '@vue-storefront/core';
import type { ComputedRef, Ref } from '@nuxtjs/composition-api';
import generateCanonicalLink from '~/helpers/seo/generateCanonicalLink';
import generateHreflangLink from '~/helpers/seo/generateHreflangLink';
import {
  generateMetaCopyrightHolder,
  generateMetaCopyrightYear,
  generateMetaDescription,
  generateMetaExpondoImage,
  generateMetaIsFamilyFriendly,
  generateMetaRobots,
  generateMetaTitleName,
} from '~/helpers/seo/generateMetaTags';
import { LocalisedString } from '~/helpers/seo/types/LocalisedString';
import { DEFAULT_META_TITLE } from '~/constants/defaultMetaTitle';
import { i18nToCurrentDomain } from '~/helpers/locales/i18nCurrentDetails';
import { usePrefix } from '~/composables';
import { adjustHostnameForPreProduction } from '~/helpers/env/preprod';
import { getFirstChildOrSelf } from '~/helpers/dataStructure/getFirstChildOrSelf';

export const useMetaExtended = ({
  metaTitle,
  isPageIndexed,
  metaDescription,
  slug,
  localisedSlugs,
  isPageHreflangLinkHidden,
  bodyAttrsClass = '',
  isPdp,
}: {
  metaTitle: ComputedRef<string> | Ref<string>,
  isPageIndexed: ComputedRef<boolean> | Ref<boolean>,
  metaDescription?: ComputedRef<string> | Ref<string>,
  slug?: ComputedRef<string> | Ref<string>,
  localisedSlugs?: ComputedRef<LocalisedString[]> | Ref<LocalisedString[]>,
  isPageHreflangLinkHidden?: ComputedRef<boolean> | Ref<boolean>,
  bodyAttrsClass?: string,
  isPdp?: boolean
}) => {
  const { i18n } = useVSFContext();
  const title = computed(() => metaTitle?.value || DEFAULT_META_TITLE);
  const route = useRoute();
  const currentPage = computed(() => {
    const pageParameter: string = getFirstChildOrSelf(route.value.query?.page) || '1';
    const parsedPage: number = parseInt(pageParameter);
    if (parsedPage > 1) {
      return parsedPage;
    } else {
      return undefined;
    }
  });

  const currentDomain = i18nToCurrentDomain(i18n) || '';
  const normalisedDomain = adjustHostnameForPreProduction(currentDomain);
  const { languagePrefix } = usePrefix();

  const meta = computed(() => [
    generateMetaRobots(isPageIndexed.value),
    generateMetaDescription(metaDescription?.value || i18n.t('SEO meta description default')),
    generateMetaCopyrightYear(),
    generateMetaIsFamilyFriendly(),
    generateMetaExpondoImage(),
    generateMetaTitleName(title.value),
    generateMetaCopyrightHolder(normalisedDomain),
  ]);

  const link = computed(() => [
    generateCanonicalLink({
      domain: normalisedDomain,
      slug: slug?.value || '',
      prefix: languagePrefix,
      pageNumber: currentPage.value,
    }),
    ...generateHreflangLink(localisedSlugs?.value, isPageHreflangLinkHidden?.value, isPdp),
  ]);
  useMeta(() => ({
    title: title.value,
    meta: meta.value,
    link: link.value,
    bodyAttrs: {
      class: bodyAttrsClass,
    },
  }));
};
