













import { defineComponent, ref, onMounted, watch } from '@nuxtjs/composition-api';
import { LOADING_MODE } from '~/constants/images';

export default defineComponent({
  name: 'ImgView',
  props: {
    width: {
      type: [Number, String],
      default: 0,
    },
    height: {
      type: [Number, String],
      default: 0,
    },
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      default: '',
    },
    defaultSrc: {
      type: String,
      default: '',
    },
    imgClasses: {
      type: String,
      default: 'w-full h-full overflow-hidden object-contain',
    },
    loading: {
      type: String,
      default: LOADING_MODE.LAZY,
    },
  },
  setup(props) {
    const imageSource = ref(props.src);
    const checkImageExists = async (url: string): Promise<boolean> => {
      try {
        const response = await fetch(url, { method: 'HEAD' });
        return response.status === 200;
      } catch (error) {
        return false;
      }
    };
    const setImageFallbackIfNecessary = async (src: string): Promise<void> => {
      if (!props.defaultSrc) {
        return;
      }

      const imageExists = await checkImageExists(src);
      if (imageExists) {
        return;
      }

      imageSource.value = props.defaultSrc;
    };

    onMounted(async () => {
      await setImageFallbackIfNecessary(props.src);
    });

    watch(() => props.src, async (newSrc) => {
      imageSource.value = newSrc;
      await setImageFallbackIfNecessary(newSrc);
    });

    return {
      imageSource,
      setImageFallbackIfNecessary,
    };
  },
});

