import { AxiosResponse } from 'axios';
import { VatIntegration } from '~/types/integrations/vatValidation/VatIntegration';
import config from '~/config';

export default (instance: VatIntegration) => {
  const { axios } = instance.services;

  return (vatNumber: string): Promise<AxiosResponse> => {
    return axios.get('validate', {
      params: {
        UstId_1: config.expondoVatId,
        UstId_2: vatNumber,
      },
    });
  };
};
