import { computed } from '@nuxtjs/composition-api';
import { defineModule } from '~/helpers/vuex/defineModule/defineModule';
import {
  ReviewConsentMutations,
} from '~/types/vuex/ReviewConsent';
import { MODULE_NAMES } from '~/types/vuex/Vuex';

export default function () {
  const store = defineModule<
    typeof MODULE_NAMES.REVIEW_CONSENT_MODULE_NAME,
    ReviewConsentMutations,
    {},
    {}
  >(MODULE_NAMES.REVIEW_CONSENT_MODULE_NAME);

  const reviewConsent = computed(() => store.state.reviewConsent);

  const setReviewConsent = (consent: boolean) => {
    store.mutations.setReviewConsent(consent);
  };

  return {
    reviewConsent,
    ...store.getters,
    ...store.mutations,
    ...store.actions,
    setReviewConsent,
  };
}
