








import { defineComponent } from '@nuxtjs/composition-api';
import { useVSFContext } from '@vue-storefront/core';
import NavMainBarCategory from '~/components/molecules/Navigation/NavMainBar/NavMainBarCategory/NavMainBarCategory.vue';
import { createOnSaleLink } from '~/helpers/navigation/createNavLinks';
import type { NavBarCategory } from '~/types/category/NavBarCategory';

export default defineComponent({
  name: 'NavMainBarOnSale',
  components: {
    NavMainBarCategory,
  },
  setup() {
    const { i18n } = useVSFContext();

    const onSaleLink = createOnSaleLink();
    const onSaleCategory: NavBarCategory = {
      name: i18n.t('on-sale'),
      link: onSaleLink,
    };

    return {
      onSaleCategory,
    };
  },
});
