import { PageView } from '~/plugins/integrations/tagManager/helpers/PageViewTag';
import { Gtm } from '~/types/@nuxtjs/gtm';
import { TriggerPageViewParameters } from '~/types/integrations/tagManager/TagManagerIntegration';

export const triggerPageViewWrapper = ($gtm: Gtm) => {
  return (parameters: TriggerPageViewParameters) => {
    const pageViewTag = new PageView({
      page: parameters.page,
      user: parameters.user,
    });
    $gtm.push(pageViewTag);
  };
};
