import { Logger } from '@vue-storefront/core';
import { getCt } from '~/helpers/getCt';

export default async ({ app, route, redirect }) => {
  const ct = getCt(app.context, { vsfCt: true });
  try {
    const isLoggedIn = await ct.api.isLoggedIn();
    if (!isLoggedIn) {
      throw new Error(`"${route.fullPath}" route is only available to logged-in customers`);
    }
  } catch (error) {
    Logger.warn(error.toString());
    return redirect('/');
  }
};
