
/**
* @param {Context[req]?} request
* @returns {string}
*/
function getHostname(request) {
  let hostFromWindow;
  if (typeof window !== 'undefined') {
    hostFromWindow = window?.location?.host;
  }
  return request?.headers['x-forwarded-host'] || request?.headers.host || hostFromWindow || 'missing-host';
}

module.exports = {
  getHostname,
};
