const { clientQuery } = require('../queries');
const shippingMethodsQuery = require('../../queries/shipping/shippingMethodsQuery.js');
const addShippingAddressQuery = require('../../queries/shipping/addShippingAddressQuery.js');
const removeAddressQuery = require('../../queries/shipping/removeAddressQuery.js');
const updateAddressQuery = require('../../queries/shipping/updateAddressQuery.js');
const setDefaultShippingAddressQuery = require('../../queries/shipping/setDefaultShippingAddressQuery.js');
const handleCtApiError = require('../handleCtApiError');
const { CUSTOM_QUERIES } = require('../../../../constants/customQueries.js');
const executeQuery = require('../executeQuery');
const getDefaultVariables = require('./getDefaultVariables');

/**
 * @typedef {Object} ShippingParams
 * @property {Object} address
 * @property {Object} shipping
 */

/**
 * @param {Context} context
 * @param {ShippingParams} params
 * @param {Maybe<CustomQuery>} customQuery
 */
const load = async (context, params, customQuery) => {
  const defaultVariables = getDefaultVariables(context, { params, customQuery: params?.customQuery });

  const { shippingMethods } = context.extendQuery(customQuery, {
    shippingMethods: shippingMethodsQuery[CUSTOM_QUERIES.SHIPPING.shippingMethods.customShippingMethods]({
      variables: defaultVariables,
    }),
  });

  try {
    return await clientQuery(context, {
      query: shippingMethods.query,
      variables: shippingMethods.variables,
    });
  } catch (error) {
    throw handleCtApiError(error);
  }
};

/**
 * @param {Context} context
 * @param {ShippingParams} params
 * @param {Maybe<CustomQuery>} customQuery
 */
const addAddress = async (context, params, customQuery) => {
  const defaultVariables = getDefaultVariables(context, { params, address: params.address });

  const { addShippingAddress } = context.extendQuery(customQuery, {
    addShippingAddress: addShippingAddressQuery[CUSTOM_QUERIES.SHIPPING.addAddress.customAddShippingAddress]({
      variables: defaultVariables,
    }),
  });

  return await executeQuery(context, addShippingAddress);
};

/**
 * @param {Context} context
 * @param {ShippingParams} params
 * @param {Maybe<CustomQuery>} customQuery
 */
const deleteAddress = async (context, params, customQuery) => {
  const defaultVariables = getDefaultVariables(context, { params, addressId: params.address.id });

  const { removeAddress } = context.extendQuery(customQuery, {
    removeAddress: removeAddressQuery[CUSTOM_QUERIES.SHIPPING.removeAddress.customRemoveShippingAddress]({
      variables: defaultVariables,
    }),
  });

  return await executeQuery(context, removeAddress);
};

/**
 * @param {Context} context
 * @param {ShippingParams} params
 * @param {Maybe<CustomQuery>} customQuery
 */
const updateAddress = async (context, params, customQuery) => {
  const defaultVariables = getDefaultVariables(context, {
    params,
    address: params.address,
    addressId: params.address.id,
  });

  const { updateAddress } = context.extendQuery(customQuery, {
    updateAddress: updateAddressQuery[CUSTOM_QUERIES.SHIPPING.updateAddress.customUpdateShippingAddress]({
      variables: defaultVariables,
    }),
  });

  return await executeQuery(context, updateAddress);
};

/**
 * @param {Context} context
 * @param {ShippingParams} params
 * @param {Maybe<CustomQuery>} customQuery
 */
const setDefaultAddress = async (context, params, customQuery) => {
  const defaultVariables = getDefaultVariables(context, { params, addressId: params.address.id });

  const { setDefaultShippingAddress } = context.extendQuery(customQuery, {
    setDefaultShippingAddress: setDefaultShippingAddressQuery[
      CUSTOM_QUERIES.SHIPPING.setDefaultAddress.customSetDefaultShippingAddress
    ]({
      variables: defaultVariables,
    }),
  });

  return await executeQuery(context, setDefaultShippingAddress);
};

module.exports = {
  load,
  addAddress,
  deleteAddress,
  updateAddress,
  setDefaultAddress,
};
