

























import { defineComponent } from '@nuxtjs/composition-api';
import { ValidationProvider } from 'vee-validate';
import PrimaryInput from '~/components/atoms/Inputs/PrimaryInput/PrimaryInput.vue';

export default defineComponent({
  name: 'ValidatedInput',
  components: {
    PrimaryInput,
    ValidationProvider,
  },
  props: {
    rules: {
      type: String,
      default: 'required|min:3',
    },
    errorMessage: {
      type: String,
      default: '',
    },
    valid: {
      type: Boolean,
      default: true,
    },
    name: {
      type: String,
      default: '',
    },
  },
});
