import { computed } from '@nuxtjs/composition-api';
import { defineModule } from '~/helpers/vuex/defineModule/defineModule';
import {
  CartSidebarMutations,
} from '~/types/vuex/CartSidebar';
import { MODULE_NAMES } from '~/types/vuex/Vuex';

export default function () {
  const store = defineModule<
    typeof MODULE_NAMES.CART_SIDE_BAR_MODULE_NAME,
    CartSidebarMutations,
    {},
    {}
  >(MODULE_NAMES.CART_SIDE_BAR_MODULE_NAME);

  const isCartSidebarOpen = computed(() => store.state.isCartSidebarOpen);
  return {
    isCartSidebarOpen,
    ...store.getters,
    ...store.mutations,
    ...store.actions,
  };
}
