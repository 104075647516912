import { computed } from '@nuxtjs/composition-api';
import { defineModule } from '~/helpers/vuex/defineModule/defineModule';
import type {
  UiNotificationMutations,
} from '~/types/vuex/UiNotification';
import { MODULE_NAMES } from '~/types/vuex/Vuex';

export default function () {
  const store = defineModule<
    typeof MODULE_NAMES.UI_NOTIFICATION_MODULE_NAME,
    UiNotificationMutations,
    {},
    {}
  >(MODULE_NAMES.UI_NOTIFICATION_MODULE_NAME);

  const uiNotifications = computed(() => store.state.uiNotifications);
  return {
    uiNotifications,
    ...store.getters,
    ...store.mutations,
    ...store.actions,
  };
}
