import { AxiosResponse } from 'axios';
import { Logger, sharedRef, useVSFContext } from '@vue-storefront/core';
import { computed } from '@nuxtjs/composition-api';
import type { Ref } from '@nuxtjs/composition-api';
import VueI18n from 'vue-i18n';
import { TaxMode } from '@vsf-enterprise/commercetools-types/src/Schema';
import { useCartExtended, useCookies, useIntegrations, useStoreVsfExtended } from '~/composables';
import { setVatNumber as setVatNumberAction, VatNumber } from '~/helpers/cart/customFields';
import { VatResult } from '~/types/integrations/vatValidation/VatIntegration';
import { getVatPrefix } from '~/helpers/formatVatNumberForLocale';
import { COULD_NOT_VALIDATE_RESPONSE_CODES, VAT_VALID_RESPONSE_CODES } from '~/constants/vatValidationCodes';
import { VAT_VALIDATION_STATUS } from '~/constants/vatValidationStatus';

export const useVatValidation = (shouldUpdateCart: Maybe<Ref<boolean>> = undefined) => {
  const { i18n } = useVSFContext();
  const { $vat } = useIntegrations();
  const { country } = useCookies();
  const { taxMode, updateCustomCart } = useCartExtended();
  const { isVatValidationEnabled, isChangeTaxRateEnabled } = useStoreVsfExtended();
  const result = sharedRef<VatResult | undefined>(undefined, 'vat-result');
  const error = sharedRef('', 'useVatValidationError');
  const vatNumber = sharedRef<VatNumber>('', 'useVatValidationVatNumber');
  const setVatNumber = (value: VatNumber) => {
    vatNumber.value = value;
  };

  const createResult = (responseData: string, valid: boolean) => ({
    valid,
    response: responseData,
  });

  const logVatValidationResponse = (responseCode: number, errorValue: string): void => {
    const validationResult = errorValue === '' ? VAT_VALIDATION_STATUS.VALID : errorValue;
    const processedVatNumber = `${vatPrefix.value}${vatNumber.value}`;

    Logger.info(`Validating ${processedVatNumber} resulted with code ${responseCode}`, {
      responseCode,
      result: validationResult,
      vatNumber: processedVatNumber,
    });
  };

  const request = sharedRef<Promise<void> | null>(null, 'useVatValidationPromise');

  const isValid = computed(() => !!result.value?.valid && !loading.value);
  const loading = computed(() => !!request.value);

  const requestValidateVatNumber = async (newVatNumber: VatNumber, shouldValidate: boolean): Promise<void> => {
    setVatNumber(newVatNumber);

    if (isVatValidationEnabled.value && newVatNumber && shouldValidate) {
      try {
        const response: AxiosResponse = await $vat.validate(`${vatPrefix.value}${newVatNumber}`);
        const codeFromResponse = getCodeFromResponse(response);
        error.value = VAT_VALID_RESPONSE_CODES.includes(codeFromResponse)
          ? ''
          : getErrorMessage(codeFromResponse);
        logVatValidationResponse(codeFromResponse, error.value);
        await setResult(createResult(response.data, !error.value));
      } catch (e) {
        Logger.error('Error during vat validation for vat number: ' + newVatNumber + ' message: ' + e);
        error.value = VAT_VALIDATION_STATUS.COULD_NOT_VALIDATE;
        await setResult(createResult('', false));
      }
      return;
    }
    error.value = '';
  };

  const getErrorMessage = (responseCode: number): string => {
    return COULD_NOT_VALIDATE_RESPONSE_CODES.includes(responseCode)
      ? VAT_VALIDATION_STATUS.COULD_NOT_VALIDATE
      : VAT_VALIDATION_STATUS.NOT_VALID;
  };

  const vatPrefix = computed(() => getVatPrefix(country));

  const validateVatNumber = async (newVatNumber: VatNumber, forceValidate = false): Promise<void> => {
    const shouldValidate = (newVatNumber !== vatNumber.value) || forceValidate;

    if (request.value) {
      await request.value;
      if (!shouldValidate) {
        return;
      }
    }
    request.value = requestValidateVatNumber(newVatNumber, shouldValidate);
    await request.value;
    request.value = null;
  };

  const setResult = async (value: VatResult | undefined) => {
    result.value = value;
    shouldUpdateCart?.value &&
      await updateCustomCart([
        setVatNumberAction('Billing', vatNumber.value, result.value),
      ]);
  };
  const clear = async () => {
    await setResult(undefined);
    error.value = '';
    vatNumber.value = '';
    request.value = null;
  };

  const isErrorCouldNotValidate = computed(() =>
    error.value === VAT_VALIDATION_STATUS.COULD_NOT_VALIDATE);
  const isErrorNotValid = computed(() =>
    error.value === VAT_VALIDATION_STATUS.NOT_VALID);

  const isNetPriceActive = computed(() => (taxMode.value === TaxMode.External));

  const notValidUsageMessage = computed<VueI18n.TranslateResult>(() =>
    isChangeTaxRateEnabled.value
      ? i18n.t(
        'Make sure your VAT ID format is correct to receive a NET invoice or ignore and continue with GROSS invoice.')
      : i18n.t('Please correct it or continue anyway.'),
  );
  const validChangeTaxRateUsageMessage = computed<VueI18n.TranslateResult>(() =>
    isNetPriceActive.value
      ? i18n.t('Prices have been adjusted to NET.')
      : i18n.t(
        'We were unable to adjust prices to NET. You can continue and contact our support service to claim the tax back.',
      ),
  );
  const changeTaxRateSuccessMessage = computed<VueI18n.TranslateResult>(() =>
    isNetPriceActive.value
      ? ''
      : i18n.t('Technical issue occurred.'),
  );

  const messages = computed<VueI18n.TranslateResult[]>(() => {
    if (!error.value) {
      return (isChangeTaxRateEnabled.value &&
        isValid.value)
        ? [changeTaxRateSuccessMessage.value, validChangeTaxRateUsageMessage.value]
        : [];
    }
    return (isErrorNotValid.value)
      ? [
        i18n.t('VAT ID not validated.'),
        notValidUsageMessage.value,
      ]
      : [
        i18n.t('VAT ID validation is taking too much time.'),
        i18n.t('Confirm by clicking the button below.'),
      ];
  });

  const getCodeFromResponse = (response: AxiosResponse): number => {
    const parser = new DOMParser();
    const xmlElements = Array.from(
      parser
        .parseFromString(String(response.data), 'application/xml')
        .querySelectorAll('string'),
    );
    let resultCode = 999;
    xmlElements.forEach((element, key) => {
      if (element.innerHTML === 'ErrorCode') {
        const nextElement = xmlElements[key + 1];
        resultCode = Number(nextElement?.innerHTML) ?? 999;
      }
    });
    return resultCode;
  };

  return {
    isValid,
    isErrorCouldNotValidate,
    isErrorNotValid,
    result,
    vatPrefix,
    error,
    loading,
    messages,
    validateVatNumber,
    clear,
    isNetPriceActive,
    vatNumber,
    logVatValidationResponse,
  };
};
