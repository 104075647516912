































import { defineComponent, ref } from '@nuxtjs/composition-api';
import { computed } from '@vue/composition-api';
import { useI18n, useLanguageSelector } from '~/composables';

export default defineComponent({
  name: 'TopMenuLanguageSelector',
  setup(_props, { emit }) {
    const { countryLocalization, language } = useI18n();
    const { changeLanguage } = useLanguageSelector();

    const languagesList = computed(() => {
      const languages = countryLocalization.value.getLanguageAndCountries();
      return languages
        .filter(item => !item.includes(language.value))
        .map(item => ({
          languageCode: item,
          languageName: item.split('-')[0].toUpperCase(),
        }));
    });
    const languageName = computed(() => language.value.toUpperCase());
    const isSwitcherOpen = ref(false);

    const toggleSwitcher = () => {
      isSwitcherOpen.value = !isSwitcherOpen.value;
    };

    const closeSwitcher = () => {
      if (isSwitcherOpen.value) {
        isSwitcherOpen.value = false;
      }
    };

    const clickedOnSwitcher = () => {
      emit('language-switcher-clicked');
    };

    return {
      countryLocalization,
      changeLanguage,
      languageName,
      languagesList,
      isSwitcherOpen,
      toggleSwitcher,
      closeSwitcher,
      clickedOnSwitcher,
    };
  },
});

