








import { defineComponent } from '@nuxtjs/composition-api';
import defaultLayout from './default.vue';

export default defineComponent({
  name: 'DefaultFullWidth',
  components: {
    defaultLayout,
  },
});
