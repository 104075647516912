import { Scalars } from '~/utils/ct';

export const roundingMode = {
  HalfDown: 'HalfDown',
  HalfUp: 'HalfUp',
  HalfEven: 'HalfEven',
} as const;

export type RoundingMode = keyof typeof roundingMode;

export type BaseMoney = {
  type: Scalars['String'];
  currencyCode: Scalars['Currency'];
  centAmount: Scalars['Long'];
  fractionDigits: Scalars['Int'];
};

export type Money = BaseMoney & {
  __typename?: 'Money';
};
