import { computed } from '@nuxtjs/composition-api';
import type { Category } from '@vsf-enterprise/commercetools-types';
import { useI18n, useProductExtended } from '~/composables';
import { removeEmptyCategories } from '~/helpers/category/categoriesTree';
import { sortCategories } from '~/helpers/category/sortCategories';
import { getBreadcrumbsFromProduct } from '~/helpers/category/getBreadcrumbsFromProduct';

export default function () {
  const getProductFirstLevelCategoryChildren = (
    slug: string,
    categories: Category[],
  ): Category[] => {
    const { languageAndCountry } = useI18n();
    const {
      masterProduct: product,
    } = useProductExtended(slug);

    const productFirstLevelCategoryChildren = computed(() => {
      if (!product.value || !product.value._breadcrumbs) {
        return [];
      }

      const breadcrumbs = getBreadcrumbsFromProduct(product.value);
      const secondBreadcrumbSlug = breadcrumbs?.at(1)?.slug;

      if (!secondBreadcrumbSlug) {
        return [];
      }

      const productFirstLevelCategory = categories.find(category => category.slug === secondBreadcrumbSlug);
      if (!productFirstLevelCategory) {
        return [];
      }

      const secondLevelCategories = productFirstLevelCategory.children || [];
      if (!secondLevelCategories.length) {
        return [];
      }

      const filteredChildren = secondLevelCategories.filter(child =>
        removeEmptyCategories([child], languageAndCountry.value).length > 0,
      );

      const sortedChildren = filteredChildren.map(product => ({
        ...product,
        link: `/${product.slug}`,
        hideChevron: true,
      }));

      sortCategories(sortedChildren);
      return sortedChildren;
    });

    return productFirstLevelCategoryChildren.value;
  };

  return {
    getProductFirstLevelCategoryChildren,
  };
}
