
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';
import { CACHE_SEARCH_RESULTS_FOR_SECONDS } from '~/constants/typesense';
import { typesenseHost, typesensePort, typesenseProtocol } from '~/config';

export function getTypesenseAdapter(
  options: object, stopwordsSetName: string | null): TypesenseInstantSearchAdapter {
  return new TypesenseInstantSearchAdapter({
    ...options,
    server: {
      apiKey: process.env.TYPESENSE_API_KEY || process.env.typesenseApiKey || '',
      nodes: [
        {
          host: typesenseHost,
          port: typesensePort,
          protocol: typesenseProtocol,
        },
      ],
      cacheSearchResultsForSeconds: CACHE_SEARCH_RESULTS_FOR_SECONDS,
    },
    additionalSearchParameters: {
      preset: 'global',
      stopwords: stopwordsSetName || undefined,
    },
  });
}
