import languageCodes from './languageCodes';

const salesforceWebformLocales = {
  [languageCodes.sk]: 'sk',
  [languageCodes.en]: 'en_GB',
  [languageCodes.de]: 'de',
  [languageCodes.pt]: 'pt_PT',
  [languageCodes.it]: 'it',
  [languageCodes.fr]: 'fr',
  [languageCodes.hu]: 'hu',
  [languageCodes.cs]: 'cs',
  [languageCodes.nb]: 'no',
  [languageCodes.nl]: 'nl_NL',
  [languageCodes.da]: 'da',
  [languageCodes.sv]: 'sv',
  [languageCodes.es]: 'es',
  [languageCodes.fi]: 'fi',
  [languageCodes.pl]: 'pl',
  [languageCodes.ro]: 'ro',
  [languageCodes.lt]: 'lt',
  [languageCodes.sl]: 'sl',
  [languageCodes.bg]: 'bg',
  [languageCodes.el]: 'el',
  [languageCodes.hr]: 'hr',
} as const;
export default salesforceWebformLocales;
