/* eslint-disable camelcase */
import { CLEAR_ALL_PARAMETERS } from '~/constants/googleTagManager';

export class ClearAllFiltersParameters {
  event = CLEAR_ALL_PARAMETERS;
  event_info: {
    category_path: string,
    category_name: string,
    listing_type: string
  };

  constructor({
    categoryPath,
    categoryName,
    listingType,
  }: {
    categoryPath: string,
    categoryName: string,
    listingType: string
  }) {
    this.event_info = {
      category_path: categoryPath,
      category_name: categoryName,
      listing_type: listingType,
    };
  }
}
