export const DEFAULT_SORTING_OPTION = 'rating desc, custom.fields.reviewCreatedAt desc, text desc';

export const REVIEW_SORTING_OPTIONS = [
  {
    value: DEFAULT_SORTING_OPTION,
    name: 'Top reviews',
  },
  {
    value: 'custom.fields.reviewCreatedAt desc',
    name: 'Most recent',
  },
];
