


















import { computed, defineComponent } from '@nuxtjs/composition-api';
import { trustpilotMicroComboWidgetId, trustpilotMicroButtonId } from '~/config';
import { TRUSTPILOT_MICRO_BUTTON_WIDGET_HEIGHT, TRUSTPILOT_MICRO_COMBO_WIDGET_HEIGHT } from '~/constants/ui';
import { useStoreVsfExtended, useTrustPilotWidget } from '~/composables';
import TrustpilotMicroCombo from '~/components/molecules/TrustPilot/TrustpilotMicroCombo/TrustpilotMicroCombo.vue';
import TrustpilotMicroButton from '~/components/molecules/TrustPilot/TrustpilotMicroButton/TrustpilotMicroButton.vue';

export default defineComponent({
  name: 'TrustpilotMicroComboOrButton',
  components: {
    TrustpilotMicroCombo,
    TrustpilotMicroButton,
  },
  setup () {
    const { showTrustpilotScoreInHeader, isLoaded } = useStoreVsfExtended();
    const { showTrustPilotWidget } = useTrustPilotWidget();

    const trustpilotWidgetId = computed(() => {
      return showTrustpilotScoreInHeader.value
        ? trustpilotMicroComboWidgetId
        : trustpilotMicroButtonId;
    });

    const trustpilotWidgetHeight = computed(() => {
      return showTrustpilotScoreInHeader.value
        ? TRUSTPILOT_MICRO_COMBO_WIDGET_HEIGHT
        : TRUSTPILOT_MICRO_BUTTON_WIDGET_HEIGHT;
    });

    const showWidget = computed(() => showTrustPilotWidget.value && isLoaded.value);

    return {
      showWidget,
      isLoaded,
      trustpilotWidgetId,
      trustpilotWidgetHeight,
      showTrustPilotWidget,
      showTrustpilotScoreInHeader,
    };
  },
});

