import { computed } from '@nuxtjs/composition-api';
import { useBilling } from '@vsf-enterprise/commercetools';
import type { Address } from '@vsf-enterprise/commercetools-types';
import { defineModule } from '~/helpers/vuex/defineModule/defineModule';
import {
  useCheckout,
} from '~/composables';
import {
  BillingDetailsMutations,
} from '~/types/vuex/BillingDetails';
import { MODULE_NAMES } from '~/types/vuex/Vuex';

export default function () {
  const store = defineModule<
    typeof MODULE_NAMES.BILLING_DETAILS_MODULE_NAME,
    BillingDetailsMutations,
    {},
    {}
  >(MODULE_NAMES.BILLING_DETAILS_MODULE_NAME);

  const {
    billing: address,
  } = useBilling();

  const { defaultAddress } = useCheckout();

  const billingDetails = computed(() => store.state.billingDetails);
  if (!billingDetails.value?.firstName) {
    store.mutations.setBillingDetails(address.value || defaultAddress);
  }
  const setBillingDetailsMutation = (billingDetails: Partial<Address>) => {
    const addressToSet = billingDetails || address.value || defaultAddress;
    store.mutations.setBillingDetails(addressToSet);
  };

  return {
    billingDetails,
    ...store.getters,
    ...store.mutations,
    ...store.actions,
    setBillingDetailsMutation,
  };
}
