import { reactive, computed } from '@nuxtjs/composition-api';
import { Logger } from '@vue-storefront/core';
import type { ComputedRef } from '@nuxtjs/composition-api';
import useLoading from '~/composables/modules/useLoading';
import {
  useCartExtended, useUserExtended, useClientEmail, useCookies, usePaymentSelected,
} from '~/composables';
import CustomPayment from '~/constants/customPayment';
import { orderWithoutEmailId } from '~/constants/log';

const error = reactive({ createCashPayment: false, createBankPayment: false });

const useCheckout = () => {
  const loadingModule = useLoading('useCheckout-loading');

  const {
    user,
  } = useUserExtended();
  const { updateCustomerEmail } = useCartExtended();
  const { clientEmail, setClientEmail } = useClientEmail();
  const { paymentSelected, setPaymentSelected } = usePaymentSelected();
  const { country } = useCookies();

  const isPaymentCodSelected: ComputedRef<boolean> = computed(() => paymentSelected.value === CustomPayment.COD);
  const isPaymentBankSelected: ComputedRef<boolean> = computed(() =>
    paymentSelected.value === CustomPayment.BANK_TRANSFER);

  const postEmail = async (updateCart = true) => {
    const newEmail = user.value?.email || clientEmail.value;
    if (!newEmail) {
      Logger.error(`${orderWithoutEmailId} postEmail called with empty email`);
      return;
    }
    setClientEmail(newEmail);
    if (updateCart) {
      await updateCustomerEmail(newEmail, 'postEmail');
    }
  };

  const setErrorCreateBankPayment = (errorCreatePayment: boolean) => {
    error.createBankPayment = errorCreatePayment;
  };

  const setErrorCreateCashPayment = (errorCreatePayment: boolean) => {
    error.createCashPayment = errorCreatePayment;
  };

  const defaultAddress = {
    country,
  };

  return {
    loadingModule,
    postEmail,
    error,
    setErrorCreateCashPayment,
    setErrorCreateBankPayment,
    isPaymentCodSelected,
    isPaymentBankSelected,
    setPaymentSelected,
    paymentSelected,
    defaultAddress,
  };
};
export default useCheckout;
