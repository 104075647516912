import { EVENT_CLICK_ACCESSORY } from '~/constants/googleTagManager';
import { EcommerceClickAccessory } from '~/types/integrations/tagManager/TagClickAccessory';

export class ClickAccessoryTags {
  event = EVENT_CLICK_ACCESSORY
  ecommerce: EcommerceClickAccessory = {
    detail: {
      items: {
        parent_product_id: '',
        accessory_id: '',
      },
    },
  }

  assignAccessoryValues (productSku: string, parentProductSku: string) {
    this.ecommerce.detail.items = {
      parent_product_id: parentProductSku,
      accessory_id: productSku,
    };
  }
}
