import { computed } from '@nuxtjs/composition-api';
import type { ComputedRef } from '@nuxtjs/composition-api';
import VueI18n from 'vue-i18n/types';
import { useVSFContext } from '@vue-storefront/core';
import { useStoreVsfExtended, useCampaignTag } from '~/composables';
import { i18nToAlgoliaMainIndex } from '~/helpers/locales/i18nCurrentDetails';
import { getCurrentInstance } from '~/helpers/getCurrentInstance';
import { getCurrentFiltersCount } from '~/helpers/algolia/getCurrentFiltersCount/getCurrentFiltersCount';
import { getHitsPerPageConfiguration } from '~/helpers/algolia/getHitsPerPage';
import { StringBoolean } from '~/types/StringBoolean';
import {
  BOOLEAN_ATTRIBUTE_LABELS,
  CATEGORY_FILTER_INDICATOR,
  TRANSLATABLE_CURRENT_REFINEMENTS,
} from '~/constants/algolia';
import { getAlgoliaCategoryFilter } from '~/helpers/category/getAlgoliaCategoryFilter';

export const useAlgolia = () => {
  const { $i18n } = getCurrentInstance();
  const { route: currentRoute } = useVSFContext();
  const { isCampaignLabel } = useStoreVsfExtended();
  const { getCampaignLabel } = useCampaignTag();

  const isFiltering = (indexName: string, renderState: any): boolean => {
    return getCurrentFiltersCount(indexName, renderState) > 0;
  };

  const mainSearchIndex: ComputedRef<string> = computed(() => {
    return i18nToAlgoliaMainIndex($i18n);
  });

  const hitsPerPageConfiguration = computed(() => {
    return currentRoute.value.query && getHitsPerPageConfiguration(currentRoute.value.query);
  });

  const getAlgoliaFilterName = (
    filterRawLabel: string,
    filterAttribute: Maybe<string> = null,
  ): string | VueI18n.TranslateResult => {
    if (filterRawLabel === StringBoolean.true) {
      if (filterAttribute) {
        return $i18n.t(BOOLEAN_ATTRIBUTE_LABELS.INCLUSIVE.LONG, { attribute: filterAttribute });
      } else {
        return $i18n.t(BOOLEAN_ATTRIBUTE_LABELS.INCLUSIVE.SHORT);
      }
    } else if (filterRawLabel === StringBoolean.false) {
      if (filterAttribute) {
        return $i18n.t(BOOLEAN_ATTRIBUTE_LABELS.EXCLUSIVE.LONG, { attribute: filterAttribute });
      } else {
        return $i18n.t(BOOLEAN_ATTRIBUTE_LABELS.EXCLUSIVE.SHORT);
      }
    } else if (TRANSLATABLE_CURRENT_REFINEMENTS.includes(filterRawLabel)) {
      return $i18n.t(filterRawLabel);
    } else if (isCampaignLabel(filterRawLabel)) {
      return getCampaignLabel(filterRawLabel);
    } else if (filterRawLabel.includes(CATEGORY_FILTER_INDICATOR)) {
      return filterRawLabel.split(CATEGORY_FILTER_INDICATOR).pop() || '';
    } else {
      return filterRawLabel;
    }
  };

  return {
    hitsPerPageConfiguration,
    isFiltering,
    mainSearchIndex,
    getAlgoliaCategoryFilter,
    getAlgoliaFilterName,
  };
};
