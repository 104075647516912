import { useVSFContext } from '@vue-storefront/core';
import { useCategories, useCategoryTree } from '~/composables';
import { getAlgoliaCategoryFilter } from '~/helpers/category/getAlgoliaCategoryFilter';

export const useIsOnCategory = () => {
  const { route: currentRoute } = useVSFContext();
  const { categories } = useCategories();
  const { categoryTree } = useCategoryTree();

  const isOnCategory = (): boolean => {
    if (!currentRoute.value.params.slug) {
      return false;
    }
    const categoryFilter = getAlgoliaCategoryFilter({
      categorySlug: currentRoute.value.params.slug,
      categories: categories.value,
      categoryTree: categoryTree.value,
    });
    return !!categoryFilter;
  };

  return {
    isOnCategory,
  };
};
