import { ClickBreadcrumbs } from '~/plugins/integrations/tagManager/helpers/ClickBreadcrumbs';
import { Gtm } from '~/types/@nuxtjs/gtm';
import { TriggerClickBreadcrumbsParameters } from '~/types/integrations/tagManager/TagManagerIntegration';

export const triggerClickBreadcrumbsWrapper = ($gtm: Gtm) => {
  return (parameters: TriggerClickBreadcrumbsParameters) => {
    const viewItemListTag = new ClickBreadcrumbs({
      categoryName: parameters.categoryName,
      categoryPath: parameters.categoryPath,
      categoryLevel: parameters.categoryLevel,
      source: parameters.source,
    });
    $gtm.push(viewItemListTag);
  };
};
