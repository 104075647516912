import { computed } from '@nuxtjs/composition-api';
import { defineModule } from '~/helpers/vuex/defineModule/defineModule';
import { MODULE_NAMES } from '~/types/vuex/Vuex';
import { AdyenErrorMutations } from '~/types/vuex/AdyenError';

type ErrorWithData = { data: Maybe<Error> };

export default function () {
  const store = defineModule<
    typeof MODULE_NAMES.ADYEN_ERROR,
    AdyenErrorMutations
  >(MODULE_NAMES.ADYEN_ERROR);

  const error = computed(() => store.state.error);

  const errorPayAndOrder = computed<Maybe<Error>>(() =>
    (error.value?.payAndOrder as {} as ErrorWithData)?.data,
  );

  return {
    error,
    errorPayAndOrder,
    ...store.getters,
    ...store.mutations,
    ...store.actions,
  };
}
