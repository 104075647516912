import type { ProductVariant } from '~/utils/ct/types/product/ProductVariant';
import { PRODUCT_ATTRIBUTES } from '~/constants/products';
import {
  getProductAttributesExtended,
} from '~/helpers/product/getProductAttributesExtended';
import type { ExcludedRelation } from '~/types/extraGuarantee/ExcludedRelations';

export const isSkuExcluded = (product: Maybe<ProductVariant>, excludedSkus: string[]): boolean => {
  const productSku = product?.sku;
  if (!productSku) {
    return true;
  }
  return !!excludedSkus?.includes(productSku);
};

export const isBrandExcluded = (
  product: Maybe<ProductVariant>,
  excludedBrands: string[],
  languageAndCountry: string,
): boolean => {
  if (!product) {
    return true;
  }

  const { brand } = getProductAttributesExtended(
    product,
    { attributeNames: [PRODUCT_ATTRIBUTES.BRAND], languageAndCountry },
  );
  return !!excludedBrands?.includes(brand);
};

export const isRelationExcluded = (product: Maybe<ProductVariant>, excludedRelations: ExcludedRelation[]): boolean => {
  return !!excludedRelations?.some(relation => {
    return product?.attributesRaw.some(attr => attr.name === relation.key);
  });
};

export const checkExclusionRules = (
  product: Maybe<ProductVariant>,
  languageAndCountry: string,
  rules: {
    excludedSkus: string[];
    excludedBrands: string[];
    excludedRelations: ExcludedRelation[];
  },
): boolean => {
  return isSkuExcluded(product, rules.excludedSkus) ||
    isRelationExcluded(product, rules.excludedRelations) ||
    isBrandExcluded(product, rules.excludedBrands, languageAndCountry);
};
