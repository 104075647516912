
























































import {
  defineComponent,
} from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import type { Category } from '@vsf-enterprise/commercetools-types';
import SimpleCarousel from '~/components/molecules/SimpleCarousel/SimpleCarousel.vue';
import { SimpleCarouselArrow } from '~/types/components/SimpleCarousel/SimpleCarouselArrows';
import NavMainBarOnSale from '~/components/molecules/Navigation/NavMainBar/NavMainBarOnSale/NavMainBarOnSale.vue';
import NavMainBarCategory from '~/components/molecules/Navigation/NavMainBar/NavMainBarCategory/NavMainBarCategory.vue';
import NavMainBarAllCategoriesButton from
  '~/components/molecules/Navigation/NavMainBar/NavMainBarAllCategoriesButton/NavMainBarAllCategoriesButton.vue';
import { useIsPage } from '~/composables';

export default defineComponent({
  name: 'NavMainBarDesktop',
  components: {
    SimpleCarousel,
    NavMainBarOnSale,
    NavMainBarCategory,
    NavMainBarAllCategoriesButton,
  },
  props: {
    categories: {
      type: Array as PropType<Category[]>,
      default: () => {
        return [];
      },
    },
    hideOnSaleLink: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const arrowClass = {
      container: `absolute bg-EXPONDO-primary-10 h-full w-10 z-1 top-0
      flex items-center justify-center cursor-pointer`,
      arrow: 'text-EXPONDO-primary text-xl',
    };
    const { isLoginPage } = useIsPage();

    return {
      SimpleCarouselArrow,
      arrowClass,
      isLoginPage,
    };
  },
});
