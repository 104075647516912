import type { ProductVariant } from '~/utils/ct/types/product/ProductVariant';
import { centAmountToNormalPrice } from '~/helpers/cart/getTotalPricesModule';

export const getDiscountedPrice = (product: ProductVariant): number => {
  return centAmountToNormalPrice(product?.price?.discounted?.value?.centAmount);
};
export const getRegularPrice = (product: ProductVariant): number => {
  return centAmountToNormalPrice(product?.price?.value?.centAmount);
};

export const getFinalPrice = (product: ProductVariant): number => {
  return getDiscountedPrice(product) || getRegularPrice(product);
};
