import { computed } from '@nuxtjs/composition-api';
import type{ ComputedRef, Ref } from '@nuxtjs/composition-api';
import type { ProductVariant } from '~/utils/ct/types/product/ProductVariant';
import { getDiscountedPrice, getRegularPrice, getFinalPrice } from '~/helpers/product/getPriceInfo';

type PriceInformationParameters = ComputedRef<number | null>;

export interface PriceInformation {
  productPrice: PriceInformationParameters,
  discountedPrice: ComputedRef<Maybe<number>>,
  priceAfterDiscounts: PriceInformationParameters,
  finalPrice: PriceInformationParameters,
  priceBeforeDiscounts: PriceInformationParameters,
}

export default function () {
  const getPriceInformation = (product: Ref<ProductVariant>): PriceInformation => {
    const priceBeforeDiscounts = computed(() => {
      return getDiscountedPrice(product.value) ? getRegularPrice(product.value) : null;
    });

    const priceAfterDiscounts = computed(() =>
      getDiscountedPrice(product.value) ||
      getRegularPrice(product.value) ||
      0,
    );
    const productPrice = computed(() => priceBeforeDiscounts.value || priceAfterDiscounts.value);
    const discountedPrice = computed(() => priceBeforeDiscounts.value ? priceAfterDiscounts.value : undefined);
    const finalPrice = computed(() => getFinalPrice(product.value));

    return {
      priceBeforeDiscounts,
      priceAfterDiscounts,
      productPrice,
      discountedPrice,
      finalPrice,
    };
  };

  return {
    getPriceInformation,
  };
}
