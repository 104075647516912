const { gql } = require('graphql-tag');
const ShippingDeliveryInformation = require('../fragments/shipping/ShippingDeliveryInformation').fragment;
const { CUSTOM_QUERIES } = require('#~/constants/customQueries.js');

module.exports = {
  [CUSTOM_QUERIES.SHIPPING.shippingMethods.customShippingMethods]: (params) => {
    const variables = params.variables;
    return {
      query: gql`
        ${ShippingDeliveryInformation}

        query shippingMethods {
          shippingMethods: shippingMethodsByLocation(country: "IE") {
            ...ShippingDeliveryInformation
          }
        }
      `,
      variables,
    };
  },
};
