import type { HitResultItem } from 'vue-instantsearch';
import type { ProductVariant } from '~/utils/ct/types/product/ProductVariant';
import { productGetters } from '~/utils/ct';
import { TagProduct, TagProductCategories } from '~/types/integrations/tagManager/TagProduct';
import { TagPromotion } from '~/types/integrations/tagManager/TagPromotion';
import { RemarketingTagParams } from '~/types/integrations/tagManager/RemarketingTagParams';
import { RemarketingPageType } from '~/types/integrations/tagManager/RemarketingPageType';
import { PRODUCT_ATTRIBUTES } from '~/constants/products';
import { SHIPPING_TYPE } from '~/constants/shippingTypes';
import { ITEM_ACCESSORY, ITEM_PRODUCT } from '~/constants/productType';
import {
  getProductAttributesExtended,
} from '~/helpers/product/getProductAttributesExtended';
import { getFinalPrice, getRegularPrice } from '~/helpers/product/getPriceInfo';
import { calculateHitResultProductDiscount } from '~/helpers/algoliaHitProduct/calculateDiscount';
import { ViewAccessoryItems } from '~/types/integrations/tagManager/TagViewAccessory';
import { ITEM_CATEGORY } from '~/constants/googleTagManager';
import type { ProductCategories } from '~/types/product/ProductCategories';
import { CATEGORY_FILTER_INDICATOR } from '~/constants/algolia';
import { TagProductBox, ProductBoxData } from '~/types/integrations/tagManager/TagProductBox';
import { BloomreachProduct } from '~/types/product/BloomreachProduct';
import { ViewParentProducts } from '~/types/integrations/tagManager/TagViewParentProducts';
import { getProductGTIN } from '~/helpers/Checkout/getProductGTIN';

// TODO - TRAN-526 - extend ProductVariant type with breadcrumbs etc
type ProductVariantWithBreadcrumbs = ProductVariant & {
  _breadcrumbs: any
};

const createDefaultCategories = (): TagProductCategories => ({
  item_category: '',
  item_category2: '',
});

const extractProductCategory = (product: ProductVariant, level: number): string => {
  const productWithBreadcrumbs = product as ProductVariantWithBreadcrumbs;
  const breadcrumbs = productWithBreadcrumbs?._breadcrumbs?.[0];
  return breadcrumbs?.length ? breadcrumbs[breadcrumbs.length - level]?.name : '';
};

const setProductCategories = (product: ProductVariant): TagProductCategories => {
  const productWithBreadcrumbs = product as ProductVariantWithBreadcrumbs;
  const productCategoriesLenght = productWithBreadcrumbs?._breadcrumbs?.[0]?.length || 0;
  const categories: TagProductCategories = {
    item_category: '',
    item_category2: '',
  };
  for (let i = 1; i <= productCategoriesLenght; i++) {
    const key = i === 1 ? ITEM_CATEGORY : `${ITEM_CATEGORY}${i}` as keyof TagProductCategories;
    categories[key] = extractProductCategory(product, productCategoriesLenght - i);
  }
  return categories;
};

export const isValidCategoryKey = (key: string): key is keyof TagProductCategories => {
  return key.startsWith(ITEM_CATEGORY);
};

export const generateCategoryKey = (index: number): string => {
  return `${ITEM_CATEGORY}${index === 0 ? '' : index + 1}`;
};

export const handleStringCategories = (
  productCategories: string,
): TagProductCategories => {
  const categories = createDefaultCategories();
  const categoryArray = productCategories.split(CATEGORY_FILTER_INDICATOR).map(category => category.trim());

  categoryArray.forEach((category, index) => {
    const key = generateCategoryKey(index);
    if (isValidCategoryKey(key)) {
      categories[key] = category;
    }
  });

  return categories;
};

export const handleObjectCategories = (
  productCategories: Record<string, string[]>,
): TagProductCategories => {
  const categories = createDefaultCategories();
  Object.values(productCategories).forEach((category, index) => {
    if (!category?.length) return;

    const categoryLevel = category[0];
    let categoryValue: string = categoryLevel?.trim() || '';

    if (index > 0) {
      const lastFilterIndicatorIndex = categoryValue.lastIndexOf(CATEGORY_FILTER_INDICATOR);
      if (lastFilterIndicatorIndex !== -1) {
        categoryValue = categoryValue.substring(lastFilterIndicatorIndex + CATEGORY_FILTER_INDICATOR.length).trim();
      }
    }

    const key = generateCategoryKey(index);
    if (isValidCategoryKey(key)) {
      categories[key] = categoryValue;
    }
  });

  return categories;
};

export const prepareProductTags = (
  product: ProductVariant,
  details: {
    currency?: string,
    quantity?: number,
    languageAndCountry?: string,
    inStock?: boolean,
    index?: number
  },
): TagProduct => {
  const localizedAttributes = getProductAttributesExtended(product, {
    attributeNames: [
      PRODUCT_ATTRIBUTES.IS_PACKAGE_TYPE_FREIGHT,
      PRODUCT_ATTRIBUTES.BRAND,
    ],
    languageAndCountry: details.languageAndCountry,
  });

  const rawAttributes = getProductAttributesExtended(product, {
    attributeNames: [
      PRODUCT_ATTRIBUTES.PARENT_PRODUCTS_FOR_ACCESSORY,
      PRODUCT_ATTRIBUTES.MODEL,
    ],
  });

  const priceBeforeDiscounts = getRegularPrice(product);
  const price = getFinalPrice(product);
  const discount = priceBeforeDiscounts && priceBeforeDiscounts > price ? priceBeforeDiscounts - price : 0;

  const isFreight = localizedAttributes[PRODUCT_ATTRIBUTES.IS_PACKAGE_TYPE_FREIGHT];
  const hasParent = rawAttributes[PRODUCT_ATTRIBUTES.PARENT_PRODUCTS_FOR_ACCESSORY];
  const productTags: TagProduct = {
    ...setProductCategories(product),
    item_name: productGetters.getName(product),
    price,
    item_id: productGetters.getSku(product),
    item_brand: localizedAttributes[PRODUCT_ATTRIBUTES.BRAND],
    item_variant: rawAttributes[PRODUCT_ATTRIBUTES.MODEL],
    currency: details.currency,
    discount,
    quantity: details.quantity || 1,
    in_stock: details.inStock,
    shipping_type: isFreight ? SHIPPING_TYPE.FREIGHT : SHIPPING_TYPE.PARCEL,
    item_type: hasParent ? ITEM_ACCESSORY : ITEM_PRODUCT,
    index: details.index,
    gtin: getProductGTIN(product),
  };

  if (details.languageAndCountry) {
    productTags.languageAndCountry = details.languageAndCountry;
  }

  return productTags;
};

export const prepareSimilarProductTags = (
  similarProducts: ProductVariant[], languageAndCountry: string): TagProduct[] => {
  const preparedSimilarProducts = [];
  similarProducts.map((product, index) =>
    preparedSimilarProducts.push({
      brand: getProductAttributesExtended(product, {
        attributeNames: [PRODUCT_ATTRIBUTES.BRAND],
        languageAndCountry,
      })[PRODUCT_ATTRIBUTES.BRAND],
      name: productGetters.getName(product),
      price: getFinalPrice(product),
      id: productGetters.getSku(product),
      list: 'Similar Articles',
      position: index + 1,
    }),
  );
  return preparedSimilarProducts;
};

export const preparePromotionTags = (promotionImageName: string, promotionImagePosition: number): TagPromotion[] => {
  const staticPromotionId = 'HP_Slider';
  const staticPromotionName = 'HP Slider';
  return [
    {
      creative: promotionImageName,
      id: staticPromotionId,
      name: staticPromotionName,
      position: promotionImagePosition,
    },
  ];
};

export const prepareRemarketingTags = (
  pageType: RemarketingPageType,
  product: ProductVariant,
): RemarketingTagParams => {
  const price = getFinalPrice(product);
  return {
    ecomm_pagetype: pageType,
    ecomm_pcat: [extractProductCategory(product, 1)],
    ecomm_pname: productGetters.getName(product),
    ecomm_prodid: productGetters.getSku(product),
    ecomm_pvalue: price,
    ecomm_totalvalue: price,
  };
};

export const extractProductCategories = (
  productCategories: ProductCategories,
): TagProductCategories => {
  return typeof productCategories === 'string'
    ? handleStringCategories(productCategories)
    : handleObjectCategories(productCategories);
};

export const prepareViewItemListTags = (
  products: HitResultItem[],
  languageAndCountry: string,
): TagProduct[] => {
  const preparedProducts: TagProduct[] = [];
  products.forEach(product => {
    const discount = calculateHitResultProductDiscount(product);

    return preparedProducts.push({
      ...extractProductCategories(product.categories || {}),
      item_brand: product.brand || '',
      currency: product.priceCurrency,
      item_id: product.objectID,
      languageAndCountry,
      item_name: product.name || '',
      discount,
      price: product.price || 0,
      quantity: product.stockCount || 0,
      in_stock: product.inStock,
    });
  });
  return preparedProducts;
};

const extractProductSkus = (products: ProductVariant[]): string[] => {
  return products?.reduce((filtered: string[], product) => {
    if (product.sku) {
      filtered.push(product.sku);
    }
    return filtered;
  }, []);
};

export const prepareViewAccessoryItemsTags = (
  accessoryProducts: ProductVariant[],
): ViewAccessoryItems => {
  const accessoryIds = extractProductSkus(accessoryProducts);
  return {
    accessory_count: accessoryIds.length,
    accessory_ids: accessoryIds,
  };
};

export const prepareParentProductsTags = (
  parentProducts: ProductVariant[],
): ViewParentProducts => {
  const parentIds = extractProductSkus(parentProducts);
  return {
    parent_count: parentIds.length,
    parent_ids: parentIds,
  };
};

export const prepareClickProductBoxTags = (
  product: ProductBoxData,
): TagProductBox => {
  return {
    item_id: product.id,
    item_name: product.name,
    currency: product.currency,
    discount: product.discountedPrice ? +(product.price - product.discountedPrice).toFixed(2) : 0,
    item_brand: product.brand,
    price: product.discountedPrice ? product.discountedPrice : product.price,
    ...extractProductCategories(product.categories),
  };
};

export const prepareProductTagsFromBloomreachProducts = (product: BloomreachProduct): TagProductBox => {
  return {
    item_id: product.item_id,
    item_name: product.title,
    currency: product.local_currency,
    discount: product.discount_value_local_currency || 0,
    item_brand: product.brand,
    price: product.price_local_currency,
    item_category: product.category_level_1,
    item_category2: product.category_level_2,
    item_category3: product.category_level_3,
    item_category4: product.category_level_4,
  };
};
