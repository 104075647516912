import { useVSFContext } from '@vue-storefront/core';
import { computed, onMounted } from '@nuxtjs/composition-api';
import type { Ref } from '@nuxtjs/composition-api';
import { useInternalCms } from '@/composables';
import { CAMPAIGN_INDICATOR } from '~/constants/commerceTools';
import { COMPONENTS } from '~/constants/cms';

interface Campaign {
  campaignName: string;
  pageTitle?: string;
  imageUrl?: string;
}

export const useDisplayCampaignInfo = () => {
  const { route } = useVSFContext();
  const campaignTag = 'refinementList[tags][0]';
  const campaignTagValue = computed(() => route.value.query[campaignTag]);

  const {
    loadComponent: loadCampaignBanner,
    internalCmsContent: campaignBannerContent,
    canShowContent: canShowCampaignBannerContent,
  } = useInternalCms(COMPONENTS.CAMPAIGN_BANNER.SLUG);

  const {
    loadComponent: loadCampaignTitle,
    internalCmsContent: campaignTitleContent,
    canShowContent: canShowCampaignTitleContent,
  } = useInternalCms(COMPONENTS.CAMPAIGN_TITLE.SLUG);

  const isCampaignPage = computed(() => campaignTagValue.value?.includes(CAMPAIGN_INDICATOR) ?? false);

  const currentCampaignName = computed(() => {
    if (typeof campaignTagValue.value === 'string') {
      return campaignTagValue.value.split(':')[1] || null;
    } else if (Array.isArray(campaignTagValue.value)) {
      return campaignTagValue.value[0]?.split(':')[1] || null;
    }

    return null;
  });

  const showCampaignInfo =
    computed(() => isCampaignPage.value && canShowCampaignBannerContent.value && canShowCampaignTitleContent.value);

  const getCampaignData = (campaignContent: Ref<Record<string, any>[]>) => {
    const campaignName = currentCampaignName.value;
    return campaignContent.value[0]?.campaigns?.find((campaign: Campaign) => campaign.campaignName === campaignName);
  };

  const campaignData = computed(() => {
    const titleData = getCampaignData(campaignTitleContent);
    const bannerData = getCampaignData(campaignBannerContent);

    return (titleData && bannerData)
      ? {
        title: [{
          pageTitle: titleData.pageTitle,
          component: campaignTitleContent.value[0]?.component,
        }],
        banner: [{
          imageUrl: bannerData.imageUrl,
          component: campaignBannerContent.value[0]?.component,
        }],
      }
      : null;
  });
  onMounted(() => {
    loadCampaignBanner();
    loadCampaignTitle();
  });

  return {
    showCampaignInfo,
    campaignData,
  };
};
