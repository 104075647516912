export default (context: any) => {
  const INJECTED_SCRIPT_DEFAULTS = {
    hid: 'consentmanager',
    type: 'text/javascript',
    defer: false,
    src: '/consentManager/consent-manager-unified.js',
  };

  context.app.head.script.push({
    ...INJECTED_SCRIPT_DEFAULTS,
  });
};
