const { clientQuery } = require('./queries');
const handleCtApiError = require('./handleCtApiError');

const executeQuery = async (context, queryData) => {
  try {
    const response = await clientQuery(context, {
      query: queryData.query,
      variables: queryData.variables,
    });
    return response?.data?.customer || {};
  } catch (error) {
    throw handleCtApiError(error);
  }
};

module.exports = executeQuery;
