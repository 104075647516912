import { Logger } from '@vue-storefront/core';
import { ValidationProvider } from '~/types/vee-validate';

export default (fields?: Record<string, ValidationProvider>) => {
  if (!fields) return;
  Object.values(fields).forEach(({ invalid, name, failedRules }) => {
    if (invalid && failedRules) {
      Object.entries(failedRules).forEach(([rule, message]) => {
        Logger.info(`Failed address validation: ${name}`, {
          field: name,
          rule,
          errorMessage: message,
        });
      });
    }
  });
};
