import { EVENT_VIEW_COMPLEMENTARY } from '~/constants/googleTagManager';

/* eslint-disable camelcase */
export class ViewComplementaryTags {
  event = EVENT_VIEW_COMPLEMENTARY;
  items: {
    complementary_count: number;
    complementary_ids: string[];
  };

  constructor(items: string[]) {
    this.items = {
      complementary_count: items.length,
      complementary_ids: items,
    };
  }
}
