import type { HitResultItem } from 'vue-instantsearch';
import type { ProductVariantExtended } from '~/types/product/ProductVariantExtended';
import type { ProductAttributes } from '~/types/product/attribute/ProductAttributes';
import { PRODUCT_ATTRIBUTES } from '~/constants/products';
import {
  getProductAttributesExtended,
} from '~/helpers/product/getProductAttributesExtended';
import type { ProductLocalisedAttributes } from '~/types/product/attribute/ProductLocalisedAttributes';
import type { EnergyRatingDetails } from '~/types/components/EnergyRating/EnergyRatingDetails';

interface EnergyRatingParams {
  product?: ProductVariantExtended;
  localisedAttributes?: ProductLocalisedAttributes;
  item?: HitResultItem;
  languageAndCountry?: string;
}

export const getEnergyRating = (params: EnergyRatingParams): EnergyRatingDetails => {
  const { product, localisedAttributes, item, languageAndCountry } = params;

  const getAttributeValue = (
    attributeKey: ProductAttributes,
    localisedAttributeKey?: keyof ProductLocalisedAttributes,
  ) => {
    if (product) {
      return getProductAttributesExtended(
        product,
        {
          attributeNames: [attributeKey],
          languageAndCountry,
        })[attributeKey];
    } else if (localisedAttributes && localisedAttributeKey && localisedAttributeKey in localisedAttributes) {
      return localisedAttributes[localisedAttributeKey];
    } else if (item) {
      return item[attributeKey];
    }
  };

  const labelUrl = getAttributeValue(PRODUCT_ATTRIBUTES.ENERGY_RATING_LABEL);
  const productCardUrl =
    getAttributeValue(PRODUCT_ATTRIBUTES.ENERGY_RATING_FICHE,
      PRODUCT_ATTRIBUTES.ENERGY_RATING_FICHE as keyof ProductLocalisedAttributes);
  const rating = getAttributeValue(PRODUCT_ATTRIBUTES.ENERGY_RATING_NESTED_LABEL);

  return {
    labelUrl,
    productCardUrl,
    rating,
  };
};
