import { Logger } from '@vue-storefront/core';
import { BLOOMREACH_COOKIE } from '~/constants/bloomreach';
import { BloomreachIntegration } from '~/types/integrations/bloomreach/BloomreachIntegration.js';
import { BLOOMREACH_TYPE_NAME, BloomreachTypeName } from '~/types/localization/localizationHelperTypes';
import { BloomreachProduct } from '~/types/product/BloomreachProduct';
import { RecommendationFactoryParams } from '~/types/integrations/bloomreach/RecommendationFactoryParams';
import { RecommendationRequestParams } from '~/types/integrations/bloomreach/RecommendationRequestParams';
import { getCartRecommendationsData } from '~/plugins/integrations/bloomreach/helpers/getCartRecommendationsData';

export const getRecommendationFactory = ({ instance, typeName }:
  {
    instance: BloomreachIntegration,
    typeName: BloomreachTypeName
  },
): (params: RecommendationFactoryParams) => Promise<BloomreachProduct[] | null> => {
  const { axios, cookies } = instance.services;
  const { languageAndCountry, bloomreachDetails } = instance.state;

  const typeId = bloomreachDetails?.[typeName];

  return async (params: RecommendationFactoryParams = {}): Promise<BloomreachProduct[] | null> => {
    try {
      if (process.server) {
        return null;
      }

      const apiUrl = 'recommendation';
      const bloomreachCookie = cookies?.get(BLOOMREACH_COOKIE);
      const domainId = bloomreachDetails?.key;

      if (!domainId || !typeId) {
        return null;
      }
      let data: RecommendationRequestParams = {
        bloomreachCookie,
        typeId,
        domainId,
        languageAndCountry,
      };
      switch (typeName) {
        case BLOOMREACH_TYPE_NAME.LAST_SEEN: break;
        case BLOOMREACH_TYPE_NAME.SIMILAR_PRODUCTS:
          data.sourceSku = params.sourceSku;
          break;
        case BLOOMREACH_TYPE_NAME.CART_RECOMMENDATION:
          data = { ...data, ...getCartRecommendationsData(params?.product) };
          break;
      }
      const res = await axios.post(apiUrl, data);
      const products = res?.data?.results?.[0]?.value;

      if (!products || !products.length) {
        return null;
      }
      return products;
    } catch (error) {
      Logger.error(error);
    }

    return null;
  };
};
