import { computed } from '@nuxtjs/composition-api';
import { defineModule } from '~/helpers/vuex/defineModule/defineModule';
import {
  ComplementarySidebarMutations,
} from '~/types/vuex/ComplementarySidebar';
import { MODULE_NAMES } from '~/types/vuex/Vuex';
export default function () {
  const store = defineModule<
    typeof MODULE_NAMES.COMPLEMENTARY_SIDEBAR_MODULE_NAME,
    ComplementarySidebarMutations,
    {},
    {}
  >(MODULE_NAMES.COMPLEMENTARY_SIDEBAR_MODULE_NAME);
  const isComplementarySidebarOpen = computed(() => store.state.isComplementarySidebarOpen);
  return {
    isComplementarySidebarOpen,
    ...store.mutations,
  };
}
