/**
 * @param {Error} error
 * @returns {Error}
 */
const handleCtApiError = (error) => {
  const graphQLErrors = error.graphQLErrors || null;
  const networkError = error.networkError || null;

  return graphQLErrors?.[0] || networkError?.result || error;
};

module.exports = handleCtApiError;
