module.exports = {
  EXTRA_GUARANTEE_PRICE: 'EXTRA-GUARANTEE-PRICE',
  SKU_FOR_EXTRA_GUARANTEE: 'SKU-FOR-EXTRA-GUARANTEE',
  PRODDUCT_PRICE_FOR_EXTRA_GUARANTEE: 'PRODDUCT-PRICE-FOR-EXTRA-GUARANTEE',
  PRODDUCT_NAME_FOR_EXTRA_GUARANTEE: 'PRODDUCT-NAME-FOR-EXTRA-GUARANTEE',
  PRODDUCT_IMAGE_FOR_EXTRA_GUARANTEE: 'PRODDUCT_IMAGE_FOR_EXTRA_GUARANTEE',
  PARENT_SKU: 'parent_sku',
  EXTRA_GUARANTEE_ATTRIBUTES: {
    EXCLUDED_BRANDS: 'extra-guarantee-excluded-brands',
    EXCLUDED_SKUS: 'extra-guarantee-excluded-skus',
    EXCLUDED_RELATIONS: 'extra-guarantee-excluded-relations',
    EXTRA_GUARANTEE_RATE: 'relative-price-rate',
    ENABLED_ON_MARKET: 'enabledOnMarket',
  },
};
