enum languageAndCountryCodes {
    EN_IE = 'en-IE',
    SK_SK = 'sk-SK',
    DE_DE = 'de-DE',
    PT_PT = 'pt-PT',
    IT_IT = 'it-IT',
    FR_FR = 'fr-FR',
    HU_HU = 'hu-HU',
    CS_CZ = 'cs-CZ',
    DE_AT = 'de-AT',
    NB_NO = 'nb-NO',
    NL_NL = 'nl-NL',
    DE_CH = 'de-CH',
    FR_CH = 'fr-CH',
    IT_CH = 'it-CH',
    NL_BE = 'nl-BE',
    FR_BE = 'fr-BE',
    DA_DK = 'da-DK',
    SV_SE = 'sv-SE',
    ES_ES = 'es-ES',
    EN_GB = 'en-GB',
    FI_FI = 'fi-FI',
    PL_PL = 'pl-PL',
    RO_RO = 'ro-RO',
    LT_LT = 'lt-LT',
    SL_SI = 'sl-SI',
    EL_GR = 'el-GR',
    HR_HR = 'hr-HR',
    BG_BG = 'bg-BG',
}

const defaultLanguageAndCountryCode = languageAndCountryCodes.SK_SK;

export {
  languageAndCountryCodes,
  defaultLanguageAndCountryCode,
};
