import { Logger, useVSFContext } from '@vue-storefront/core';
import { ref } from '@nuxtjs/composition-api';
import type { Category } from '@vsf-enterprise/commercetools-types';
import type { SiblingCategory } from '~/types/product/ProductCategories';
import { useI18n } from '~/composables';
import { ProductVariantExtended } from '~/types/product/ProductVariantExtended';
import { CUSTOM_QUERIES } from '~/constants/customQueries';
import { removeEmptyCategories } from '~/helpers/category/categoriesTree';
import { getBreadcrumbsFromProduct } from '~/helpers/category/getBreadcrumbsFromProduct';

export const useSiblingCategories = () => {
  const { $ct } = useVSFContext();
  const { languageAndCountry } = useI18n();

  const siblingCategories = ref<Category[]>([]);

  const isSiblingCategory = (category: Category | SiblingCategory) => {
    return typeof category.slug === 'string' && typeof category.name === 'string';
  };

  const load = async (product: ProductVariantExtended) => {
    if (!product._breadcrumbs || !product._breadcrumbs[0]) {
      Logger.error('error.useSiblingCategories', 'Product breadcrumbs are missing.');
      return;
    }

    const breadcrumbs = getBreadcrumbsFromProduct(product);
    const lastCategorySlug = breadcrumbs?.at(-1)?.slug || {};
    const lastAncestorSlug = breadcrumbs?.at(-2)?.slug || {};

    if (!lastCategorySlug || !lastAncestorSlug) {
      return;
    }

    try {
      const categoryResponse = await $ct.api.getCategory(
        { slug: lastAncestorSlug, acceptLanguage: languageAndCountry.value },
        { categories: CUSTOM_QUERIES.CATEGORIES.SIBLING },
      );
      const siblingCategoriesResponse = categoryResponse?.data?.categories?.results?.[0].children;
      if (siblingCategoriesResponse.length) {
        siblingCategories.value = siblingCategoriesResponse.filter((child: Category) => {
          const isNotEmpty = removeEmptyCategories([child], languageAndCountry.value).length > 0;
          return isNotEmpty && isSiblingCategory(child) && child.slug !== lastCategorySlug;
        });
      }
    } catch (err) {
      Logger.error('error.useSiblingCategories', String(err));
    }
  };

  return {
    siblingCategories,
    load,
  };
};
