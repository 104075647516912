enum currencyCodes {
  EUR = 'EUR',
  GBP = 'GBP',
  CHF = 'CHF',
  CZK = 'CZK',
  DKK = 'DKK',
  SEK = 'SEK',
  HUF = 'HUF',
  RON = 'RON',
  NOK = 'NOK',
  PLN = 'PLN',
  BGN = 'BGN',
  USD = 'USD',
}

const defaultCurrencyCode = currencyCodes.EUR;

export { currencyCodes, defaultCurrencyCode };
