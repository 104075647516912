import { BundleBuilderViewTags } from '~/plugins/integrations/tagManager/helpers/BundleBuilderViewTags';
import { Gtm } from '~/types/@nuxtjs/gtm';
import { BundleBuilderBaseParameter } from '~/types/integrations/tagManager/TagManagerIntegration';

export const triggerViewBundleBuilderWrapper = ($gtm: Gtm) => {
  return (parameters: BundleBuilderBaseParameter) => {
    const bundleBuilderView = new BundleBuilderViewTags(parameters);
    $gtm.push(bundleBuilderView);
  };
};
