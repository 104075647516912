
import { EVENT_BUNDLE_BUILDER } from '~/constants/googleTagManager';

export class BundleBuilderViewTags {
  event = EVENT_BUNDLE_BUILDER.VIEW;
  product_id: string;
  constructor({ sku }: {
    sku: string
  }) {
    this.product_id = sku;
  }
}
