const { gql } = require('graphql-tag');
const { Logger } = require('@vue-storefront/core');
const getMyCart = require('../queries/cart/getMyCart');

const computeMoreOptions = (context) => {
  return {
    fetchPolicy: 'no-cache',
    context: {
      req: context.req,
      res: context.res,
    },
  };
};

const clientQuery = (context, options) => {
  return context.client.query({
    ...options,
    ...computeMoreOptions(context),
  });
};
module.exports.clientQuery = clientQuery;

const clientMutate = (context, options) => {
  return context.client.mutate({
    ...options,
    ...computeMoreOptions(context),
  });
};
module.exports.clientMutate = clientMutate;

module.exports.fetchMyCart = async (context, cartId) => {
  const { locale, acceptLanguage, currency, store } = context.config;
  let res;
  try {
    const query = gql`${getMyCart}`;
    res = await clientQuery(context, {
      query,
      variables: {
        cartId,
        locale,
        acceptLanguage,
        currency,
        storeKey: store,
      },
    });
  } catch (e) {
    Logger.error(e);
  }

  return {
    ...res.data.inStore.me.cart,
  };
};
