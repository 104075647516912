export const TRUSTPILOT_CAROUSEL_STARS_RATING: string = '4,5';

export const TRUSTPILOT_THEMES = {
  DARK: 'dark',
  LIGHT: 'light',
} as const;

export const TRUSTPILOT_LOGOS = {
  BLACK: 'black',
  WHITE: 'white',
} as const;
