import type { LineItem, Cart } from '@vsf-enterprise/commercetools-types';
import type {
  ProductVariant,
  DiscountCodeInfo,
  CustomQuery,
} from '~/utils/ct';
import { useIntegrations } from '~/composables';
import { catchApiErrors } from '~/utils/ct/composables/helpers/apiHelpers';

export function useCartApi() {
  const { $ct } = useIntegrations({ vsfCt: true });

  const load = async ({ customQuery }: { customQuery?: CustomQuery } = { customQuery: undefined }) => {
    const response = await $ct.api.getMe({ customer: false }, customQuery);
    catchApiErrors(response);
    return response.data.me?.activeCart || null;
  };

  const addItem = async ({
    currentCart,
    product,
    quantity,
    supplyChannel,
    distributionChannel,
    customQuery,
  }: {
    currentCart: Cart;
    product: ProductVariant;
    quantity: number;
    supplyChannel?: string;
    distributionChannel?: string;
    customQuery?: CustomQuery;
  }) => {
    let cart = currentCart;
    if (!currentCart) {
      const { data } = await $ct.api.createCart({}, currentCart);
      cart = data.cart;
    }
    const response = await $ct.api.addToCart(
      { id: cart.id, version: cart.version },
      { product, quantity, supplyChannel, distributionChannel },
      customQuery,
    );
    catchApiErrors(response);
    return response.data.cart;
  };

  const removeItem = async ({
    currentCart,
    product,
    customQuery,
  }: { currentCart: Cart; product: LineItem; customQuery?: CustomQuery; }) => {
    const response = await $ct.api.removeFromCart(
      { id: currentCart.id, version: currentCart.version },
      product,
      customQuery,
    );
    catchApiErrors(response);
    return response.data.cart;
  };

  const updateItemQty = async ({
    currentCart,
    product,
    quantity,
    customQuery,
  }: {
    currentCart?: Cart;
    product: LineItem;
    quantity?: number;
    customQuery?: CustomQuery;
}) => {
    const response = await $ct.api.updateCartQuantity(
      { id: currentCart?.id, version: currentCart?.version },
      { ...product, quantity },
      customQuery,
    );
    catchApiErrors(response);
    return response.data.cart;
  };

  const clear = async ({ currentCart }: { currentCart: Cart }) => {
    const response = await $ct.api.deleteCart({
      id: currentCart.id,
      version: currentCart.version,
    });
    catchApiErrors(response);
    return response.data.cart;
  };

  const applyCoupon = async ({
    currentCart,
    couponCode,
    customQuery,
  }: {
    currentCart: Cart;
    couponCode: string;
    customQuery?: CustomQuery;
  }) => {
    const response = await $ct.api.applyCartCoupon(
      { id: currentCart.id, version: currentCart.version },
      couponCode,
      customQuery,
    );
    catchApiErrors(response);
    return {
      updatedCart: response.data.cart,
      updatedCoupon: couponCode,
    };
  };

  const removeCoupon = async ({
    currentCart,
    couponCode,
    customQuery,
  }: { currentCart: Cart; couponCode: string; customQuery?: CustomQuery }) => {
    const couponId = currentCart?.discountCodes?.find(
      (d: DiscountCodeInfo) => d?.discountCode?.code === couponCode,
    )?.discountCode?.id;

    if (!couponId) {
      return { updatedCart: currentCart };
    }
    const response = await $ct.api.removeCartCoupon(
      { id: currentCart.id, version: currentCart.version },
      { id: couponId, typeId: 'discount-code' },
      customQuery,
    );
    catchApiErrors(response);
    return { updatedCart: response.data.cart };
  };

  const setItemSupplyChannel = async ({
    currentCart,
    product,
    customQuery,
  }: {
    currentCart: Cart;
    product: ProductVariant;
    customQuery?: CustomQuery;
  }) => {
    const response = await $ct.api.updateCartItemChannel(
      { id: currentCart.id, version: currentCart.version },
      { lineItem: product, channelId: 'SOME_CHANNEL_ID' },
      customQuery,
    );
    catchApiErrors(response);
    return { updatedCart: response.data.cart };
  };

  return {
    load,
    addItem,
    removeItem,
    updateItemQty,
    clear,
    applyCoupon,
    removeCoupon,
    setItemSupplyChannel,
  };
}
