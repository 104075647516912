import { computed } from '@nuxtjs/composition-api';
import { useVSFContext } from '@vue-storefront/core';
import {
  getProductAttributesExtended,
} from '~/helpers/product/getProductAttributesExtended';
import type { ProductVariantExtended } from '~/types/product/ProductVariantExtended';
import type { ProductProcessedAttributes } from '~/types/product/attribute/ProductProcessedAttributes';
import type { ProductDetail } from '~/types/product/attribute/ProductDetail';
import { PRODUCT_ATTRIBUTES } from '~/constants/products';

const DIMENSIONS_PRIORITY = 1;

/*
 * Custom composable for product technical details information
 */
export const useProductDetailsCombined = () => {
  const { i18n } = useVSFContext();

  const combineTechnicalDetails = (
    product: ProductVariantExtended,
    attributesLocalized: ProductProcessedAttributes,
  ) => {
    if (!attributesLocalized?.techdetails) {
      attributesLocalized.techdetails = [];
    }
    const technicalDetails: ProductDetail[] = attributesLocalized.techdetails;

    const addItemToTechnicalDetailsArray = (
      label: string,
      value: number | string,
      priority?: ProductDetail['priority'],
    ) => {
      technicalDetails.push({ key: label.toString(), values: [value.toString()], priority });
    };

    const productAttributes = computed(() => getProductAttributesExtended(
      product,
      {
        attributeNames: [
          PRODUCT_ATTRIBUTES.NETTO_LENGTH,
          PRODUCT_ATTRIBUTES.NETTO_WIDTH,
          PRODUCT_ATTRIBUTES.NETTO_HEIGHT,
          PRODUCT_ATTRIBUTES.NETTO_WEIGHT,
          PRODUCT_ATTRIBUTES.BRUTTO_LENGTH,
          PRODUCT_ATTRIBUTES.BRUTTO_WIDTH,
          PRODUCT_ATTRIBUTES.BRUTTO_HEIGHT,
          PRODUCT_ATTRIBUTES.BRUTTO_WEIGHT,
        ],
      }));

    const dimensions = i18n.t('Dimensions Value', {
      length: productAttributes.value?.nettoLength,
      width: productAttributes.value?.nettoWidth,
      height: productAttributes.value?.nettoHeight,
    });
    addItemToTechnicalDetailsArray(i18n.t('Dimensions'), dimensions, DIMENSIONS_PRIORITY);

    const weight = i18n.t('Weight Value', {
      weight: productAttributes.value?.nettoWeight,
    });
    addItemToTechnicalDetailsArray(i18n.t('Weight'), weight);

    const shippingDimensions = i18n.t('Dimensions Value', {
      length: productAttributes.value?.bruttoLength,
      width: productAttributes.value?.bruttoWidth,
      height: productAttributes.value?.bruttoHeight,
    });
    addItemToTechnicalDetailsArray(i18n.t('Shipping Dimensions'), shippingDimensions);

    const shippingWeight = i18n.t('Weight Value', {
      weight: productAttributes.value?.bruttoWeight,
    });
    addItemToTechnicalDetailsArray(i18n.t('Shipping Weight'), shippingWeight);
  };

  return {
    combineTechnicalDetails,
  };
};
