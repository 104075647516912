const { updateCart } = require('./updateCart');
const { createRemoveLineItemAction } = require('#~/helpers/cart/cartActions/createRemoveLineItemAction.js');
/**
 * Endpoint for removing product from the cart. By default, it uses
 * the {@link updateCartDefaultQuery} GraphQL query
 *
 * @param context - Automatically injected context. Refer to {@link SetupContext}
 * @param cart - Cart information
 * @param product - Product information
 * @param customQuery - Custom queries included in the request
 * @param responseOptions - Custom options for the response
 * @returns User cart information
 */
module.exports.removeFromCart = (context, cart, product, customQuery, responseOptions) => {
  // TODO: We should consider merging the second and third parameters into one
  return updateCart(context, {
    ...cart,
    actions: [createRemoveLineItemAction(product)],
  }, customQuery, responseOptions);
};
