import { Logger, sharedRef } from '@vue-storefront/core';
import { computed } from '@nuxtjs/composition-api';
import type { LineItem, Cart } from '@vsf-enterprise/commercetools-types';
import { useCartApi } from '~/utils/ct/composables/api/useCartApi';
import { errorToString } from '~/helpers/error/errorToString';
import type { CustomQuery, ProductVariant } from '~/utils/ct';

export function useCart() {
  const {
    load: apiLoad,
    addItem: apiAddItem,
    removeItem: apiRemoveItem,
    updateItemQty: apiUpdateItemQty,
    clear: apiClear,
    applyCoupon: apiApplyCoupon,
    removeCoupon: apiRemoveCoupon,
    setItemSupplyChannel: apiSetItemSupplyChannel,
  } = useCartApi();

  const cart = sharedRef<Cart | null>(null, 'useCart-cart');
  const loading = sharedRef<boolean>(false, 'useCart-loading');
  const error = sharedRef<{
    load: string | null;
    addItem: string| null ;
    removeItem: string | null ;
    updateItemQty: string | null ;
    clear: string | null ;
    applyCoupon: string | null ;
    removeCoupon: string | null ;
    setItemSupplyChannel: string | null ;
  }>(
    {
      load: null,
      addItem: null,
      removeItem: null,
      updateItemQty: null,
      clear: null,
      applyCoupon: null,
      removeCoupon: null,
      setItemSupplyChannel: null,
    },
    'useCart-error',
  );

  async function load(params: { customQuery?: CustomQuery }) {
    Logger.debug('useCart.load');
    try {
      loading.value = true;
      const loadedCart = await apiLoad(params);
      cart.value = loadedCart;
      error.value.load = null;
    } catch (err) {
      error.value.load = errorToString(err);
      Logger.error('useCart.load', err);
    } finally {
      loading.value = false;
    }
  }

  function setCart(newCart: Cart) {
    cart.value = newCart;
    Logger.debug('useCartFactory.setCart', newCart);
  }

  async function addItem(params: {
    product: ProductVariant;
    quantity: number;
    triggeredIn?: string;
    customQuery: CustomQuery;
}) {
    Logger.debug('useCart.addItem');
    try {
      loading.value = true;
      const updatedCart = await apiAddItem({ currentCart: cart.value, ...params });
      cart.value = updatedCart;
      error.value.addItem = null;
    } catch (err) {
      error.value.addItem = errorToString(err);
      Logger.error('useCart.addItem', err);
    } finally {
      loading.value = false;
    }
  }

  async function removeItem(params: { product: LineItem, customQuery: CustomQuery }) {
    Logger.debug('useCart.removeItem');
    try {
      loading.value = true;
      const updatedCart = await apiRemoveItem({ currentCart: cart.value, ...params });
      cart.value = updatedCart;
      error.value.removeItem = null;
    } catch (err) {
      error.value.removeItem = errorToString(err);
      Logger.error('useCart.removeItem', err);
    } finally {
      loading.value = false;
    }
  }

  async function updateItemQty(params: {
    currentCart?: Cart;
    product: LineItem;
    quantity?: number;
    customQuery?: CustomQuery;
}) {
    Logger.debug('useCart.updateItemQty');
    try {
      loading.value = true;
      const updatedCart = await apiUpdateItemQty({ currentCart: cart.value, ...params });
      cart.value = updatedCart;
      error.value.updateItemQty = null;
    } catch (err) {
      error.value.updateItemQty = errorToString(err);
      Logger.error('useCart.updateItemQty', err);
    } finally {
      loading.value = false;
    }
  }

  async function clear() {
    Logger.debug('useCart.clear');
    try {
      loading.value = true;
      await apiClear({ currentCart: cart.value });
      cart.value = null;
      error.value.clear = null;
    } catch (err) {
      error.value.clear = errorToString(err);
      Logger.error('useCart.clear', err);
    } finally {
      loading.value = false;
    }
  }

  async function applyCoupon(params: { couponCode: string, customQuery?: CustomQuery }) {
    Logger.debug('useCart.applyCoupon');
    try {
      loading.value = true;
      const result = await apiApplyCoupon({ currentCart: cart.value, ...params });
      cart.value = result.updatedCart;
      error.value.applyCoupon = null;
    } catch (err) {
      error.value.applyCoupon = errorToString(err);
      Logger.error('useCart.applyCoupon', err);
    } finally {
      loading.value = false;
    }
  }

  async function removeCoupon(params: { couponCode: string, customQuery?: CustomQuery }) {
    Logger.debug('useCart.removeCoupon');
    try {
      loading.value = true;
      const result = await apiRemoveCoupon({ currentCart: cart.value, ...params });
      cart.value = result.updatedCart;
      error.value.removeCoupon = null;
    } catch (err) {
      error.value.removeCoupon = errorToString(err);
      Logger.error('useCart.removeCoupon', err);
    } finally {
      loading.value = false;
    }
  }

  async function setItemSupplyChannel(params: {
    product: ProductVariant;
    customQuery?: CustomQuery;
  }) {
    Logger.debug('useCart.setItemSupplyChannel');
    try {
      loading.value = true;
      const result = await apiSetItemSupplyChannel({ currentCart: cart.value, ...params });
      cart.value = result.updatedCart;
      error.value.setItemSupplyChannel = null;
    } catch (err) {
      error.value.setItemSupplyChannel = errorToString(err);
      Logger.error('useCart.setItemSupplyChannel', err);
    } finally {
      loading.value = false;
    }
  }

  return {
    load,
    setCart,
    addItem,
    removeItem,
    updateItemQty,
    clear,
    applyCoupon,
    removeCoupon,
    setItemSupplyChannel,

    cart: computed(() => cart.value),
    loading: computed(() => loading.value),
    error: computed(() => error.value),
  };
}
