import { useProduct } from '@vsf-enterprise/commercetools';
import { computed } from '@nuxtjs/composition-api';
import { defineModule } from '~/helpers/vuex/defineModule/defineModule';
import {
  ProductExtraGuaranteeMutations,
} from '~/types/vuex/ProductExtraGuarantee';
import { MODULE_NAMES } from '~/types/vuex/Vuex';
import { ProductVariantGql } from '~/types/product/ProductVariantGql';
import { useI18n } from '~/composables/useI18n';
import { CUSTOM_QUERIES } from '~/constants/customQueries';
import extendProducts from '~/helpers/product/extendProducts';
import { EXTRA_GUARANTEE_SKU } from '~/constants/extraGuaranteeSku';
import { getProductSearchParams } from '~/helpers/product/getProductSearchParams';
import { usePrefix } from '~/composables';

export const useProductExtraGuarantee = () => {
  const store = defineModule<typeof MODULE_NAMES.EXTRA_GUARANTEE_MODULE_NAME,
  ProductExtraGuaranteeMutations,
  {},
  {}>(MODULE_NAMES.EXTRA_GUARANTEE_MODULE_NAME);

  const productExtraGuarantee = computed(() => store.state.productExtraGuarantee);
  const { languagePrefix } = usePrefix();
  const load = async ():Promise<void> => {
    if (productExtraGuarantee.value) {
      return;
    }
    const {
      products: extraGuaranteeProducts,
      search: searchExtraGuaranteeProduct,
    } = useProduct<ProductVariantGql>('extra-guarantee-product');

    const { languageAndCountry, countryCode } = useI18n();
    const searchParams = {
      skus: [
        EXTRA_GUARANTEE_SKU.THREE_YEARS_GUARANTEE,
        EXTRA_GUARANTEE_SKU.ONE_YEAR_PREMIUM_GUARANTEE,
        EXTRA_GUARANTEE_SKU.TWO_YEARS_PREMIUM_GUARANTEE,
        EXTRA_GUARANTEE_SKU.THREE_YEARS_PREMIUM_GUARANTEE,
      ],
    };
    const productSearchParams = getProductSearchParams(
      searchParams,
      {
        languageAndCountry: languageAndCountry.value,
        languagePrefix,
        includeUnsellableProducts: true,
      },
    );
    await searchExtraGuaranteeProduct({
      ...productSearchParams,
      customQuery: CUSTOM_QUERIES.PRODUCT_SEARCH.extraGuaranteeQuery,
    });

    const products = extraGuaranteeProducts.value?.data?.products?.results || [];
    const product = extendProducts(products, languageAndCountry.value, countryCode.value, { isPublished: true })?.[0];

    store.mutations.setProduct(product);
  };

  return {
    productExtraGuarantee,
    ...store.actions,
    ...store.mutations,
    ...store.getters,
    load,
  };
};
