/* eslint-disable camelcase */
import type { PriceFilterValue } from '~/types/integrations/tagManager/TagClickFilterTypes';

export class ClickFilter {
  event: string;
  event_info: {
    filter_section: string,
    filter_name: string,
    filter_value: PriceFilterValue,
    category_path: string,
    category_name: string,
    listing_type: string
  };

  constructor({
    filterInteraction,
    filterSection,
    filterName,
    filterValue,
    categoryPath,
    categoryName,
    listingType,
  }: {
    filterInteraction: string,
    filterSection: string,
    filterName: string,
    filterValue: PriceFilterValue,
    categoryPath: string,
    categoryName: string,
    listingType: string
  }) {
    this.event = filterInteraction;
    this.event_info = {
      filter_section: filterSection,
      filter_name: filterName,
      filter_value: filterValue,
      category_path: categoryPath,
      category_name: categoryName,
      listing_type: listingType,
    };
  }
}
