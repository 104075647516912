

















import { defineComponent } from '@nuxtjs/composition-api';
import { TRUSTPILOT } from '~/constants/trustUrls';
import { getCurrentInstance } from '~/helpers/getCurrentInstance';
import { Logo } from '~/types/components/DisclaimerLogos/Logo';

export default defineComponent({
  name: 'DisclaimerLogos',
  setup() {
    const root = getCurrentInstance();
    const { $i18n } = root;

    const icons: Logo[] = [
      {
        svg: '/logos/trustpilot-logo-black.svg',
        url: TRUSTPILOT,
        alt: $i18n.t('logo-trustpilot').toString(),
      },
    ];

    return {
      icons,
    };
  },
});
