const { filterObjectByArray } = require('../../../helpers/dataStructure/objectOperations');
const { PRODUCT_ATTRIBUTES } = require('../../../constants/products.ts');

module.exports = (request, acceptLanguage) => {
  const productResults = request.data.products.results;
  productResults?.map(result => {
    const allVariants = result.masterData.current.allVariants;
    allVariants?.map(variant => {
      filterRawAttributes(variant.attributesRaw, acceptLanguage);
      return variant;
    });
    return result;
  });
  return request;
};

const filterRawAttributes = (attributesRaw, acceptLanguage) => {
  return attributesRaw?.map(attribute => {
    if (attribute.attributeDefinition.type.__typename === PRODUCT_ATTRIBUTES.LOCALISABLE_TYPE_INDICATOR &&
      attribute.name !== PRODUCT_ATTRIBUTES.ELIGIBLE_FOR_SELLING) {
      attribute.value = filterObjectByArray(attribute.value, acceptLanguage);
    }
    return attribute;
  });
};
