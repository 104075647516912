import { computed } from '@nuxtjs/composition-api';
import { useVSFContext } from '@vue-storefront/core';
import type { ProductVariant } from '~/utils/ct/types/product/ProductVariant';
import { PRODUCT_ATTRIBUTES } from '~/constants/products';
import {
  getProductAttributesExtended,
} from '~/helpers/product/getProductAttributesExtended';

/*
 * Custom composable for product technical details information
 */
export const useProductDetails = (product: ProductVariant) => {
  const { i18n } = useVSFContext();
  const productAttributes = computed(() => getProductAttributesExtended(
    product,
    {
      attributeNames: [
        PRODUCT_ATTRIBUTES.BRUTTO_LENGTH,
        PRODUCT_ATTRIBUTES.BRUTTO_WIDTH,
        PRODUCT_ATTRIBUTES.BRUTTO_HEIGHT,
        PRODUCT_ATTRIBUTES.BRUTTO_WEIGHT,
      ],
    }));
  const shippingHeight = computed(() => i18n.t('Centimeters Value', {
    value: productAttributes.value?.bruttoHeight?.toString(),
  }));
  const shippingWidth = computed(() => i18n.t('Centimeters Value', {
    value: productAttributes.value?.bruttoWidth?.toString(),
  }));
  const shippingDepth = computed(() => i18n.t('Centimeters Value', {
    value: productAttributes.value?.bruttoLength?.toString(),
  }));
  const shippingWeight = computed(() => i18n.t('Weight Value', {
    weight: productAttributes.value?.bruttoWeight,
  }));

  return {
    productAttributes,
    shippingHeight,
    shippingWidth,
    shippingDepth,
    shippingWeight,
  };
};

export default useProductDetails;
