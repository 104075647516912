import { useVSFContext } from '@vue-storefront/core';
import { getPrefix, getPrefixedSlug } from '~/helpers/routes/prefixes';

export const usePrefix = () => {
  const { route: currentRoute } = useVSFContext();

  const languagePrefix = getPrefix(currentRoute.value.path);
  const addPrefixToSlugIfNecessary = (slug: Maybe<string>): Maybe<string> => {
    return getPrefixedSlug(slug, languagePrefix);
  };

  return {
    languagePrefix,
    addPrefixToSlugIfNecessary,
  };
};
