const MAX_MOBILE_WIDTH: number = 768;
const MIN_DESKTOP_WIDTH: number = 1024;
const TRUSTPILOT_DESKTOP_WIDGET_HEIGHT: string = '24px';
const TRUSTPILOT_MOBILE_WIDGET_HEIGHT: string = '52px';
const TRUSTPILOT_MINI_WIDGET_WIDTH: string = '250px';
const TRUSTPILOT_MINI_WIDGET_HEIGHT: string = '150px';
const TRUSTPILOT_CAROUSEL_WIDGET_HEIGHT: string = '152px';
const TRUSTPILOT_MINI_CAROUSEL_WIDGET_HEIGHT: string = '350px';
const TRUSTPILOT_MICRO_COMBO_WIDGET_HEIGHT: string = '20.3px';
const TRUSTPILOT_MICRO_BUTTON_WIDGET_HEIGHT: string = '24px';
const SCROLL_BEHAVIOR_SMOOTH: ScrollBehavior = 'smooth';

export {
  MAX_MOBILE_WIDTH,
  MIN_DESKTOP_WIDTH,
  TRUSTPILOT_DESKTOP_WIDGET_HEIGHT,
  TRUSTPILOT_MOBILE_WIDGET_HEIGHT,
  TRUSTPILOT_MINI_WIDGET_WIDTH,
  TRUSTPILOT_MINI_WIDGET_HEIGHT,
  TRUSTPILOT_CAROUSEL_WIDGET_HEIGHT,
  TRUSTPILOT_MINI_CAROUSEL_WIDGET_HEIGHT,
  TRUSTPILOT_MICRO_COMBO_WIDGET_HEIGHT,
  TRUSTPILOT_MICRO_BUTTON_WIDGET_HEIGHT,
  SCROLL_BEHAVIOR_SMOOTH,
};
