const { gql } = require('graphql-tag');

const DefaultCartDiscountValue = require('../../fragments/cartDiscount/defaultCartDiscountValue').fragment;

module.exports = {
  fragment: gql`
    ${DefaultCartDiscountValue}

    fragment DefaultDiscountCodeInfo on DiscountCodeInfo {
      discountCode {
        id
        code
        cartDiscounts {
          value {
            ...DefaultCartDiscountValue
          }
        }
      }
    }
  `,
};
