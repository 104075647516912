import type { Cart } from '@vsf-enterprise/commercetools-types';
import { cartGetters } from '@vsf-enterprise/commercetools';
import type { ProductVariant } from '~/utils/ct/types/product/ProductVariant';
import { productGetters } from '~/utils/ct';
import { BeginCheckoutTags } from '~/plugins/integrations/tagManager/helpers/BeginCheckoutTags';
import { prepareProductTags } from '~/plugins/integrations/tagManager/helpers/transformers';
import { Gtm } from '~/types/@nuxtjs/gtm';
import { getTotalTaxPrice } from '~/helpers/cart/getTotalPrices';
import { getTotalGrossPrice } from '~/helpers/cart/getTotalPricesModule';

export const triggerBeginCheckoutTagsWrapper = ($gtm: Gtm, languageAndCountry: string, currency: string) => {
  return (
    cart: Cart,
    products: ProductVariant[],
  ) => {
    const beginCheckoutTags = new BeginCheckoutTags();
    if (products?.length) {
      const productTags = products.map(product => {
        return prepareProductTags(
          product,
          {
            currency,
            quantity: cart.lineItems.find(item =>
              cartGetters.getItemSku(item) === productGetters.getSku(product))?.quantity,
            languageAndCountry,
          });
      });
      beginCheckoutTags.assignProductValues(productTags);
    }

    beginCheckoutTags.assignCartInformation({
      value: getTotalGrossPrice(cart),
      tax: getTotalTaxPrice(cart),
      currency,
    });

    $gtm.push({ ecommerce: null });
    $gtm.push(beginCheckoutTags);
  };
};
