import { Logger, useVSFContext } from '@vue-storefront/core';
import type { Ref } from '@nuxtjs/composition-api';
import { ssrRef } from '@nuxtjs/composition-api';
import { useI18n } from '../useI18n';
import { INTERNAL_CMS_COMPONENTS_PATH, INTERNAL_CMS_PAGES_PATH } from '~/constants/cms';

export const useInternalCms = (slug: string) => {
  if (!slug) {
    Logger.error('useInternalCms is incorrectly used');
    return;
  }
  const { languageAndCountry, domain } = useI18n();
  const { $axios } = useVSFContext();
  const internalCmsContent: Ref<Record<string, any>[]> = ssrRef([], `${slug}-internalCmsContent`);
  const canShowContent = ssrRef(false, `${slug}-canShowContent`);
  const loadingContent = ssrRef(false, `${slug}-loadingContent`);

  const loadCmsContent = async (filePath: string) => {
    loadingContent.value = true;
    let fileContent: { content?: Record<string, any>} = {};
    try {
      const fullUrlForClientSide = domain.value + filePath;
      const fullPath = process?.server ? filePath : fullUrlForClientSide;
      fileContent = await $axios.$get(fullPath);
    } catch (error) {
      Logger.info('invalid internalCms key seems to be used');
    }
    if (fileContent?.content) {
      internalCmsContent.value = [fileContent.content];
      canShowContent.value = true;
    }
    loadingContent.value = false;
  };

  const loadComponent = async () => {
    const filePath = `${INTERNAL_CMS_COMPONENTS_PATH}${slug}-${languageAndCountry.value}.json`;
    await loadCmsContent(filePath);
  };

  const loadPage = async () => {
    const filePath = `${INTERNAL_CMS_PAGES_PATH}${slug}-${languageAndCountry.value}.json`;
    await loadCmsContent(filePath);
  };

  return {
    loadComponent,
    loadPage,
    internalCmsContent,
    canShowContent,
    loadingContent,
  };
};
