import { renderToString } from '~/helpers/algolia/algoliaSSR/algoliaSSR';
import { ALGOLIA_GLOBAL_STATE_NAME, AlgoliaGlobalStateName } from '~/constants/algolia';
import { i18nToAlgoliaMainIndex } from '~/helpers/locales/i18nCurrentDetails';
import { STATUS_RESOURCE_NOT_FOUND } from '~/constants/http';

export const algoliaSSRRequiredFields = (
  globalStateName: AlgoliaGlobalStateName = ALGOLIA_GLOBAL_STATE_NAME.ALGOLIA_STATE,
): any => ({
  data() {
    return {
      shouldSetNoFollow: false,
    };
  },
  serverPrefetch() {
    return this.instantsearch
      .findResultsState({
        component: this,
        renderToString,
      }).then((algoliaState: unknown) => {
        this.$ssrContext.nuxt[globalStateName] = algoliaState;
        if (globalStateName === ALGOLIA_GLOBAL_STATE_NAME.ALGOLIA_STATE) {
          checkAlgoliaValidPageInPagination({ algoliaState, indexName: i18nToAlgoliaMainIndex(this.$i18n), vm: this });
        }
      });
  },
  provide() {
    return {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      $_ais_ssrInstantSearchInstance: this.instantsearch,
    };
  },
  beforeMount() {
    const results =
      (this.$nuxt.context && this.$nuxt.context.nuxtState[globalStateName]) ||
      window.__NUXT__[globalStateName];

    this.instantsearch.hydrate(results);

    // Remove the SSR state so it can't be applied again by mistake
    delete this.$nuxt.context.nuxtState[globalStateName];
    delete window.__NUXT__[globalStateName];
  },
  head() {
    if (this.shouldSetNoFollow) {
      return {
        meta: [
          {
            hid: 'robots',
            name: 'robots',
            content: 'noindex, nofollow',
          },
        ],
      };
    }

    return {};
  },
});

export const checkAlgoliaValidPageInPagination = ({ algoliaState, indexName, vm }) => {
  const firstResult = algoliaState[indexName]?.results?.[0];
  if (firstResult?.page + 1 > firstResult?.nbPages) {
    if (process?.server) {
      vm.$ssrContext.res.statusCode = STATUS_RESOURCE_NOT_FOUND;
      vm.shouldSetNoFollow = true;
    }
  }
};
