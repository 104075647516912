import { computed } from '@nuxtjs/composition-api';
import { useVSFContext } from '@vue-storefront/core';
import { useUserOrder } from '~/composables';
import { dateToDDMMYYYY } from '~/helpers/time/dateToDDMMYYYY/dateToDDMMYYYY';

export default function () {
  const { i18n } = useVSFContext();
  const { orderPayment } = useUserOrder();

  const orderPaymentMultibanco = computed(() => {
    try {
      const parsedValue = JSON.parse(orderPayment?.value?.value);
      return parsedValue.action || [];
    } catch (error) {
      return [];
    }
  });

  const multibancoData = computed(() => {
    if (orderPaymentMultibanco.value !== null && !Array.isArray(orderPaymentMultibanco.value)) {
      const { reference, entity, expiresAt, merchantReference } = orderPaymentMultibanco.value;
      return [
        { label: i18n.t('Payment reference'), value: reference },
        { label: i18n.t('Entity'), value: entity },
        { label: i18n.t('Expiration date'), value: dateToDDMMYYYY(expiresAt) },
        { label: i18n.t('Consumer reference'), value: merchantReference },
      ];
    } else {
      return [];
    }
  });

  return {
    multibancoData,
  };
}
