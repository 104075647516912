const { gql } = require('graphql-tag');

const DefaultProductPrice = require('./defaultProductPrice').fragment;
const DefaultCartDiscountValue = require('./cartDiscount/defaultCartDiscountValue').fragment;

/* Added discounts information */
module.exports = {
  fragment: gql`
    ${DefaultProductPrice}
    ${DefaultCartDiscountValue}

    fragment DefaultLineItem on LineItem {
      id
      productId
      name(acceptLanguage: $acceptLanguage)
      productSlug(acceptLanguage: $acceptLanguage)
      productType {
        key
      }
      quantity
      discountedPricePerQuantity {
        quantity
        discountedPrice {
          value {
            centAmount
            type
          }
          includedDiscounts {
            discount {
              id
              key
              requiresDiscountCode
              name(acceptLanguage: $acceptLanguage)

              value {
                ...DefaultCartDiscountValue
              }
            }
            discountedAmount {
              centAmount
            }
          }
        }
      }
      custom {
        customFieldsRaw {
          name
          value
        }
      }
      variant {
        id
        sku
        prices {
          id
          value {
            type
            currencyCode
            centAmount
            fractionDigits
          }
          custom {
            typeRef {
              id
            }
            customFieldsRaw {
              name
              value
            }
          }
        }
        price(currency: $currency) {
          tiers {
            value {
              centAmount
            }
          }
          value {
            centAmount
          }
          discounted {
            value {
              centAmount
            }
            discount {
              isActive
              name(acceptLanguage: $acceptLanguage)
            }
          }
        }
        images {
          url
          label
        }
        attributesRaw {
          name
          value
          attributeDefinition {
            type {
              name
            }
            label(locale: $locale)
          }
        }
      }
      price {
        ...DefaultProductPrice
      }
      priceMode
      taxedPrice {
        totalGross {
          centAmount
          currencyCode
        }
      }
    }
  `,
};
