import { EVENT_PRODUCT_BOX_CLICK, EVENT_PRODUCT_BOX_CLICK_DETAIL } from '~/constants/googleTagManager';
import { TagProductBox } from '~/types/integrations/tagManager/TagProductBox';

/* eslint-disable camelcase */
export class ClickProductBoxTags {
  event = EVENT_PRODUCT_BOX_CLICK;
  event_info: {
    detail: string,
    type: string
  };

  ecommerce: { items: TagProductBox[] }
  constructor(boxType: string, product: TagProductBox) {
    this.event_info = {
      detail: EVENT_PRODUCT_BOX_CLICK_DETAIL,
      type: boxType,
    };
    this.ecommerce = { items: [product] };
  }
}
