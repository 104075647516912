import type { Cart } from '@vsf-enterprise/commercetools-types';
import { maxBankAndCashPaymentItems } from '../../../config/shared';
import { EXTRA_GUARANTEE } from '../../../constants/productType';

export const isEligibleForCashAndBank = (cart: Cart | null): boolean => {
  if (!cart?.lineItems?.length) return false;

  const totalQuantity = cart.lineItems
    .filter(item => item?.productType?.key !== EXTRA_GUARANTEE)
    .reduce((sum, item) => sum + (item.quantity || 0), 0);

  return totalQuantity > 0 && totalQuantity <= maxBankAndCashPaymentItems;
};
