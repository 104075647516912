import {
  useIntegrations,
  usePageEventLocation,
  useUserExtended,
} from '~/composables';
import { PageViewPageCategory, TriggerPageViewParameters } from '~/types/integrations/tagManager/TagManagerIntegration';
import { PAGE_VIEV_ENVIRONMENT } from '~/constants/env';
import { isProduction } from '~/helpers/env/checkEnvironmentType';

type CallPageViewEventParams = {
  pageCategory?: PageViewPageCategory
}

type UsePageViewEventReturn = {
  callPageViewEvent: (parameters?: CallPageViewEventParams) => void
}

export default function (): UsePageViewEventReturn {
  const { $tagManager } = useIntegrations();
  const { getCurrentPageType } = usePageEventLocation();
  const { user } = useUserExtended();
  const getPageViewEventData = (): TriggerPageViewParameters => {
    const sessionIdCookieString = document.cookie.match(/_ga_(.+?)=(.+?);/)?.[0]?.split('.')?.[2];
    const sessionId = parseInt(sessionIdCookieString || '');
    const { PROD, DEV } = PAGE_VIEV_ENVIRONMENT;
    const environmentType = isProduction() ? PROD : DEV;
    const pageType = getCurrentPageType();
    const pageViewEventData: TriggerPageViewParameters = {
      page: {
        info: {
          name: document.title,
          url: window.location.href,
          path: window.location.pathname,
          referrer: document.referrer,
          environment: environmentType,
          hostname: window.location.hostname,
          page_category: pageType,
        },
      },
      user: {
        profile: {
          user_id: parseInt(user?.value?.id) || undefined,
          crm_status: 0, // newsletter status of given email (it's 0 because we don't have a way to check it yet),
          session_id: sessionId || undefined, // GA Session ID identifies the session that an event came from (Integer)
        },
      },
    };
    return pageViewEventData;
  };

  const callPageViewEvent = (parameters?: CallPageViewEventParams) => {
    const pageViewEventData = getPageViewEventData();
    if (parameters?.pageCategory) {
      pageViewEventData.page.info.page_category = parameters.pageCategory;
    }
    $tagManager.events.triggerPageView(pageViewEventData);
  };

  return { callPageViewEvent };
}
