import { useVSFContext } from '@vue-storefront/core';
import { computed } from '@nuxtjs/composition-api';
import { UseMyAccountPage } from '~/types/myaccount/UseMyAccountPage';
import { useRouteExtended } from '~/composables';
import { ROUTES } from '~/constants/routes';
import { getNameForRoute } from '~/helpers/routes/getPageInformation';

export const useAccountInternalPage: UseMyAccountPage =
  (route = ROUTES.MY_PROFILE, translationKey: string|undefined) => {
    const { getAdjustedSlug } = useRouteExtended();
    const { i18n, app: { router } } = useVSFContext();

    const isInternal = true;
    const label = i18n.t(translationKey);
    const stringRoute = computed(() => {
      return typeof route === 'object' ? route.value : route;
    });
    const goToThatPage = (): void => {
      router.push(getAdjustedSlug(stringRoute.value));
    };

    const key = computed(() => {
      return getNameForRoute(stringRoute.value);
    });
    return {
      label,
      goToThatPage,
      isInternal,
      key,
    };
  };
