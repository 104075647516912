import { computed } from '@nuxtjs/composition-api';
import { defineModule } from '~/helpers/vuex/defineModule/defineModule';
import {
  NavigationMutations,
} from '~/types/vuex/Navigation';
import { MODULE_NAMES } from '~/types/vuex/Vuex';

export default function () {
  const store = defineModule<
    typeof MODULE_NAMES.NAVIGATION_MODULE_NAME,
    NavigationMutations,
    {},
    {}
  >(MODULE_NAMES.NAVIGATION_MODULE_NAME);

  const isNavigationOpen = computed(() => store.state.isNavigationOpen);
  const currentFirstLevelCategorySlug = computed(() => store.state.currentFirstLevelCategorySlug);
  return {
    isNavigationOpen,
    currentFirstLevelCategorySlug,
    ...store.getters,
    ...store.mutations,
    ...store.actions,
  };
}
