
















import { defineComponent, computed } from '@nuxtjs/composition-api';
import VueSkeletonLoader from 'skeleton-loader-vue';
import LazyHydrate from 'vue-lazy-hydration';

export default defineComponent({
  name: 'SkeletonLoader',
  components: { VueSkeletonLoader, LazyHydrate },
  props: {
    numberOfItems: {
      type: Number,
      default: 1,
    },
    itemClasses: {
      type: String,
      default: '',
    },
    width: {
      type: [Number, String],
      default: '100%',
    },
    height: {
      type: [Number, String],
      required: true,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    lazy: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const componentTag = computed(() => props.lazy ? 'LazyHydrate' : 'div');
    return {
      componentTag,
    };
  },
});
