import type { Category, CategorySearch } from '@vsf-enterprise/commercetools-types';

export default function getBreadcrumbsList(
  categoriesTree: Maybe<Category[] | CategorySearch[]> = [],
  subCategoryToFind: string = '',
): string[] | undefined {
  if (!categoriesTree?.length) {
    return undefined;
  }

  let categoryPath: string[] | undefined;

  categoriesTree?.some((subCategory: Category | CategorySearch) => {
    if (subCategory.slug === subCategoryToFind) {
      categoryPath = [subCategory.name];

      return true;
    }

    const result = getBreadcrumbsList(subCategory.children, subCategoryToFind);

    if (result) {
      result.unshift(subCategory.name);
      categoryPath = result;

      return true;
    }

    return false;
  });
  return categoryPath;
}
