const localizationDefinitions = require('../../../../i18n/localizationDefinitions.ts').default;

const getDefaultVariables = (context, options = {}) => {
  const { locale, country } = context.config;
  const { params = {}, additionalVars = {} } = options;

  const acceptLanguage = localizationDefinitions.getByCountryCode(country)
    .getAcceptedLanguages(params?.languagePrefix);

  return {
    acceptLanguage,
    locale,
    version: params?.shipping?.version,
    ...additionalVars,
  };
};

module.exports = getDefaultVariables;
