const { gql } = require('graphql-tag');

module.exports = {
  fragment: gql`
    fragment ReviewStatistics on Product {
      reviewRatingStatistics {
        averageRating,
        ratingsDistribution,
        count
      }
    }
  `,
};
