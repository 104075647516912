import { ClickProductBoxTags } from '~/plugins/integrations/tagManager/helpers/ClickProductBoxTags';
import { Gtm } from '~/types/@nuxtjs/gtm';
import { TriggerClickProducBoxParameters } from '~/types/integrations/tagManager/TagManagerIntegration';
import { prepareClickProductBoxTags } from '~/plugins/integrations/tagManager/helpers/transformers';

export const triggerClickProductBoxTagsWrapper = ($gtm: Gtm) => {
  return (parameters: TriggerClickProducBoxParameters) => {
    const clickProductBoxTag = new ClickProductBoxTags(
      parameters.boxType,
      prepareClickProductBoxTags(parameters.product),
    );
    $gtm.push({ ecommerce: null });
    $gtm.push(clickProductBoxTag);
  };
};
