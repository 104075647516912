import type { ProductVariant } from '~/utils/ct/types/product/ProductVariant';
import { Gtm } from '~/types/@nuxtjs/gtm';
import { ViewProductTags } from '~/plugins/integrations/tagManager/helpers/ViewProductTags';
import { prepareProductTags } from '~/plugins/integrations/tagManager/helpers/transformers';
import { EVENT_PRODUCT_DETAIL } from '~/constants/googleTagManager';

export const triggerViewProductTagsWrapper = ($gtm: Gtm, languageAndCountry: string, currency: string) => {
  return ({ product, inStock, eventName = EVENT_PRODUCT_DETAIL }:
    {product: ProductVariant, inStock: boolean, eventName?: string}) => {
    const viewProductTags = new ViewProductTags(eventName);
    const productTags = prepareProductTags(
      product,
      {
        currency,
        languageAndCountry,
        quantity: 1,
        inStock,
      },
    );

    viewProductTags.assignProductValues(productTags);
    viewProductTags.assignActionField(productTags.item_category2);
    $gtm.push(viewProductTags);
  };
};
