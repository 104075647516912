import { ROUTES } from '~/constants/routes';
import { getSlugForRoute } from '~/helpers/routes/getPageInformation';
import { getCt } from '~/helpers/getCt';

const canEnterPayment = cart => cart.shippingInfo && cart.shippingAddress;
export default async ({ app }) => {
  const ct = getCt(app.context, { vsfCt: true });
  const { data } = await ct.api.getMe();

  if (!data || !data.me.activeCart) return;
  const { activeCart } = data.me;
  if (!canEnterPayment(activeCart)) {
    app.context.redirect(getSlugForRoute(ROUTES.CHECKOUT_ADDRESSES));
  }
};
