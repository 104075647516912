import { NuxtAxiosInstance } from '@nuxtjs/axios';
import { MyOrdersIntegration } from '~/types/integrations/myOrders/MyOrdersIntegration';
import getUrlWithToken from '~/plugins/integrations/myOrders/request/getUrlWithToken';
import { getAxiosConfig } from '~/helpers/axios/getAxiosConfig';
import { setAxiosInterceptors } from '~/helpers/axios/setAxiosInterceptors';

export default (
  $axios: NuxtAxiosInstance,
) => {
  const axios = $axios.create(
    getAxiosConfig((process?.server ? $axios.defaults.baseURL : '/') + 'my-orders'),
  );

  setAxiosInterceptors({ axios, shouldRejectPromise: true });

  const $myOrders = {
    services: {
      axios,
    },
  } as MyOrdersIntegration;
  $myOrders.getUrlWithToken = getUrlWithToken($myOrders);
  return $myOrders;
};
