























import {
  defineComponent,
  onMounted,
  ref,
} from '@nuxtjs/composition-api';
import { useResizeObserver } from '@vueuse/core';
import ScrollIndicator from '~/components/atoms/ScrollIndicator/ScrollIndicator.vue';

export default defineComponent({
  name: 'SliderWithIndicator',
  components: {
    ScrollIndicator,
  },
  setup() {
    const scrollContainer = ref<HTMLDivElement | null>(null);

    const isElement = (target: EventTarget | null): target is Element => target instanceof Element;

    const handlePointerCapture = (event: PointerEvent, capture: boolean) => {
      if (!isElement(event.target)) {
        return;
      }
      if (capture) {
        event.target.setPointerCapture(event.pointerId);
      } else {
        event.target.releasePointerCapture(event.pointerId);
      }
    };

    const dragStart = (event: PointerEvent) => handlePointerCapture(event, true);
    const dragEnd = (event: PointerEvent) => handlePointerCapture(event, false);

    const dragMove = (event: PointerEvent) => {
      if (event.pointerType !== 'mouse' || !isElement(event.target)) {
        return;
      }
      if (event.target.hasPointerCapture(event.pointerId)) {
        scrollContainer.value.scrollLeft -= event.movementX;
      }
    };

    const scrollPosition = ref(0);
    const scrollTotalWidth = ref(0);
    const scrollViewportWidth = ref(0);

    const onScroll = () => {
      scrollPosition.value = scrollContainer.value?.scrollLeft || 0;
      scrollTotalWidth.value = scrollContainer.value?.scrollWidth || 0;
      scrollViewportWidth.value = scrollContainer.value?.clientWidth || 0;
    };

    onMounted(() => {
      onScroll();
    });

    useResizeObserver(scrollContainer, onScroll);

    return {
      scrollContainer,
      scrollPosition,
      scrollTotalWidth,
      scrollViewportWidth,
      onScroll,
      dragStart,
      dragEnd,
      dragMove,
    };
  },
});
