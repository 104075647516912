import { useVSFContext } from '@vue-storefront/core';
import { AxiosError } from 'axios';
import { getExpondoMiddlewareUrl } from '~/helpers/env/getMiddlewareUrl';
import { errorToString } from '~/helpers/error/errorToString';
import { AllowedMethod } from '~/types/expondoMiddleware';

export const useExpondoMiddleware = () => {
  const { $axios, $config } = useVSFContext();

  const makeMiddlewareRequest = async <T> ({
    endpoint,
    method,
    data,
    params,
    catchCallback,
  }: {
    endpoint: string;
    method: AllowedMethod;
    data?: unknown;
    params?: Record<string, unknown>;
    catchCallback?: (error: AxiosError) => void;
  }): Promise<T | null> => {
    try {
      const currentMiddlewareURL = getExpondoMiddlewareUrl({ $config });
      const response = await $axios({
        method,
        url: `${currentMiddlewareURL}${endpoint}`,
        data,
        params,
        baseURL: typeof window !== 'undefined' ? window.location.origin : '',
      });
      return response?.data;
    } catch (error: unknown) {
      if (catchCallback) {
        catchCallback(error as AxiosError);
        return null;
      } else {
        throw new Error(`An error occurred during request to ${endpoint}, ${errorToString(error)}`);
      }
    }
  };

  return {
    makeMiddlewareRequest,
  };
};
