import { ViewItemListTags } from '~/plugins/integrations/tagManager/helpers/ViewItemListTags';
import { Gtm } from '~/types/@nuxtjs/gtm';
import { TriggerViewItemListParameters } from '~/types/integrations/tagManager/TagManagerIntegration';
import { prepareViewItemListTags } from '~/plugins/integrations/tagManager/helpers/transformers';

export const triggerViewItemListWrapper = ($gtm: Gtm, languageAndCountry: string) => {
  return (parameters: TriggerViewItemListParameters) => {
    const viewItemListTag = new ViewItemListTags({
      id: parameters.id,
      name: parameters.name,
      itemsArray: prepareViewItemListTags(parameters.items, languageAndCountry),
    });
    $gtm.push(viewItemListTag);
  };
};
