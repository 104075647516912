const BLOOMREACH_COOKIE: string = '__exponea_etc__'; // the same key as in old shop

const FAIL_RESPONSE: { [key: string]: boolean } = { success: false };

const MIDDLEWARE_TIMEOUT: number = 15000; // milliseconds

const JS_SDK_CONFIG = {
  track: {
    visits: true,
    default_properties: {
      from_new_shop: true,
    },
  },
  debug: false,
};

const CHECKOUT_STEPS: Record<string, number> = {
  cart: 1,
  shipping: 2,
  billing: 3,
  payment: 4,
  thankYou: 5,
};

export {
  BLOOMREACH_COOKIE,
  FAIL_RESPONSE,
  MIDDLEWARE_TIMEOUT,
  JS_SDK_CONFIG,
  CHECKOUT_STEPS,
};
