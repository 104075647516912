type Store<T extends { S, M?, A?, G? }> = {
  state: () => T['S'];
  mutations?: T['M'];
  actions?: T['A'];
  getters?: T['G'];
};

export const extendModule = <T extends { S, M?, A?, G? }>(store: Store<T>) => {
  const createState = () => {
    return {
      ...store.state(),
      getterKeys: store.getters ? Object.keys(store.getters) : [],
      mutationKeys: store.mutations ? Object.keys(store.mutations) : [],
      actionKeys: store.actions ? Object.keys(store.actions) : [],
    };
  };

  return {
    ...store,
    state: createState,
  };
};
