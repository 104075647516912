import type { ProductVariant } from '~/utils/ct/types/product/ProductVariant';
import { PRODUCT_ATTRIBUTES } from '~/constants/products';
import {
  getProductAttributesExtended,
} from '~/helpers/product/getProductAttributesExtended';
import { isBWareProduct } from '~/helpers/product/productCondition';

export const getProductComparisonFilter = (product: ProductVariant): string | undefined => {
  const sku = product.sku;
  const isBware = isBWareProduct(sku);
  const family = getProductAttributesExtended(
    product,
    {
      attributeNames: [PRODUCT_ATTRIBUTES.FAMILY],
    })[PRODUCT_ATTRIBUTES.FAMILY];
  if (!sku || !family) {
    return;
  }

  return ` and masterData(current(masterVariant(
    attributes((name="family" and value="${family}")) and
    attributes((name="isBware" and value=${isBware})) and
    sku != "${sku}"
  )))`;
};
