import { ssrRef } from '@nuxtjs/composition-api';
import { useVSFContext } from '@vue-storefront/core';
import type { ProductVariant } from '~/utils/ct/types/product/ProductVariant';
import { useI18n, usePrefix, useProductDetailsCombined } from '~/composables';
import extendProducts from '~/helpers/product/extendProducts';
import { getProductSearchParams } from '~/helpers/product/getProductSearchParams';
import { COMPARISON_PRODUCTS_FETCH_LIMIT } from '~/constants/comparisonProducts';
import { getProductComparisonFilter } from '~/helpers/commercetools/graphql/getProductComparisonFilter';
import type { ComparisonProductData } from '~/types/product/ComparisonProducts';
import {
  getProductAttributesExtended,
} from '~/helpers/product/getProductAttributesExtended';
import { PRODUCT_ATTRIBUTES } from '~/constants/products';

export default function (cacheId: string) {
  const { $ct } = useVSFContext();
  const { languagePrefix } = usePrefix();
  const { languageAndCountry, countryCode } = useI18n();
  const { combineTechnicalDetails } = useProductDetailsCombined();

  const productsData = ssrRef<ComparisonProductData[]>([], `${cacheId}-comparisonProducts`);

  const search = async (product: ProductVariant) => {
    productsData.value = [];
    const comparisonFiler = getProductComparisonFilter(product);
    if (!comparisonFiler) {
      return;
    }

    const productSearchParams = getProductSearchParams(
      {
        limit: COMPARISON_PRODUCTS_FETCH_LIMIT,
        customFilters: comparisonFiler,
      },
      { languageAndCountry: languageAndCountry.value, languagePrefix },
    );

    const products = await $ct.api.getComparisonProducts(productSearchParams);

    const extendedProducts = extendProducts(
      products, languageAndCountry.value, countryCode.value, { isPublished: true },
    );
    const data = extendedProducts.map(product => {
      const attributesLocalized = getProductAttributesExtended(
        product,
        {
          attributeNames: [PRODUCT_ATTRIBUTES.TECH_DETAILS],
          languageAndCountry: languageAndCountry.value,
        },
      );
      combineTechnicalDetails(product, attributesLocalized);
      return { product, attributesLocalized };
    });
    productsData.value = data;
  };

  return {
    productsData,
    search,
  };
}
