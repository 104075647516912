const { gql } = require('graphql-tag');

module.exports = {
  fragment: gql`
    fragment ShippingDeliveryInformation on ShippingMethod {
      key
      zoneRates {
        zone {
          id
          name
          key
        }
        shippingRates {
          freeAbove {
            type
            centAmount
            currencyCode
          }
          isMatching
          price {
            centAmount
            currencyCode
          }
        }
      }
      custom {
        customFieldsRaw(includeNames:["DeliveryTime"]) {
          name
          value
        }
      }
    }
  `,
};
