const { updateCart } = require('./updateCart');
const { createAddLineItemAction } = require('#~/helpers/cart/cartActions/createAddLineItemAction.js');
/**
 * Endpoint for adding product to the provided cart. By default, it uses
 * the {@link updateCartDefaultQuery} GraphQL query
 *
 * @param context - Automatically injected context. Refer to {@link SetupContext}
 * @param cart - Cart object
 * @param params - Product, quantity and channel information
 * @param customQuery - Custom queries included in the request
 * @returns User cart information
 */
module.exports.addToCart = (context, cart, params, customQuery, responseOptions) => {
  return updateCart(context, {
    ...cart,
    actions: [createAddLineItemAction(params)],
  }, customQuery, responseOptions);
};
