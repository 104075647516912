import { useVSFContext } from '@vue-storefront/core';
import { buildHumanReadableCampaignName } from '~/helpers/store/campaign';

export const useCampaignTag = () => {
  const { i18n } = useVSFContext();

  const checkCampaignNameTranslationExists = (campaignName: string): boolean => {
    return i18n.t(campaignName) !== campaignName && i18n.t(campaignName).toString().trim() !== '';
  };

  const getCampaignLabel = (campaignTag: string) => {
    if (checkCampaignNameTranslationExists(campaignTag)) {
      return i18n.t(campaignTag);
    }
    return buildHumanReadableCampaignName(campaignTag);
  };

  return {
    checkCampaignNameTranslationExists,
    getCampaignLabel,
  };
};
