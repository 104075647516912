import { computed } from '@nuxtjs/composition-api';
import type { Ref } from '@nuxtjs/composition-api';
import { sharedRef } from '@vue-storefront/core';
import { useI18n, usePrefix, useProductDetailsCombined } from '~/composables';
import extendProducts from '~/helpers/product/extendProducts';
import type { ProductsSearchParams } from '~/utils/ct';
import type { ProductProcessedAttributes } from '~/types/product/attribute/ProductProcessedAttributes';
import type { ProductVariantExtended } from '~/types/product/ProductVariantExtended';
import type { ProductVariantGql } from '~/types/product/ProductVariantGql';
import {
  getProductAttributesExtended,
} from '~/helpers/product/getProductAttributesExtended';
import { getProductSearchParams } from '~/helpers/product/getProductSearchParams';
import { CUSTOM_QUERIES } from '~/constants/customQueries';
import { PRODUCT_ATTRIBUTES } from '~/constants/products';
import { useProduct, productGetters } from '~/utils/ct';
import { getEnergyRating } from '~/helpers/product/energyRating';

export default function (cacheId: string) {
  const {
    products: oldProducts,
    search: oldSearch,
    loading,
  } = useProduct<ProductVariantGql>(cacheId);
  const { languagePrefix } = usePrefix();
  const { combineTechnicalDetails } = useProductDetailsCombined();

  const products = computed<ProductVariantGql[]>(() =>
    oldProducts.value?.data?.products?.results || [],
  );

  const masterProduct: Ref<ProductVariantExtended | undefined> =
    sharedRef(undefined, `master-product-${cacheId}`);
  const masterProductLocalisedAttributes: Ref<ProductProcessedAttributes | undefined> =
    sharedRef(undefined, `master-product-localised-${cacheId}`);

  const loaded: Ref<boolean> = sharedRef(`loaded-${cacheId}`, false);

  const { languageAndCountry, countryCode } = useI18n();
  const publishedProducts = computed(() => {
    return extendProducts(
      products.value, languageAndCountry.value, countryCode.value, { isPublished: true });
  });

  const search = async (searchParams: ProductsSearchParams) => {
    const productSearchParams = getProductSearchParams(
      searchParams,
      { languageAndCountry: languageAndCountry.value, languagePrefix },
    );
    await oldSearch({
      ...productSearchParams,
      customQuery: CUSTOM_QUERIES.PRODUCT_SEARCH.mainProductQuery,
    });
    setMasterProduct();
    setMasterProductLocalisedAttributes();
    loaded.value = true;
  };

  const setMasterProduct = () => {
    masterProduct.value = productGetters.getFiltered(
      publishedProducts.value,
      { master: true },
    )[0];
  };

  const setMasterProductLocalisedAttributes = () => {
    if (masterProduct.value) {
      const localisedAttributes = getProductAttributesExtended(
        masterProduct.value,
        {
          attributeNames: [
            PRODUCT_ATTRIBUTES.BRAND,
            PRODUCT_ATTRIBUTES.FAQ,
            PRODUCT_ATTRIBUTES.HIGHLIGHTS,
            PRODUCT_ATTRIBUTES.TECH_DETAILS,
            PRODUCT_ATTRIBUTES.TAGS,
            PRODUCT_ATTRIBUTES.DELIVERY_PACKAGE,
            PRODUCT_ATTRIBUTES.IS_PACKAGE_TYPE_FREIGHT,
          ],
          languageAndCountry: languageAndCountry.value,
        },
      );

      localisedAttributes.energyRating = getEnergyRating({
        product: masterProduct.value,
        languageAndCountry: languageAndCountry.value,
      });
      combineTechnicalDetails(masterProduct.value, localisedAttributes);
      masterProductLocalisedAttributes.value = localisedAttributes;
    }
  };

  return {
    products: publishedProducts,
    search,
    loading,
    masterProductLocalisedAttributes,
    masterProduct,
    loaded,
  };
}
