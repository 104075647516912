import { ClearSingleFiltersParameter } from '~/plugins/integrations/tagManager/helpers/ClearSingleFiltersParameter';
import { Gtm } from '~/types/@nuxtjs/gtm';
import { TriggerClearSingleFiltersParameterWrapper } from '~/types/integrations/tagManager/TagManagerIntegration';

export const triggerClearSingleFiltersParameterWrapper = ($gtm: Gtm) => {
  return (parameters: TriggerClearSingleFiltersParameterWrapper) => {
    const clearSingleFiltersParameter = new ClearSingleFiltersParameter({
      filterName: parameters.filterName,
      filterValue: parameters.filterValue,
      categoryPath: parameters.categoryPath,
      categoryName: parameters.categoryName,
      listingType: parameters.listingType,
    });
    $gtm.push(clearSingleFiltersParameter);
  };
};
