import { useVSFContext } from '@vue-storefront/core';
import {
  usePageEventLocation,
  useCategories,
  useIntegrations,
} from '~/composables';
import { EVENT_SOURCE } from '~/constants/googleTagManager';
import { TriggerClickFilterParameters } from '~/types/integrations/tagManager/TagManagerIntegration';
import type { PriceFilterValue } from '~/types/integrations/tagManager/TagClickFilterTypes';

interface FilterClickParameters {
  filterInteraction: string,
  filterSection: string,
  filterName: string,
  filterValue: PriceFilterValue
}

export default function () {
  const { $tagManager } = useIntegrations();
  const { getCurrentPageType } = usePageEventLocation();
  const {
    getters: {
      currentCategoryName,
    },
  } = useCategories();
  const { route } = useVSFContext();
  const getClickFilterEventData = (eventParameters: FilterClickParameters) => {
    const pageType = getCurrentPageType();
    const categoryName = pageType === EVENT_SOURCE.CATEGORY_LISTING ? currentCategoryName.value || '' : '';
    const categoryPath = pageType === EVENT_SOURCE.CATEGORY_LISTING ? route.value.params.slug || '' : '';

    const clickFilterEventData: TriggerClickFilterParameters = {
      filterInteraction: eventParameters.filterInteraction,
      filterSection: eventParameters.filterSection,
      filterName: eventParameters.filterName,
      filterValue: eventParameters.filterValue,
      categoryPath,
      categoryName,
      listingType: pageType,
    };
    return clickFilterEventData;
  };

  const callClickFilterEvent = (eventParameters: FilterClickParameters) => {
    const clickFilterEventData = getClickFilterEventData(eventParameters);
    $tagManager.events.triggerClickFilter(clickFilterEventData);
  };

  return { callClickFilterEvent };
}
