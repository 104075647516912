import { sharedRef } from '@vue-storefront/core';
import { AddressType } from '~/types/AddressType';
import { VatNumber } from '~/helpers/cart/customFields';
import { useStoreVsfExtended, useVatValidation } from '~/composables';

export default (addressType: AddressType) => {
  const { isChangeTaxRateEnabled } = useStoreVsfExtended();
  const {
    clear: clearVatValidation,
  } = useVatValidation(isChangeTaxRateEnabled);

  const vatNumber = sharedRef<VatNumber>('', `${addressType}VatNumber`);
  const setVatNumber = async (value: VatNumber) => {
    if (vatNumber.value === value) return;
    vatNumber.value = value;
    if (!value) {
      await clearVatValidation();
    }
  };

  return {
    vatNumber,
    setVatNumber,
  };
};
