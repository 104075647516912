
























import {
  computed,
  defineComponent,
} from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import type { Category } from '@vsf-enterprise/commercetools-types';
import NavMainBarOnSale from '~/components/molecules/Navigation/NavMainBar/NavMainBarOnSale/NavMainBarOnSale.vue';
import NavMainBarCategory from '~/components/molecules/Navigation/NavMainBar/NavMainBarCategory/NavMainBarCategory.vue';
import SliderWithIndicator from '~/components/molecules/SliderWithIndicator/SliderWithIndicator.vue';

export default defineComponent({
  name: 'NavMainBarMobile',
  components: {
    NavMainBarOnSale,
    NavMainBarCategory,
    SliderWithIndicator,
  },
  props: {
    categories: {
      type: Array as PropType<Category[]>,
      default: () => {
        return [];
      },
    },
    hideOnSaleLink: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const middleOfCategories = computed<number>(() => {
      return Math.ceil(props.categories.length / 2);
    });

    const firstHalf = computed<Category[]>(() => {
      return props.categories.slice(
        0, middleOfCategories.value,
      );
    });
    const secondHalf = computed<Category[]>(() => {
      return props.categories.slice(
        middleOfCategories.value,
      );
    });

    return {
      firstHalf,
      secondHalf,
    };
  },
});
