const { gql } = require('graphql-tag');

module.exports = {
  fragment: gql`
    fragment DefaultAddress on Address {
      id
      firstName
      lastName
      streetName
      streetNumber
      additionalAddressInfo
      department
      postalCode
      city
      country
      company
      phone
      fax
      custom {
        customFieldsRaw {
          name
          value
        }
      }
    }
  `,
};
