import { computed } from '@nuxtjs/composition-api';
import { useCartExtended, useCheckout } from '~/composables';
import { getTotalNetPrice, getTotalTaxPrice, getTaxPortions } from '~/helpers/cart/getTotalPrices';
import { getTotalGrossPrice } from '~/helpers/cart/getTotalPricesModule';

export default function () {
  const { cart, codFeeAmount } = useCartExtended();
  const { isPaymentCodSelected } = useCheckout();
  const codFeeAmountForCartCalculations = computed(() => {
    return isPaymentCodSelected.value ? codFeeAmount.value : 0;
  });
  const vatPercentage = computed(() => getTaxPortions(cart.value).percentage / 100);
  const codFeeNetPrice = computed(() => codFeeAmountForCartCalculations.value / (1 + vatPercentage.value));
  const codFeeTaxPrice = computed(() => codFeeAmountForCartCalculations.value - codFeeNetPrice.value);

  const finalAmount = computed(() => {
    return getTotalGrossPrice(cart.value) + codFeeAmountForCartCalculations.value;
  });

  const subTotal = computed(() => {
    return getTotalNetPrice(cart.value) + codFeeNetPrice.value;
  });
  const vatTotal = computed(() => {
    return getTotalTaxPrice(cart.value) + codFeeTaxPrice.value;
  });

  return {
    finalAmount,
    subTotal,
    vatTotal,
    codFeeAmountForCartCalculations,
  };
}
