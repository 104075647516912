export const extraGuaranteeAttributes = {
  extraGuaranteePrice: 'EXTRA-GUARANTEE-PRICE',
  skuForExtraGuarantee: 'SKU-FOR-EXTRA-GUARANTEE',
  productNameForExtraGuarantee: 'PRODDUCT-NAME-FOR-EXTRA-GUARANTEE',
  productImageForExtraGuarantee: 'PRODDUCT_IMAGE_FOR_EXTRA_GUARANTEE',
  excludedBrands: 'extra-guarantee-excluded-brands',
  excludedSkus: 'extra-guarantee-excluded-skus',
  excludedRelations: 'extra-guarantee-excluded-relations',
  extraGuaranteeRate: 'relative-price-rate',
} as const;

export type ExtraGuaranteeAttributes = typeof extraGuaranteeAttributes[keyof typeof extraGuaranteeAttributes];
