const { gql } = require('graphql-tag');

module.exports = {
  fragment: gql`
    fragment Price on ProductVariant {
      price(currency: $currency, country: $country) {
        ...DefaultProductPrice
      }
    }
  `,
};
