import { computed, onMounted, onUnmounted, ref } from '@nuxtjs/composition-api';
import { useVSFContext } from '@vue-storefront/core';
import { MIN_DESKTOP_WIDTH } from '~/constants/ui';

/**
 * A composable that helps to define the size of the window
 * Should be avoided on template as it creates render errors at non-mobile devices at small widths
 */
// Only use this composable where css solution won't give expected results
export const useUiMode = () => {
  const { $device } = useVSFContext();
  const width = ref(typeof window !== 'undefined' ? window.innerWidth : 0);
  const onResise = () => {
    if (typeof window !== 'undefined') {
      width.value = window.innerWidth;
    }
  };
  onMounted(() => {
    window.addEventListener('resize', onResise);
  });
  onUnmounted(() => {
    window.removeEventListener('resize', onResise);
  });
  const isMobile = computed(() => {
    return process?.server
      ? $device.isMobile || $device.isTablet
      : width.value < MIN_DESKTOP_WIDTH;
  });

  return {
    isMobile,
  };
};
