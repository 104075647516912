









import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'Hint',
  props: {
    text: {
      type: String,
      default: '',
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
});
