import { computed } from '@nuxtjs/composition-api';
import type { Ref } from '@nuxtjs/composition-api';
import { useVSFContext } from '@vue-storefront/core';
import { useCategories } from '~/composables';
import { STATUS_RESOURCE_NOT_FOUND } from '~/constants/http';
import { getCategoryNameFromFilter } from '~/helpers/category/getCategoryNameFromFilter';
import {
  generateMetaTitleForInvalidPage,
  generateMetaTitleWithCTFormat,
} from '~/helpers/commercetools/meta/generateMetaTitle';

export const useCategoryPageTitle = (pageNumber: Ref<number>) => {
  const TITLE_SEPARATOR = '|';

  const { i18n } = useVSFContext();
  const {
    getters: {
      currentCategoryMetaTitle,
    },
  } = useCategories();
  const currentPageName = computed(() => i18n.t('Page', { pageNumber: pageNumber.value }));

  const generateActiveCategoryName = (categoryFilter: string) => {
    const categoryName = getCategoryNameFromFilter(categoryFilter);
    if (pageNumber.value > 1) {
      return `${categoryName} - ${currentPageName.value}`;
    }
    return categoryName;
  };

  const generateMetaTitleForValidPage = (activeCategory: string): string => {
    if (pageNumber.value === 1) {
      return currentCategoryMetaTitle.value ||
        generateMetaTitleWithCTFormat({ title: activeCategory, i18n }).toString();
    }

    if (!currentCategoryMetaTitle.value) return activeCategory;

    const [baseTitle] = currentCategoryMetaTitle.value.split(TITLE_SEPARATOR);
    return `${(baseTitle?.trim() || activeCategory)} - ${currentPageName.value}`;
  };

  const generateMetaTitle = (activeCategory: string, pageStatusCode?: Maybe<number>): string => {
    if (pageStatusCode === STATUS_RESOURCE_NOT_FOUND) {
      return generateMetaTitleForInvalidPage(i18n);
    }
    return generateMetaTitleForValidPage(activeCategory);
  };

  return {
    generateActiveCategoryName,
    generateMetaTitle,
  };
};
