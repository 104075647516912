import { CATEGORY_FILTER_INDICATOR, CATEGORY_FILTER_SEPARATOR } from '~/constants/algolia';
import { CategoryTree } from '~/constants/categoryTree';

export const convertCommerceToolsCategories = (
  commerceToolsCategories: Maybe<string[]>,
  categoryTree: CategoryTree,
): string => {
  if (!commerceToolsCategories || !commerceToolsCategories.length) {
    return '';
  }
  const mainCategory = `${categoryTree.algoliaKey}.lvl${commerceToolsCategories.length - 1}`;
  const remainingPath = commerceToolsCategories.join(CATEGORY_FILTER_INDICATOR);
  const algoliaCategoryPath = `${mainCategory}${CATEGORY_FILTER_SEPARATOR}"${remainingPath}"`;
  return algoliaCategoryPath;
};
