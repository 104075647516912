const { gql } = require('graphql-tag');

module.exports = {
  fragment: gql`
    fragment SimplifiedVariant on ProductVariant {
      id
      sku
    }
  `,
};
