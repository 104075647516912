import { computed } from '@nuxtjs/composition-api';
import { Logger, sharedRef } from '@vue-storefront/core';
import type { OrderQueryResult } from '@vsf-enterprise/commercetools-types';
import { useUserOrderApi } from '~/utils/ct/composables/api/useUserOrderApi';
import type { ApiParams } from '~/utils/ct';
import { errorToString } from '~/helpers/error/errorToString';

export function useUserOrder() {
  const { search: apiSearch } = useUserOrderApi();
  const orders = sharedRef<
   OrderQueryResult | null
>(null, 'useUserOrder}');
  const loading = sharedRef(false, 'useUserOrderLoading');
  const error = sharedRef<{
    search: null | string
  }>({
    search: null,
  }, 'useUserOrderError');

  async function search(searchParams: ApiParams) {
    try {
      loading.value = true;
      orders.value = await apiSearch(searchParams);
      error.value.search = null;
    } catch (err: unknown) {
      error.value.search = errorToString(err);
      Logger.error('useUserOrder.search', err);
    } finally {
      loading.value = false;
    }
  }

  return {
    orders: computed(() => orders.value),
    search,
    loading: computed(() => loading.value),
    error: computed(() => error.value),
  };
}
