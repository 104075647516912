const gql = require('graphql-tag');
const { CUSTOM_QUERIES } = require('../../../../constants/customQueries');

const addShippingAddressQuery = {
  [CUSTOM_QUERIES.SHIPPING.addAddress.customAddShippingAddress]: ({ variables }) => ({
    query: gql`
      mutation addShippingAddress(
        $address: AddressInput!
        $version: Long!
        $locale: Locale!
        $acceptLanguage: [Locale!]
      ) {
        customer: updateCustomer(
          version: $version
          actions: [
            {
              addShippingAddressId: {
                address: $address
              }
            }
          ]
        ) {
          version
          shippingAddressIds
          addresses {
            id
            firstName
            lastName
            streetName
            streetNumber
            postalCode
            city
            country
            phone
            isDefaultShippingAddress
            isDefaultBillingAddress
          }
        }
      }
    `,
    variables: {
      address: variables.address,
      version: variables.version,
      locale: variables.locale,
      acceptLanguage: variables.acceptLanguage,
    },
  }),
};

module.exports = addShippingAddressQuery;
