const { SHIPPING_METHOD_KEY_DEFAULT } = require('#~/constants/shippingTypes/shippingMethodKeys.js');

const SHIPPING_METHOD_TYPE_ID = 'shipping-method';

/**
 * @param {string} country
 * @returns {{shippingMethod: {typeId: string, key: string},
 * shippingAddress: {country: string}, billingAddress: {country: string}}} MyCartDraft
 */
module.exports.getDefaultCart = (country) => ({
  billingAddress: {
    country,
  },
  shippingAddress: {
    country,
  },
  shippingMethod: {
    typeId: SHIPPING_METHOD_TYPE_ID,
    key: SHIPPING_METHOD_KEY_DEFAULT,
  },
});
