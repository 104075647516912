/* eslint-disable camelcase */
export class ClickAlternativeProduct {
  event: string;
  ecommerce: { product_id: string }

  constructor(eventName: string, productId: string) {
    this.event = eventName;
    this.ecommerce = { product_id: productId };
  }
}
