


























































import { defineComponent, ref } from '@nuxtjs/composition-api';
import { SfModal } from '@storefront-ui/vue';
import { useVSFContext } from '@vue-storefront/core';
import Button from '~/components/atoms/Buttons/Button/Button.vue';

export default defineComponent({
  components: { SfModal, Button },
  setup(_, { emit }) {
    const { i18n } = useVSFContext();

    const reasons = ref([
      { title: 'Invalid VAT ID:', description: 'The number entered may be incorrect or does not exist.' },
      { title: 'Private VAT ID:', description: 'A personal VAT ID was used instead of a business one.' },
      { title: 'System Error:', description: 'There was a technical issue.' },
    ]);

    const reclaimMessage = i18n.t(
      'You can still complete your purchase and easily reclaim any taxes ' +
      'by contacting your local tax office with the right documents.',
    );

    const closeModal = () => {
      emit('close');
    };

    return {
      closeModal,
      reasons,
      reclaimMessage,
    };
  },
});
