import { useAdyen } from '@vsf-enterprise/adyen-commercetools';
import { onBeforeUnmount, watch } from '@nuxtjs/composition-api';
import useAdyenError from '~/composables/stores/useAdyenError';
import { usePromiseQueue } from '~/composables/modules/usePromiseQueue';
import { CART_PROMISE_QUEUE_KEY } from '~/constants/checkout';
import { useCheckoutBilling, useCheckoutClientType } from '~/composables';
import { PAYMENT_METHODS } from '~/constants/paymentMethods';
import hideKlarnaPaymentMethods from '~/helpers/adyen/hideKlarnaPaymentMethods';
import hideBilliePaymentMethod from '~/helpers/adyen/hideBilliePaymentMethod';

export const useAdyenExtended = () => {
  const useAdyenInstance = useAdyen();
  const {
    error,
    errorPayAndOrder,
    setError,
  } = useAdyenError();

  const { billingDetails, vatNumber } = useCheckoutBilling();

  const { isCompany } = useCheckoutClientType('Billing');

  const payAndOrder = usePromiseQueue(useAdyenInstance.payAndOrder, CART_PROMISE_QUEUE_KEY).execute;

  const createContext = async (isExpressPayments: boolean) => {
    const paymentMethodsResponse = await useAdyenInstance.createContext();
    if (isExpressPayments) {
      paymentMethodsResponse.paymentMethods = paymentMethodsResponse.paymentMethods.filter(
        paymentMethod => paymentMethod.type === PAYMENT_METHODS.GOOGLEPAY);
      return paymentMethodsResponse;
    }
    if (isCompany.value) {
      hideKlarnaPaymentMethods(paymentMethodsResponse);
    }
    if (!isCompany.value) {
      hideBilliePaymentMethod(paymentMethodsResponse);
    }
    return paymentMethodsResponse;
  };

  const getCompanyData = () => {
    return isCompany.value
      ? {
        company: {
          name: billingDetails.value?.company,
          taxId: vatNumber.value,
          registryLocation: billingDetails.value?.country,
        },
      }
      : {};
  };

  watch(useAdyenInstance.error, () => {
    setError({
      ...useAdyenInstance.error.value,
    });
  }, {
    deep: true,
    immediate: true,
  });

  onBeforeUnmount(() => {
    setError(null);
  });

  return {
    ...useAdyenInstance,
    payAndOrder,
    error,
    errorPayAndOrder,
    getCompanyData,
    createContext,
  };
};
