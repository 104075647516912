// import { sharedRef } from '@vue-storefront/core';
import { useBilling, useUser } from '@vsf-enterprise/commercetools';
import { ref, watch } from '@nuxtjs/composition-api';
import type { Address } from '@vsf-enterprise/commercetools-types';
import {
  useCheckoutClientType,
  useCartExtended,
  useCookies,
  useUserBillingExtended,
  useCheckoutAddress,
  // useCheckout,
  useBillingDetails,
} from '~/composables';
import { useVatValidation } from '~/composables/useVatValidation';
import { CheckoutClientType } from '~/types/checkout/formData/checkoutClientType';
import { NOT_SELECTED_ADDRESS } from '~/constants/checkout';
import { CUSTOM_QUERIES } from '~/constants/customQueries';
import useCheckoutAddressVat from '~/composables/useCheckoutAddressVat';
import setVatNumberToAddress from '~/helpers/setVatNumberToAddress';
import getVatNumberFromCustomerAddress from '~/helpers/getVatNumberFromCustomerAddress';
import registerAddressErrors from '~/helpers/Checkout/registerAddressErrors/registerAddressErrors';
import { ValidationProvider } from '~/types/vee-validate';
import { BillingAddressType } from '~/types/checkout/BillingAddressType';

// shared state
const currentAddressId = ref(NOT_SELECTED_ADDRESS);
const saveAddressCheckbox = ref(false);

const useCheckoutBilling = () => {
  const {
    billing: address,
    load: loadBilling,
  } = useBilling();
  const {
    isAuthenticated,
  } = useUser();
  const { clientType, setClientTypeFromAddress } = useCheckoutClientType('Billing');
  const { setBillingDetails: setCartBillingDetails } = useCartExtended();
  const {
    setDefaultAddress,
    addAddress,
    updateAddress,
    currentCountryBillingAddresses,
    setBillingAddressType,
  } = useUserBillingExtended();
  const { country } = useCookies();
  const {
    validateVatNumber,
  } = useVatValidation();
  // const { defaultAddress } = useCheckout();
  const { billingDetails, setBillingDetailsMutation } = useBillingDetails();
  // const billingDetails = sharedRef<Partial<Address>>(address.value || defaultAddress, 'billingDetails');
  const { vatNumber, setVatNumber } = useCheckoutAddressVat('Billing');
  const useCheckoutAddressInstance = useCheckoutAddress({
    addressType: 'Billing',
    scrollToTopOfForm: '#BillingForm',
    addressDetails: billingDetails,
  });
  const {
    resetNotifications,
    scrollToField,
    setScrollToField,
    resetBusinessInfoForPrivateCustomer,
    removeUnnecessaryAddressProperties,
  } = useCheckoutAddressInstance;
  const handleResetBusinessInfo = async () => {
    await resetBusinessInfoForPrivateCustomer({
      setVatNumber,
      clientType: clientType.value,
    });
  };

  const handleBillingBeforeSubmit = async (isValid: Boolean,
    fields: Record<string, ValidationProvider> | undefined) => {
    resetNotifications();
    if (isValid && 'postalCode' in billingDetails.value) {
      await handleResetBusinessInfo();
      if (clientType.value === CheckoutClientType.COMPANY) {
        await validateVatNumber(vatNumber.value);
      }
    } else {
      registerAddressErrors(fields);
    }
  };

  const handleSaveBillingAddress = async () => {
    removeUnnecessaryAddressProperties();
    if (vatNumber.value) {
      setVatNumberToAddress(billingDetails.value, vatNumber.value);
    }
    if (!billingDetails.value?.id) {
      const currentAddresses = currentCountryBillingAddresses.value;
      await addAddress({
        address: billingDetails.value,
        customQuery: CUSTOM_QUERIES.UPDATE_MY_CUSTOMER_BILLING_ADDRESS,
      });
      return currentCountryBillingAddresses.value.filter(address => !currentAddresses.includes(address))[0];
    }
    await updateAddress({
      address: billingDetails.value,
      customQuery: CUSTOM_QUERIES.UPDATE_MY_CUSTOMER_BILLING_ADDRESS,
    });
    return billingDetails.value;
  };

  const handleBillingAfterSubmit = async () => {
    if (!isAuthenticated.value) {
      return;
    }
    let savedAddress = null;
    if (saveAddressCheckbox.value) {
      savedAddress = await handleSaveBillingAddress();
    }

    if (savedAddress) {
      await setDefaultAddress({
        address: savedAddress,
        customQuery: CUSTOM_QUERIES.SET_MY_CUSTOMER_DEFAULT_BILLING_ADDRESS,
      });
    } else if (billingDetails.value?.id) {
      await setDefaultAddress({
        address: billingDetails.value,
        customQuery: CUSTOM_QUERIES.SET_MY_CUSTOMER_DEFAULT_BILLING_ADDRESS,
      });
    }
  };

  const load = async () => {
    await loadBilling();
    await setBillingDetailsFromDefaultAddress();
    if (!currentCountryBillingAddresses?.value?.length) {
      return;
    }

    await setClientTypeFromAddress(billingDetails.value);
    initAddressType();
    if (!vatNumber.value) {
      await setVatNumber(getVatNumberFromCustomerAddress(billingDetails.value));
    }
  };

  const initAddressType = () => {
    if (billingDetails?.value?.id) {
      setBillingAddressType(BillingAddressType.SAVED_BILLING_ADDRESS);
      return;
    } else {
      setBillingAddressType(BillingAddressType.NEW_BILLING_ADDRESS);
    }
    const hasEmptyBillingDetails = !billingDetails?.value?.postalCode;
    if (!hasEmptyBillingDetails) {
      setBillingAddressType(BillingAddressType.NEW_BILLING_ADDRESS);
    }
  };

  const setBillingDetailsField = (field: string, value: string) => {
    return setBillingDetails({
      ...billingDetails.value,
      [field]: value,
    }, false);
  };
  const setBillingDetails = async (address: Partial<Address>, isFullAddress = true) => {
    if (address?.country?.toLowerCase() !== country?.toLowerCase()) {
      await setCartBillingDetails({ country }, '');
      return;
    }
    setBillingDetailsMutation({ ...address });
    if (isFullAddress) {
      currentAddressId.value = address.id ?? NOT_SELECTED_ADDRESS;
      await setClientTypeFromAddress(billingDetails.value);
      await setVatNumber(getVatNumberFromCustomerAddress(billingDetails.value));
    } else {
      currentAddressId.value = NOT_SELECTED_ADDRESS;
    }
  };
  watch(address, async () => {
    if (address.value?.firstName && !Object.prototype.hasOwnProperty.call(billingDetails.value, 'firstName')) {
      await setBillingDetails(address.value);
    }
  }, { immediate: true });

  const setBillingDetailsFromDefaultAddress = () => {
    const hasEmptyBillingDetails = !billingDetails?.value?.postalCode;
    const userDefaultAddress = currentCountryBillingAddresses.value?.[0];
    if (userDefaultAddress && hasEmptyBillingDetails) {
      return setBillingDetails(userDefaultAddress);
    }
  };

  return {
    ...useCheckoutAddressInstance,
    load,
    currentAddressId,
    vatNumber,
    setVatNumber,
    setBillingDetails,
    setBillingDetailsField,
    handleBillingBeforeSubmit,
    handleBillingAfterSubmit,
    billingDetails,
    scrollToField,
    setScrollToField,
    saveAddressCheckbox,
  };
};

export default useCheckoutBilling;
