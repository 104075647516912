import type { ProductVariant } from '~/utils/ct/types/product/ProductVariant';
import { useI18n, useProductsExtraGuarantee } from '~/composables';
import type { ExcludedRelation } from '~/types/extraGuarantee/ExcludedRelations';
import { checkExclusionRules } from '~/helpers/extraGuarantee/exclusionRules';
import { getRawAttributeAsArray } from '~/helpers/product/getRawAttributeAsArray';
import { EXTRA_GUARANTEE_ATTRIBUTES } from '~/constants/extraGuarantee';
import { isAWareProduct } from '~/helpers/product/productCondition';

export const useExtraGuaranteeExcluded = () => {
  const { languageAndCountry } = useI18n();
  const { productsExtraGuarantee } = useProductsExtraGuarantee();

  const getExcludedSkus = (product: ProductVariant): string[] => {
    return getRawAttributeAsArray(product, EXTRA_GUARANTEE_ATTRIBUTES.EXCLUDED_SKUS);
  };
  const getExcludedBrands = (product: ProductVariant): string[] => {
    return getRawAttributeAsArray(product, EXTRA_GUARANTEE_ATTRIBUTES.EXCLUDED_BRANDS);
  };
  const getExcludedRelations = (product: ProductVariant): ExcludedRelation[] => {
    return getRawAttributeAsArray(product, EXTRA_GUARANTEE_ATTRIBUTES.EXCLUDED_RELATIONS);
  };

  const isGuaranteeExcluded = (product: Maybe<ProductVariant>) => {
    if (!productsExtraGuarantee.value?.length || !isAWareProduct(product?.sku)) {
      return true;
    }

    for (const extraGuaranteeProduct of productsExtraGuarantee.value) {
      const isExcluded = checkExclusionRules(
        product,
        languageAndCountry.value,
        {
          excludedSkus: getExcludedSkus(extraGuaranteeProduct),
          excludedBrands: getExcludedBrands(extraGuaranteeProduct),
          excludedRelations: getExcludedRelations(extraGuaranteeProduct),
        },
      );

      if (!isExcluded) {
        return false;
      }
    }

    return true;
  };

  return {
    isGuaranteeExcluded,
  };
};
