import { isSeparateMiddleware } from '~/helpers/env/checkEnvironmentType';

export function resolveMiddlewareUrl({
  middlewareUrl,
  ssrMiddlewareUrl,
  port,
}: {
  middlewareUrl?: string;
  ssrMiddlewareUrl?: string;
  port: number;
}): string {
  if (!process?.server && middlewareUrl) {
    return middlewareUrl;
  }
  if (!isSeparateMiddleware() && middlewareUrl) {
    return `http://localhost:${port}${middlewareUrl}`;
  }
  return ssrMiddlewareUrl || middlewareUrl || 'missing middlewareUrl';
}
