import type { CustomFieldInput, Address } from '@vsf-enterprise/commercetools-types';
import {
  VAT_NUMBER_LABEL, ADDRESS_CUSTOM_FIELDS, VAT_VALIDATED_LABEL, VAT_RAW_RESPONSE,
} from '~/constants/commerceTools';
import { VatResult } from '~/types/integrations/vatValidation/VatIntegration';
import { AddressType } from '~/types/AddressType';

export type VatNumber = Maybe<string>;

export const getVatNumberFromAddress = (address: Maybe<Address>): string => {
  return address?.custom?.customFieldsRaw?.find(item => item.name === VAT_NUMBER_LABEL)?.value;
};

export const setVatNumber = (
  addressType: AddressType,
  vatNumber: Maybe<VatNumber>,
  vatResult: Maybe<VatResult> = undefined,
) => {
  const fields: Array<CustomFieldInput> = [];
  if (vatNumber) {
    fields.push(buildVatNumberField(vatNumber));
  }
  if (vatResult) {
    fields.push(buildVatValidatedField(vatResult?.valid));
    fields.push(buildVatRawResponseField(vatResult));
  }
  return setAddressCustomType(addressType, fields);
};

export const setAddressCustomType = (
  addressType: AddressType,
  fields: Array<CustomFieldInput>,
) => {
  return setCustomType(fields, ADDRESS_CUSTOM_FIELDS, `set${addressType}AddressCustomType`);
};

export const buildVatNumberField = (vatNumber: VatNumber): CustomFieldInput => {
  return {
    value: JSON.stringify(vatNumber),
    name: VAT_NUMBER_LABEL,
  };
};

export const buildVatRawResponseField = (vatResult: VatResult): CustomFieldInput => {
  return {
    value: JSON.stringify(vatResult.response),
    name: VAT_RAW_RESPONSE,
  };
};

export const buildVatValidatedField = (vatValid: Boolean): CustomFieldInput => {
  return {
    value: String(vatValid),
    name: VAT_VALIDATED_LABEL,
  };
};

export const setCustomType = (fields: Array<CustomFieldInput>, key: string, customType: string) => {
  return {
    [customType]: {
      type: {
        key,
      },
      fields,
    },
  };
};
