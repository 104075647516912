/* eslint-disable no-restricted-syntax */
import type { DocumentNode } from '@apollo/client';
import {
  Scalars,
  Address,
  CustomFieldsType,
  ReviewTarget,
  ReviewRatingStatistics,
  ProductSelectionSetting,
  RawCustomField,
} from '~/utils/ct';

export type CustomQueryItem = {query: DocumentNode, variables: Record<string, unknown>};

export const inventoryMode = {
  None: 'None',
  ReserveOnOrder: 'ReserveOnOrder',
  TrackOnly: 'TrackOnly',
} as const;

export type InventoryMode = keyof typeof inventoryMode;

export enum ChannelRole {
  InventorySupply = 'InventorySupply',
  ProductDistribution = 'ProductDistribution',
  OrderExport = 'OrderExport',
  OrderImport = 'OrderImport',
  Primary = 'Primary',
}

export enum TextInputHint {
  SingleLine = 'SingleLine',
  MultiLine = 'MultiLine',
}

export type Maybe<T> = T | null;

export type FieldType = {
  name: Scalars['String'];
};

export type Reference = {
  __typename?: 'Reference';
  typeId: Scalars['String'];
  id: Scalars['String'];
};

export type Dimensions = {
  __typename?: 'Dimensions';
  width: Scalars['Int'];
  height: Scalars['Int'];
};

export type Image = {
  __typename?: 'Image';
  url: Scalars['String'];
  dimensions: Dimensions;
  label?: Maybe<Scalars['String']>;
};

export type LocalizedString = {
  __typename?: 'LocalizedString';
  locale: Scalars['Locale'];
  value: Scalars['String'];
};

export type FieldDefinition = {
  __typename?: 'FieldDefinition';
  name: Scalars['String'];
  required: Scalars['Boolean'];
  inputHint: TextInputHint;
  label?: Maybe<Scalars['String']>;
  labelAllLocales: Array<LocalizedString>;
  type: FieldType;
};

export type SuggestTokenizer = {
  type: Scalars['String'];
};

export type KeyReference = {
  __typename?: 'KeyReference';
  typeId: Scalars['String'];
  key: Scalars['String'];
};

export type SearchKeyword = {
  __typename?: 'SearchKeyword';
  text: Scalars['String'];
  suggestTokenizer?: Maybe<SuggestTokenizer>;
};

export type SearchKeywords = {
  __typename?: 'SearchKeywords';
  locale: Scalars['Locale'];
  searchKeywords: Array<SearchKeyword>;
};
export type Initiator = {
  __typename?: 'Initiator';
  isPlatformClient?: Maybe<Scalars['Boolean']>;
  externalUserId?: Maybe<Scalars['String']>;
  anonymousId?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  customerRef?: Maybe<Reference>;
  userRef?: Maybe<Reference>;
};

export type Versioned = {
  id: Scalars['String'];
  version: Scalars['Long'];
  createdAt: Scalars['DateTime'];
  lastModifiedAt: Scalars['DateTime'];
  createdBy?: Maybe<Initiator>;
  lastModifiedBy?: Maybe<Initiator>;
};

export type InputMaybe<T> = Maybe<T>;

export type GeometryInput = {
  type: Scalars['String'];
  coordinates?: InputMaybe<Array<Scalars['Float']>>;
};

export type Channel = Versioned & ReviewTarget & {
  __typename?: 'Channel';
  id: Scalars['String'];
  version: Scalars['Long'];
  key: Scalars['String'];
  roles: Array<ChannelRole>;
  name?: Maybe<Scalars['String']>;
  nameAllLocales?: Maybe<Array<LocalizedString>>;
  description?: Maybe<Scalars['String']>;
  descriptionAllLocales?: Maybe<Array<LocalizedString>>;
  address?: Maybe<Address>;
  geoLocation?: Maybe<GeometryInput>;
  createdAt: Scalars['DateTime'];
  lastModifiedAt: Scalars['DateTime'];
  reviewRatingStatistics?: Maybe<ReviewRatingStatistics>;
  custom?: Maybe<CustomFieldsType>;
  createdBy?: Maybe<Initiator>;
  lastModifiedBy?: Maybe<Initiator>;
};

export type SyncInfo = {
  __typename?: 'SyncInfo';
  channelRef: Reference;
  channel?: Maybe<Channel>;
  externalId?: Maybe<Scalars['String']>;
  syncedAt: Scalars['DateTime'];
};

export type Store = Versioned & {
  __typename?: 'Store';
  id: Scalars['String'];
  version: Scalars['Long'];
  key: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  nameAllLocales?: Maybe<Array<LocalizedString>>;
  languages?: Maybe<Array<Scalars['Locale']>>;
  /** BETA: This feature can be subject to change and should be used carefully in production.
   * https://docs.commercetools.com/api/contract#beta-features */
  productSelections: Array<ProductSelectionSetting>;
  distributionChannelsRef: Array<Reference>;
  distributionChannels: Array<Channel>;
  supplyChannelsRef: Array<Reference>;
  supplyChannels: Array<Channel>;
  createdAt: Scalars['DateTime'];
  lastModifiedAt: Scalars['DateTime'];
  custom?: Maybe<CustomFieldsType>;
  createdBy?: Maybe<Initiator>;
  lastModifiedBy?: Maybe<Initiator>;
};

export type TypeDefinition = Versioned & {
  __typename?: 'TypeDefinition';
  key: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  nameAllLocales: Array<LocalizedString>;
  descriptionAllLocales?: Maybe<Array<LocalizedString>>;
  resourceTypeIds: Array<Scalars['String']>;
  fieldDefinitions: Array<FieldDefinition>;
  id: Scalars['String'];
  version: Scalars['Long'];
  createdAt: Scalars['DateTime'];
  lastModifiedAt: Scalars['DateTime'];
  createdBy?: Maybe<Initiator>;
  lastModifiedBy?: Maybe<Initiator>;
};

export type InterfaceInteractionsRaw = {
  __typename?: 'InterfaceInteractionsRaw';
  typeRef: Reference;
  type?: Maybe<TypeDefinition>;
  fields: Array<RawCustomField>;
};

export type InterfaceInteractionsRawResult = {
  __typename?: 'InterfaceInteractionsRawResult';
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  results: Array<InterfaceInteractionsRaw>;
};
