import { computed } from '@nuxtjs/composition-api';
import { defineModule } from '~/helpers/vuex/defineModule/defineModule';
import {
  GuaranteeActionMutations,
} from '~/types/vuex/GuaranteeAction';
import { MODULE_NAMES } from '~/types/vuex/Vuex';
export default function () {
  const store = defineModule<
    typeof MODULE_NAMES.IS_GUARANTEE_ACTION_BEING_CLICKED,
    GuaranteeActionMutations,
    {},
    {}
  >(MODULE_NAMES.IS_GUARANTEE_ACTION_BEING_CLICKED);
  const isGuaranteeActionBeingClicked = computed(() => store.state.isGuaranteeActionBeingClicked);
  return {
    isGuaranteeActionBeingClicked,
    ...store.getters,
    ...store.mutations,
    ...store.actions,
  };
}
