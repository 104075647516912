import express from 'express';

export const AllowedMethods = {
  get: 'get',
  post: 'post',
} as const;

export type AllowedMethod = ValueOf<typeof AllowedMethods>;

export type RouteConfig = {
  path: string;
  handler: express.RequestHandler;
  method: AllowedMethod;
};
