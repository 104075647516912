import { extendModule } from '~/helpers/vuex/extendModule/extendModule';
import type {
  UiNotificationState,
} from '~/types/vuex/UiNotification';
import { UiNotification } from '~/types/components/Notification/UiNotification';

const state = (): UiNotificationState => ({
  uiNotifications: [],
});

const mutations = {
  addUiNotification(state: UiNotificationState, notification: UiNotification) {
    state.uiNotifications.push(notification);
  },
  removeUiNotification(state: UiNotificationState, notificationId: string) {
    state.uiNotifications = state.uiNotifications.filter(notification => notification.id !== notificationId);
  },
};

export default extendModule({
  state, mutations,
});
