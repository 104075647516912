import { useProduct } from '@vsf-enterprise/commercetools';
import { computed } from '@nuxtjs/composition-api';
import type { ProductsSearchParams } from '~/utils/ct';
import { useI18n, usePrefix } from '~/composables';
import extendProducts from '~/helpers/product/extendProducts';
import type { ProductVariantGql } from '~/types/product/ProductVariantGql';
import { getProductSearchParams } from '~/helpers/product/getProductSearchParams';
import { CUSTOM_QUERIES } from '~/constants/customQueries';

export default function (cacheId: string) {
  const {
    products: oldProducts,
    search: oldSearch,
    loading,
  } = useProduct<ProductVariantGql>(`related-${cacheId}`);
  const { languagePrefix } = usePrefix();

  const products = computed<ProductVariantGql[]>(() =>
    oldProducts.value?.data?.products?.results || [],
  );

  const { languageAndCountry, countryCode } = useI18n();

  const publishedProductsInStock = computed(() => {
    return extendProducts(
      products.value, languageAndCountry.value, countryCode.value, { isPublished: true, inStock: true });
  });

  const search = async (searchParams: ProductsSearchParams) => {
    const productSearchParams = getProductSearchParams(
      searchParams,
      { languageAndCountry: languageAndCountry.value, languagePrefix, isOnStock: true },
    );
    await oldSearch({
      ...productSearchParams,
      customQuery: CUSTOM_QUERIES.PRODUCT_SEARCH.relatedProductQuery,
    });
  };

  return {
    productsInStock: publishedProductsInStock,
    search,
    loading,
  };
}
