import { getPageLinkForSlug } from '~/helpers/routes/getPageInformation';
import { LocalisedString } from '~/helpers/seo/types/LocalisedString';
import { HreflangParameters } from '~/helpers/seo/types/HreflangParameters';
import { HREFLANG_RELATION } from '~/constants/seo';
import { SECURE_WEBSITE_PROTOCOLE_PREFIX } from '~/constants/http';
import localizationDefinitions from '~/i18n/localizationDefinitions';
import CountryLocalization from '~/types/localization/countryLocalization';
import languageCodes from '~/i18n/enums/languageCodes';

const generateHreflangLink = (localisedSlugs?: LocalisedString[],
  isPageHreflangLinkHidden?: boolean, isPdp: boolean = false): HreflangParameters[] => {
  if (isPageHreflangLinkHidden) {
    return [];
  }
  if (isPdp || (localisedSlugs && localisedSlugs.length)) {
    return getHreflangLinkForLocalisedSlugs(localisedSlugs);
  } else {
    return getHreflangLinkForHomePage();
  }
};

const getHreflangLinkForLocalisedSlugs =
(localisedSlugs: LocalisedString[] = []): HreflangParameters[] => {
  const returnValues: HreflangParameters[] = [];
  localisedSlugs.forEach((localizedSlug: LocalisedString) => {
    const countryLocalization = localizationDefinitions.getByLanguageAndCountry(localizedSlug.locale);
    if (!countryLocalization) {
      return;
    }
    const prefix = countryLocalization.getLanguageFromExistingLanguageAndCountry(localizedSlug.locale);
    const hreflangInfo = generateHreflangObject({
      languageAndCountry: localizedSlug.locale,
      slug: `/${localizedSlug.value}`,
      countryLocalization,
      prefix: countryLocalization.getDefaultLanguage() !== prefix ? prefix : undefined,
    });
    if (hreflangInfo) {
      returnValues.push(hreflangInfo);
    }
  });
  return returnValues;
};

const getHreflangLinkForHomePage = (): HreflangParameters[] => {
  const returnValues: HreflangParameters[] = [];
  localizationDefinitions.getAllCountries().forEach((countryLocalization: CountryLocalization) => {
    countryLocalization.getLanguageAndCountries().forEach(languageAndCountry => {
      const prefix = countryLocalization.getLanguageFromExistingLanguageAndCountry(languageAndCountry);
      const hreflangInfo = generateHreflangObject({
        languageAndCountry,
        slug: '',
        countryLocalization,
        prefix: countryLocalization.getDefaultLanguage() !== prefix ? prefix : undefined,
      });
      if (hreflangInfo) {
        returnValues.push(hreflangInfo);
      }
    });
  });
  return returnValues;
};

const generateHreflangObject = ({
  languageAndCountry,
  slug,
  countryLocalization,
  prefix,
}: {
  languageAndCountry: string,
  slug: string,
  countryLocalization: CountryLocalization,
  prefix?: Maybe<ValueOf<typeof languageCodes>>
}):
  HreflangParameters => {
  const parameters: HreflangParameters = {
    rel: HREFLANG_RELATION,
    hreflang: languageAndCountry,
    href: `${SECURE_WEBSITE_PROTOCOLE_PREFIX}${countryLocalization.prodDomain}${prefix ? '/' + prefix : ''}`,
  };
  parameters.href += slug ? getPageLinkForSlug(slug) : '/';
  return parameters;
};

export default generateHreflangLink;
