const WEBSOCKET_EVENT = {
  SOCKET: 'web-socket',
  CART_UPDATE: 'cart-update',
  UPDATED_STOCK: 'updated-stock',
  CLOSE: 'close',
  ERROR: 'error',
  OPEN: 'open',
  CONNECTION: 'connection',
};

const WEBSOCKET_CONFIG = {
  CART_ID: 'cartID',
  DEV_HOST: 'ws://localhost',
  WSS_PROTOCOL: 'wss://',
  MAX_CONNECTIONS_PER_CART: 10,
  RETRIES: 2,
  DELAY: 5000,
};

module.exports = {
  WEBSOCKET_EVENT,
  WEBSOCKET_CONFIG,
};
