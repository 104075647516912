import { Logger } from '@vue-storefront/core';
import { ROUTES } from '~/constants/routes';
import { getNameForRoute } from '~/helpers/routes/getPageInformation';
import { getCt } from '~/helpers/getCt';

// this middleware makes sure that the user is not logged in yet.
export default async ({ app, redirect }) => {
  const ct = getCt(app.context, { vsfCt: true });
  try {
    const isLoggedIn = await ct.api.isLoggedIn();
    if (isLoggedIn) {
      redirect({ name: getNameForRoute(ROUTES.HOME), replace: true });
    }
  } catch (error) {
    Logger.warn('non-authenticated error: ', String(error));
  }
};
