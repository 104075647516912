import type { LineItem } from '@vsf-enterprise/commercetools-types';
import { sharedRef, Logger } from '@vue-storefront/core';
import type { Ref } from '@nuxtjs/composition-api';
import { watch, computed } from '@nuxtjs/composition-api';
import { cartGetters } from '@vsf-enterprise/commercetools';
import { useExtraGuaranteePrice } from '../useExtraGuaranteePrice';
import { useExtraGuaranteeExcluded } from '../useExtraGuaranteeExcluded';
import type { ProductVariant } from '~/utils/ct/types/product/ProductVariant';
import virtualProductSku from '~/constants/virtualProductSku';
import {
  useCartExtended,
  useUserOrder,
  useStoreVsfExtended,
  useIsPage,
  useIntegrations,
} from '~/composables';
import { STANDARD_PRODUCT } from '~/constants/productType';
import { GUARANTEE_PLACEMENT } from '~/constants/guaranteePlacement';
import {
  SKU_FOR_EXTRA_GUARANTEE,
  PRODDUCT_NAME_FOR_EXTRA_GUARANTEE,
  PRODDUCT_IMAGE_FOR_EXTRA_GUARANTEE,
  PARENT_SKU,
  PRODDUCT_PRICE_FOR_EXTRA_GUARANTEE,
} from '~/constants/extraGuarantee';
import { BASE_SKU } from '~/constants/extraGuaranteeSku';
import { getLineItemFromSku } from '~/helpers/cart/getLineItemFromSku';
import { orderGetters } from '~/utils/ct';

type SetExtraGuarenteeInfoParams = {
  price: number;
  name: string;
  image: string;
  sku: string;
};

export const useExtraGuarantee = () => {
  const { cart } = useCartExtended();
  const { order } = useUserOrder();
  const { isExtraGuaranteeEnabled } = useStoreVsfExtended();
  const { $cia } = useIntegrations();
  const { isOnPaymentPage } = useIsPage();

  const {
    getExtraGuaranteePercentage,
  } = useExtraGuaranteePrice();

  const {
    isGuaranteeExcluded,
  } = useExtraGuaranteeExcluded();

  // TO DO INSP-4165 - Create useExtraGuarantee store
  const skuForExtraGuarantee: Ref<string | null> = sharedRef(null, SKU_FOR_EXTRA_GUARANTEE);
  const productNameForExtraGuarantee: Ref<string | null> = sharedRef(null, PRODDUCT_NAME_FOR_EXTRA_GUARANTEE);
  const productImageForExtraGuarantee: Ref<string | null> = sharedRef(null, PRODDUCT_IMAGE_FOR_EXTRA_GUARANTEE);
  const productPriceForExtraGuarantee: Ref<number | null> = sharedRef(null, PRODDUCT_PRICE_FOR_EXTRA_GUARANTEE);
  const setProductNameForExtraGuarantee = (name: string) => {
    productNameForExtraGuarantee.value = name;
  };
  const setProductImageForExtraGuarantee = (name: string) => {
    productImageForExtraGuarantee.value = name;
  };
  const setProductPriceForExtraGuarantee = (price: number) => {
    productPriceForExtraGuarantee.value = price;
  };

  const itemsWithExtraGuarantee = computed(() => {
    if (!cart.value?.lineItems?.length) return [];

    return cart.value?.lineItems
      .filter(item => {
        const customFields = item?.custom?.customFieldsRaw;
        return Array.isArray(customFields) && customFields.some(field => field?.name === PARENT_SKU);
      })
      .map(item => {
        const customFields = item?.custom?.customFieldsRaw || [];
        return customFields.find(field => field?.name === PARENT_SKU)?.value || null;
      })
      .filter(Boolean);
  });

  const setSkuForExtraGuarantee = (sku: string) => {
    skuForExtraGuarantee.value = sku;
  };

  const getItemFromSku = (sku: string) => {
    return getLineItemFromSku(cart.value, sku);
  };

  const skuHasAddedExtraGuarantee = (productSku: ProductVariant['sku']) => {
    return !!cart.value?.lineItems?.find((item: LineItem) =>
      isExtraGuaranteeItem(item, productSku),
    )?.custom?.customFieldsRaw?.[0]?.value;
  };

  const isExtraGuaranteeItem = (item: LineItem, productSku: ProductVariant['sku']) => {
    return item.variant?.sku === virtualProductSku &&
      item.custom?.customFieldsRaw?.[0]?.value === productSku &&
      item.productType?.key !== STANDARD_PRODUCT;
  };

  const cartItemExtraGuaranteeExcluded = (productSku: ProductVariant['sku']) => {
    return cart.value?.lineItems?.some((item: LineItem) => {
      if (item.variant?.sku === productSku) {
        return isGuaranteeExcluded(item.variant);
      }
      return false;
    },
    );
  };

  const extraGuaranteeParentSku = (product: LineItem) => {
    return product.custom?.customFieldsRaw?.[0]?.value;
  };

  const extraGuaranteeParentProduct = (productSku: ProductVariant['sku']) => {
    return (cart.value || order.value)?.lineItems.find((item: LineItem) => {
      return item?.variant?.sku === productSku;
    });
  };

  const extraGuaranteeParentTitle = (productSku: ProductVariant['sku']) => {
    return extraGuaranteeParentProduct(productSku)?.name;
  };

  const getVirtualProductTitle = (virtualProduct: LineItem) => {
    const parentSku = extraGuaranteeParentSku(virtualProduct);
    return extraGuaranteeParentTitle(parentSku) +
      ` - ${cartGetters.getItemName(virtualProduct) || orderGetters.getItemName(virtualProduct)}`;
  };

  const shouldAddExtraGuarantee = (product: LineItem) => {
    const sku = product.variant?.sku;
    return isExtraGuaranteeEnabled.value &&
      !skuHasAddedExtraGuarantee(sku) &&
      !isVirtualProduct(sku) &&
      !isOnPaymentPage.value &&
      !cartItemExtraGuaranteeExcluded(sku);
  };

  const isExtraGuaranteeActionsVisible = ({ sku, hasOnlyOneOption }: { sku: string, hasOnlyOneOption: boolean }) => {
    return isVirtualProduct(sku) && !hasOnlyOneOption && !isOnPaymentPage.value;
  };

  const isVirtualProduct = (productSku: ProductVariant['sku']) => {
    return productSku?.startsWith(BASE_SKU);
  };

  const setExtraGuaranteeInfo = ({ price, name, image, sku }: SetExtraGuarenteeInfoParams) => {
    setSkuForExtraGuarantee(sku);
    setProductNameForExtraGuarantee(name);
    setProductImageForExtraGuarantee(image);
    setProductPriceForExtraGuarantee(price);
  };

  const sendCiaViewGuarantee = (type: typeof GUARANTEE_PLACEMENT[keyof typeof GUARANTEE_PLACEMENT]) => {
    watch(isExtraGuaranteeEnabled, (value) => {
      if (value) {
        cart.value?.lineItems.forEach((product) => {
          if (shouldAddExtraGuarantee(product)) {
            const percentage = getExtraGuaranteePercentage();
            try {
              $cia.event.viewGuarantee(product.variant?.sku, type, percentage);
            } catch (error) {
              Logger.error(`cia|viewGuarantee error: ${error}`);
            }
          }
        });
      }
    }, { immediate: true });
  };

  return {
    skuHasAddedExtraGuarantee,
    skuForExtraGuarantee: computed<string | null>(() => skuForExtraGuarantee.value),
    isVirtualProduct,
    extraGuaranteeParentSku,
    setExtraGuaranteeInfo,
    isExtraGuaranteeItem,
    shouldAddExtraGuarantee,
    sendCiaViewGuarantee,
    cartItemExtraGuaranteeExcluded,
    productNameForExtraGuarantee,
    productImageForExtraGuarantee,
    productPriceForExtraGuarantee,
    itemsWithExtraGuarantee,
    getItemFromSku,
    isExtraGuaranteeActionsVisible,
    getVirtualProductTitle,
  };
};
