import { sharedRef } from '@vue-storefront/core';
import type { Ref } from '@nuxtjs/composition-api';
import { ref } from '@nuxtjs/composition-api';
import { extraGuaranteeAttributes } from '~/types/product/attribute/ProductExtraGuarantee';
import {
  getProductAttributesExtended,
} from '~/helpers/product/getProductAttributesExtended';
import { useProductExtraGuarantee } from '~/composables/stores/useProductExtraGuarantee';

/**
 * @deprecated This composable is deprecated, it does not consider multiple options for extra guarantee
 * Will be removed in scope of https://expondo.atlassian.net/browse/INSP-4831
 */
export const useExtraGuaranteePrice = () => {
  const {
    productExtraGuarantee: extraGuaranteeProduct,
  } = useProductExtraGuarantee();

  const extraGuaranteePrice: Ref<number | null> = sharedRef(null, extraGuaranteeAttributes.extraGuaranteePrice);
  const extraGuaranteePercentage: Ref<number | null> = ref(null);

  const getExtraGuaranteePercentage = (): typeof extraGuaranteePercentage['value'] => {
    if (!extraGuaranteeProduct.value) {
      extraGuaranteePercentage.value = null;
      return null;
    }

    extraGuaranteePercentage.value = getProductAttributesExtended(
      extraGuaranteeProduct.value,
      {
        attributeNames: [extraGuaranteeAttributes.extraGuaranteeRate],
      })[extraGuaranteeAttributes.extraGuaranteeRate] ?? null;
    return extraGuaranteePercentage.value;
  };

  return {
    extraGuaranteePrice,
    getExtraGuaranteePercentage,
  };
};
