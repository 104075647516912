








import { defineComponent } from '@nuxtjs/composition-api';
export default defineComponent({
  name: 'ComponentName',
  props: {
    name: {
      type: String,
      required: true,
    },
  },
});

