import { TagProduct } from '~/types/integrations/tagManager/TagProduct';
import {
  BeginCheckoutEventInfo,
  BeginCheckoutCategory,
  BeginCheckoutEcommerceType,
  BeginCheckoutInformation,
} from '~/types/integrations/tagManager/BeginCheckoutTypes';
import {
  EVENT_BEGIN_CHECKOUT,
  EVENT_BEGIN_CHECKOUT_DEFAULT_EVENT_TYPE,
  PRIMARY_CATEGORY,
} from '~/constants/googleTagManager';

export class BeginCheckoutTags {
  private event: string = EVENT_BEGIN_CHECKOUT;
  private eventInfo: BeginCheckoutEventInfo = {
    detail: EVENT_BEGIN_CHECKOUT,
  };

  private category: BeginCheckoutCategory = {
    event_type: EVENT_BEGIN_CHECKOUT_DEFAULT_EVENT_TYPE,
    primary_category: PRIMARY_CATEGORY,
  };

  private ecommerce: BeginCheckoutEcommerceType = {
    value: undefined,
    tax: undefined,
    currency: undefined,
    items: [],
  };

  assignCartInformation (cartInformation: BeginCheckoutInformation) {
    this.ecommerce = {
      items: this.ecommerce.items,
      ...cartInformation,
    };
  }

  assignProductValues (tagProducts: TagProduct[]) {
    this.ecommerce.items = tagProducts;
  }
}
