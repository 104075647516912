import { onMounted, ref } from '@nuxtjs/composition-api';

export const useIsMounted = () => {
  const isMounted = ref(false);

  onMounted(() => {
    isMounted.value = true;
  });

  return {
    isMounted,
  };
};
