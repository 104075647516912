import type { ProductVariant } from '~/utils/ct/types/product/ProductVariant';
import { RemoveFromCartTagDetailsParams } from '~/types/integrations/tagManager/TagManagerIntegration';
import { Gtm } from '~/types/@nuxtjs/gtm';
import { RemoveFromCartTags } from '~/plugins/integrations/tagManager/helpers/RemoveFromCartTags';
import { prepareProductTags } from '~/plugins/integrations/tagManager/helpers/transformers';

export const triggerRemoveFromCartTagsWrapper = ($gtm: Gtm, languageAndCountry: string, currency: string) => {
  return (
    product: ProductVariant, details: RemoveFromCartTagDetailsParams) => {
    const detailsExtended = {
      ...details,
      languageAndCountry,
      currency,
    };
    const productTags = prepareProductTags(product, detailsExtended);
    const removeItemFromCartTags = new RemoveFromCartTags(productTags, details.textDisplayed, details.triggeredIn);
    $gtm.push(removeItemFromCartTags);
  };
};
