const deleteCartMutation = require('../queries/cart/deleteCart.js');
const cartwebSocketHelper = require('../../../helpers/webSocket.js');
const { isWebSocketEnabledForCountry } = require('../../../helpers/locales/isWebSocketEnabledForCountry');
const { clientMutate } = require('./queries');

/**
 * Endpoint for deleting cart of the currently logged user based on parameters.
 *
 * @param context - Automatically injected context. Refer to {@link Context}
 * @param params - Cart information
 * @param customQuery - Custom queries included in the request
 * @returns Cart data
 */
module.exports.deleteCart = async (context, params, customQuery) => {
  const { id, version } = params;
  const { locale, acceptLanguage, currency, country } = context.config;
  const defaultVariables = {
    id,
    version,
    acceptLanguage,
    locale,
    currency,
  };
  const { deleteCart: deleteCartGql } = context.extendQuery(customQuery, {
    deleteCart: {
      query: deleteCartMutation,
      variables: defaultVariables,
    },
  });
  const response = await clientMutate(context, {
    mutation: deleteCartGql.query,
    variables: deleteCartGql.variables,
  });
  if (isWebSocketEnabledForCountry(country)) {
    cartwebSocketHelper.sendCartUpdate({ data: null, cartId: id });
  }
  return response;
};
