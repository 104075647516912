const { buildOrderWhere } = require('../order');
const { clientQuery } = require('../queries');
const getMyOrdersQuery = require('../../queries/order/thankYouPageOrderQuery.js');
const localizationDefinitions = require('../../../../i18n/localizationDefinitions.ts').default;
const handleCtApiError = require('../handleCtApiError');
const { CUSTOM_QUERIES } = require('../../../../constants/customQueries.js');

/**
 * @param {Context} context
 * @param {*} params
 * @param {Maybe<CustomQuery>} customQuery
 */
const getOrders = async (context, params, customQuery) => {
  const { locale, country, currency } = context.config;
  const acceptLanguage = localizationDefinitions.getByCountryCode(country)
    .getAcceptedLanguages(params.languagePrefix);

  const defaultVariables = {
    where: buildOrderWhere(params),
    sort: params.sort,
    limit: params.limit,
    offset: params.offset,
    acceptLanguage,
    locale,
    currency,
  };

  const { orders } = context.extendQuery(customQuery, {
    orders: getMyOrdersQuery[CUSTOM_QUERIES.THANK_YOU_PAGE_ORDER.getMyOrders]({
      variables: defaultVariables,
    }),
  });

  try {
    const response = await clientQuery(context, {
      query: orders.query,
      variables: orders.variables,
    });
    return response;
  } catch (error) {
    throw handleCtApiError(error);
  }
};

module.exports = getOrders;
