import { cartGetters, useShipping, useBilling } from '@vsf-enterprise/commercetools';
import { onMounted, watch, computed } from '@nuxtjs/composition-api';
import {
  useStock,
  useCartExtended,
  useLineItems,
  useStoreVsfExtended,
  useUserExtended,
  useProductExtraGuarantee,
  useProductsExtraGuarantee,
  useCartWebSocket,
  useCheckoutShipping,
} from '~/composables';
import { ShippingAddressType } from '~/types/checkout/ShippingAddressType';

export default function () {
  const { cart, load: loadCart, setCart } = useCartExtended();
  const { load: loadStore, isLoaded } = useStoreVsfExtended();
  const { load: loadShipping, shipping } = useShipping();
  const { load: loadBilling, billing } = useBilling();
  const { setShippingAddressType } = useCheckoutShipping();
  const {
    productExtraGuarantee: extraGuaranteeProduct,
    load: loadExtraGuaranteeProduct,
  } = useProductExtraGuarantee();

  const { load: loadProductsExtraGuarantee, productsExtraGuarantee } = useProductsExtraGuarantee();

  const { getStocks, stocks } = useStock('cart');
  const { lineItems } = useLineItems();
  const { user, load: loadUser, isAuthenticated } = useUserExtended();
  const { openWebSocket } = useCartWebSocket(setCart);

  onMounted(() => {
    if (!cart?.value) {
      loadCart();
    }
    if (!user?.value) {
      loadUser();
    }
    if (!isLoaded.value) {
      loadStore();
    }
    if (!extraGuaranteeProduct.value) {
      loadExtraGuaranteeProduct();
    }
    if (!productsExtraGuarantee.value) {
      loadProductsExtraGuarantee();
    }
  });

  const cartId = computed(() => cart?.value?.id);

  const sku = computed(() => lineItems.value.map(product => cartGetters.getItemSku(product)));

  watch(cartId, (value) => {
    if (value) {
      openWebSocket(value);
      if (!stocks.value) {
        getStocks({
          sku: sku.value,
        });
      }
      if (!shipping.value) {
        loadShipping();
      }
      if (!billing.value) {
        loadBilling();
      }
    }
  });

  watch(isAuthenticated, async (value) => {
    if (!value) {
      await setShippingAddressType(ShippingAddressType.SAME_AS_BILLING);
    }
  });

  return {
    lineItems,
  };
}
