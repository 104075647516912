import { computed } from '@nuxtjs/composition-api';
import { useShippingMethods, useI18n } from '~/composables';
import estimatedDeliveryDate from '~/helpers/Checkout/estimatedDeliveryDate';
import { ShippingMethodDeliveryInformation } from '~/types/checkout/ShippingMethod';

export function useGTMDeliveryDate() {
  const { selectedShippingMethod } = useShippingMethods();
  const { languageAndCountry } = useI18n();

  const deliveryDate = computed(() => {
    return estimatedDeliveryDate(
      languageAndCountry.value,
      selectedShippingMethod.value as ShippingMethodDeliveryInformation,
    );
  });

  return {
    deliveryDate,
  };
}
