import { useVSFContext, VSF_COUNTRY_COOKIE, VSF_LOCALE_COOKIE } from '@vue-storefront/core';
import Cookies from 'universal-cookie';
import { ref } from '@nuxtjs/composition-api';
import {
  GOOGLE_ANALYTIC_COOKIE,
  ALGOLIA_COOKIE,
  UTM_SOURCE_COOKIE,
  CUSTOMER_ID_COOKIE,
} from '~/constants/cookies';
import getPostHogDistincId from '~/helpers/posthog/getPostHogDistincId';
import { BLOOMREACH_COOKIE } from '~/constants/bloomreach';

const useCookies = () => {
  const { $cookies } = useVSFContext();
  const country = $cookies.get(VSF_COUNTRY_COOKIE);
  const languageAndCountry = $cookies.get(VSF_LOCALE_COOKIE);
  const googleUserToken = $cookies.get(GOOGLE_ANALYTIC_COOKIE);
  const algoliaUserToken = $cookies.get(ALGOLIA_COOKIE);
  const posthogDistinctId = getPostHogDistincId($cookies);
  const universalCookies = new Cookies(null, { path: '/' });
  const bloomreachCookie = ref(universalCookies.get(BLOOMREACH_COOKIE));
  const utmSourceCookie = $cookies.get(UTM_SOURCE_COOKIE);
  const addBloomreachCookieChangeListener = () => {
    universalCookies.addChangeListener((cookie) => {
      if (cookie.name === BLOOMREACH_COOKIE) {
        bloomreachCookie.value = cookie.value;
      }
    });
  };

  const setUtmSourceCookie = (value: string) => {
    if (value.length > 0) {
      $cookies.set(UTM_SOURCE_COOKIE, value);
    }
  };

  const setCustomerIdCookie = (customerId: string | undefined) => {
    $cookies.set(CUSTOMER_ID_COOKIE, customerId);
  };

  return {
    country,
    languageAndCountry,
    posthogDistinctId,
    googleUserToken,
    algoliaUserToken,
    addBloomreachCookieChangeListener,
    bloomreachCookie,
    utmSourceCookie,
    setUtmSourceCookie,
    setCustomerIdCookie,
  };
};

export default useCookies;
