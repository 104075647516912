import { EVENT_VOLUME_CHANGE } from '~/constants/googleTagManager';
import { TriggerQuantityChangeParameters } from '~/types/integrations/tagManager/TagManagerIntegration';

export class QuantityChangeTags {
  event = EVENT_VOLUME_CHANGE
  data = { action_type: '', selected_quantity: 0, page_category: '', sku: '' };

  constructor({ actionType, selectedQuantity, pageCategory, sku }: TriggerQuantityChangeParameters) {
    this.data = {
      action_type: actionType,
      selected_quantity: selectedQuantity,
      page_category: pageCategory,
      sku,
    };
  }
}
