const { gql } = require('graphql-tag');
const { buildRelatedCategoriesQuery } = require('../../helpers/buildRelatedCategoriesQuery');
const { CUSTOM_QUERIES } = require('#~/constants/customQueries.js');
const { CATEGORY_CUSTOM_FIELDS } = require('#~/constants/commerceTools.js');

/*
 * This query fetches a category with all it's parents, while assuming some max tree depth.
 * Used for accessing a category page by URL.
 */
module.exports = {
  [CUSTOM_QUERIES.CATEGORIES.LISTING]: (params) => {
    const variables = params.variables;
    const fetchSiblings = variables.fetchSiblings;

    const relatedCategoriesQuery = buildRelatedCategoriesQuery();
    const siblingsQuery = fetchSiblings
      ? `children {
      ...Child
    }`
      : '';

    return {
      query: gql`
        fragment HasProducts on CustomFieldsType {
          customFieldsRaw(includeNames:["${CATEGORY_CUSTOM_FIELDS.HAS_PRODUCTS}"]){
            name
            value
          }
        }

        fragment ParentCategory on Category {
          id
          orderHint
          slug(acceptLanguage: $acceptLanguage)
          name(acceptLanguage: $acceptLanguage)
          key
          custom{
            ...HasProducts
          }
          ${siblingsQuery}
        }

        fragment Child on Category {
          id
          orderHint
          slug(acceptLanguage: $acceptLanguage)
          name(acceptLanguage: $acceptLanguage)
          custom{
            ...HasProducts
          }
        }

        fragment RelatedCategory on Category {
          name(acceptLanguage: $acceptLanguage)
          slug(acceptLanguage: $acceptLanguage)
          ancestors {
            key
            name(acceptLanguage: $acceptLanguage)
          }
          custom{
            ...HasProducts
          }
        }

        fragment RelatedCategoriesCustomField on RawCustomField {
          name,
          value
          referencedResourceSet {
            ...RelatedCategory
          }
        }

        query categories($where: String, $sort: [String!], $limit: Int, $offset: Int, $acceptLanguage: [Locale!]) {
          categories(where: $where, sort: $sort, limit: $limit, offset: $offset) {
            offset
            count
            total
            results {
              id
              orderHint
              slug(acceptLanguage: $acceptLanguage)
              name(acceptLanguage: $acceptLanguage)
              slugAllLocales{locale,value}
              metaTitle(acceptLanguage: $acceptLanguage)
              metaDescription(acceptLanguage: $acceptLanguage)
              custom{
                customFieldsRaw(includeNames:[
                    "${CATEGORY_CUSTOM_FIELDS.HAS_PRODUCTS}",
                    "${CATEGORY_CUSTOM_FIELDS.CATEGORY_CONTENT_HTML}",
                    "${CATEGORY_CUSTOM_FIELDS.CATEGORY_POSTS}"
                ]) {
                  name
                  value
                }
                ${relatedCategoriesQuery}
              }
              children {
                ...Child
              }
              ancestors {
                ...ParentCategory
              }
            }
          }
        }
      `,
      variables,
    };
  },
};
