const { gql } = require('graphql-tag');

const DefaultProductPrice = require('../fragments/defaultProductPrice').fragment;
const Images = require('../fragments/productVariant/images').fragment;
const Price = require('../fragments/productVariant/price').fragment;
const Attributes = require('../fragments/productVariant/attributes').fragment;
const Availability = require('../fragments/productVariant/availability').fragment;
const DefaultVariant = require('../fragments/productVariant/defaultVariant').fragment;
const SimplifiedVariant = require('../fragments/productVariant/simplifiedVariant').fragment;
const TaxCategories = require('../fragments/product/taxCategoies').fragment;
const ReviewStatistics = require('../fragments/product/reviewStatistics').fragment;
const { CUSTOM_QUERIES } = require('#~/constants/customQueries.js');

/*
 * Changes: added fetching category with ancestors and product availability
 */

module.exports = {
  [CUSTOM_QUERIES.PRODUCT_SEARCH.mainProductQuery.products]: (params) => {
    const variables = params.variables;
    return {
      query: gql`
           ${DefaultProductPrice}
           ${Images}
           ${Price}
           ${Attributes}
           ${DefaultVariant}
           ${SimplifiedVariant}
           ${TaxCategories}
           ${Availability}
           ${ReviewStatistics}

            query products(
              $where: String
              $limit: Int
              $offset: Int
              $skus: [String!]
              $locale: Locale!
              $acceptLanguage: [Locale!]
              $currency: Currency!
              $country: Country!
            ) {
              products(
                where: $where
                sort: "masterData.published desc"
                limit: $limit
                offset: $offset
                skus: $skus
              ) {
                offset
                count
                total
                results {
                  id
                  ...TaxCategories
                  ...ReviewStatistics
                  masterData {
                    published
                    current {
                      categories {
                        name(acceptLanguage: $acceptLanguage)
                        slug(acceptLanguage: $acceptLanguage)
                        ancestors {
                          name(acceptLanguage: $acceptLanguage)
                          slug(acceptLanguage: $acceptLanguage)
                          key
                        }
                      }
                      slug(acceptLanguage: $acceptLanguage)
                      slugAllLocales{locale,value}
                      name(acceptLanguage: $acceptLanguage)
                      metaTitle(acceptLanguage: $acceptLanguage)
                      metaKeywords(acceptLanguage: $acceptLanguage)
                      metaDescription(acceptLanguage: $acceptLanguage)
                      description(acceptLanguage: $acceptLanguage)
                      categoriesRef {
                        id
                      }
                      allVariants {
                        ...Availability
                        ...DefaultVariant
                      }
                      masterVariant {
                        ...SimplifiedVariant
                      }
                    }
                  }
                }
              }
            }
          `,
      variables,
    };
  },
};
