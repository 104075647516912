import { computed } from '@nuxtjs/composition-api';
import { useVSFContext } from '@vue-storefront/core';

type HasTranslationConfig = {
  checkFallbacks?: boolean;
};

export const useTranslationCheck = () => {
  const { i18n } = useVSFContext();

  const getFallbackLocale = (currentLocale: string, fallbackLocaleObject: Record<string, string[]>) => {
    for (const [locale, fallbacks] of Object.entries(fallbackLocaleObject)) {
      if (locale === currentLocale) {
        return fallbacks[0];
      }
    }
    return null;
  };

  const hasTranslation = (translationKey: string, config: HasTranslationConfig = { checkFallbacks: true }): boolean => {
    const fallbackLocale = getFallbackLocale(i18n.locale, i18n.fallbackLocale);

    const translationExists = i18n.te(translationKey);
    const translationValue = i18n.t(translationKey) || '';

    if (translationExists && translationValue.trim() !== '') {
      return true;
    }

    if (config.checkFallbacks) {
      const fallbackExists = i18n.te(translationKey, fallbackLocale);
      const fallbackValue = i18n.t(translationKey, fallbackLocale) || '';

      return fallbackExists && fallbackValue.trim() !== '';
    }

    return false;
  };

  return {
    hasTranslation,
    locale: computed(() => i18n.locale),
  };
};
