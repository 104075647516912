import { Gtm } from '~/types/@nuxtjs/gtm';
import { pushCleanTags } from '~/plugins/integrations/tagManager/helpers/pushCleanTags/pushCleanTags';
import { RecommendationsShownTags } from '~/plugins/integrations/tagManager/helpers/RecommendationsShownTags';
import { prepareProductTagsFromBloomreachProducts } from '~/plugins/integrations/tagManager/helpers/transformers';
import { BloomreachProduct } from '~/types/product/BloomreachProduct';
import { BloomreachTypeName } from '~/types/localization/localizationHelperTypes';

export const triggerRecommendationsShownTagsWrapper = ($gtm: Gtm) => {
  return (products: BloomreachProduct[], recommendationType?: BloomreachTypeName) => {
    const recommendationsShownTags = new RecommendationsShownTags(recommendationType);
    for (const product of products) {
      const productTags = prepareProductTagsFromBloomreachProducts(
        product,
      );
      recommendationsShownTags.assignProductValues(productTags);
    }

    pushCleanTags($gtm, recommendationsShownTags);
  };
};
