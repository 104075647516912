const localizationDefinitions = require('../../../i18n/localizationDefinitions.ts').default;

/**
 * @param {string} [url]
 * @returns Maybe<string>
 */
const getPrefix = (url) => {
  const secondElementFromRoute = url?.split('/')?.[1];
  if (isPrefix(secondElementFromRoute)) {
    return secondElementFromRoute;
  }
};

/**
 * @param {string} testedString
 * @returns boolean
 */
const isPrefix = (testedString) => {
  return localizationDefinitions.getAllLanguages().includes(testedString);
};

/**
 * @param {string} path
 * @returns boolean
 */
const hasPrefix = (path) => {
  const prefix = getPrefix(path);
  return !!prefix && path.includes(prefix);
};

/**
 * @param {Maybe<string>} slug
 * @param {Maybe<string>} languagePrefix
 * @returns {Maybe<string>}
 */
const getPrefixedSlug = (slug, languagePrefix) => {
  if (languagePrefix && slug && slug[0] === '/' && !hasPrefix(slug)) {
    slug = `/${languagePrefix}${slug}`;
  }
  return slug;
};

const getSlugWithoutPrefix = (slug) => {
  const prefix = getPrefix(slug);
  if (prefix) {
    slug = slug.replace(`/${prefix}`, '');
  }
  return slug;
};

module.exports = {
  getPrefixedSlug,
  getPrefix,
  hasPrefix,
  isPrefix,
  getSlugWithoutPrefix,
};
