import type { Category } from '@vsf-enterprise/commercetools-types';
import { convertCommerceToolsCategories } from '~/helpers/algolia/convertCommerceToolsCategories';
import { extractChildCategories } from '~/helpers/category/categoriesTree';
import getBreadcrumbsList from '~/helpers/category/getBreadcrumbsList';
import type { CategoryTree } from '~/constants/categoryTree';

export const getAlgoliaCategoryFilter = ({ categorySlug, categories, categoryTree }:
  { categorySlug: string, categories: Category[], categoryTree: CategoryTree },
): string => {
  const childCategories = extractChildCategories(categories) || undefined;
  const breadcrumbsList = getBreadcrumbsList(
    childCategories,
    categorySlug,
  );
  return convertCommerceToolsCategories(breadcrumbsList, categoryTree);
};
