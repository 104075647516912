import { sharedRef } from '@vue-storefront/core';
import type { Ref } from '@nuxtjs/composition-api';

const useLoading = (cachingKey: string, isLoading = false) => {
  const loading: Ref<boolean> = sharedRef(isLoading, cachingKey);
  const setLoading = (value: boolean) => {
    loading.value = value;
  };

  return {
    loading,
    setLoading,
  };
};
export default useLoading;
