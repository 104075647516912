import { Logger } from '@vue-storefront/core';
import { ref } from '@nuxtjs/composition-api';
import { AxiosError } from 'axios';
import { GrahamProductReview } from '~/types/product/attribute/GrahamProductReview';
import { ProductReviewError } from '~/types/product/attribute/ProductReview';
import { EXPONDO_MIDDLEWARE_ROUTES } from '~/constants/routes';
import { errorToString } from '~/helpers/error/errorToString';
import { useExpondoMiddleware } from '~/composables';
import { AllowedMethods } from '~/types/expondoMiddleware';

const errorCallback = (error: AxiosError) => {
  if (error) {
    const communicate = error.response?.data?.communicate;
    if (
      communicate &&
      Object.values(ProductReviewError).includes(communicate)
    ) {
      throw new Error(communicate);
    } else {
      throw new Error('Something went wrong on our side. Please try again or contact support');
    }
  }
};

export const useManageReviews = () => {
  const errorMessage = ref('');
  const isReviewAdded = ref<Boolean | undefined>(undefined);
  const { makeMiddlewareRequest } = useExpondoMiddleware();

  const addReview = async (productReview: GrahamProductReview) => {
    isReviewAdded.value = false;
    try {
      await makeMiddlewareRequest({
        endpoint: EXPONDO_MIDDLEWARE_ROUTES.ADD_REVIEW,
        method: AllowedMethods.post,
        data: productReview,
        catchCallback: errorCallback,
      });
      isReviewAdded.value = true;
    } catch (error: unknown) {
      const errorString = errorToString(error);
      errorMessage.value = errorString;
      Logger.error('Error during adding new review: ' + errorString);
    }
  };

  return {
    addReview,
    errorMessage,
    isReviewAdded,
  };
};
