const { gql } = require('graphql-tag');

module.exports = {
  fragment: gql`
    fragment TaxCategories on Product {
      taxCategory {
        rates {
          amount
          country
        }
      }
    }
  `,
};
