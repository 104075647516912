enum countryCodes {
  IE = 'ie',
  SK = 'sk',
  DE = 'de',
  PT = 'pt',
  IT = 'it',
  FR = 'fr',
  HU = 'hu',
  CZ = 'cz',
  AT = 'at',
  NO = 'no',
  NL = 'nl',
  CH = 'ch',
  BE = 'be',
  DK = 'dk',
  SE = 'se',
  ES = 'es',
  GB = 'gb',
  FI = 'fi',
  PL = 'pl',
  RO = 'ro',
  LT = 'lt',
  SI = 'si',
  BG = 'bg',
  GR = 'gr',
  HR = 'hr'
}

const defaultCountryCode = countryCodes.SK;

export {
  countryCodes,
  defaultCountryCode,
};
