export const PAGINATION_ACTIONS = {
  PREV: 'prev',
  NEXT: 'next',
  FIRST: 'first',
  LAST: 'last',
  PAGE: 'page',
} as const;

export type PaginationActions = ValueOf<typeof PAGINATION_ACTIONS>;

export const RATING_REVIEWS_SECTION_SELECTOR = '#rating-reviews';
export const REVIEW_ID = 'review';

export const PAGINATION_PAGES_TO_SHOW = 5;
export const PAGINATION_DEFAULT_RESULTS_LIMIT = 10;
export const DEFAULT_REVIEWS_SEARCH_PARAMS = { limit: PAGINATION_DEFAULT_RESULTS_LIMIT, offset: 0 };
