import type { Cart, LineItem, Order } from '@vsf-enterprise/commercetools-types';
import { AgnosticPrice } from '@vue-storefront/core';
import { cartGetters } from '@vsf-enterprise/commercetools';
import { centAmountToNormalPrice, getTotalGrossPrice } from '~/helpers/cart/getTotalPricesModule';
import moneyToNumber from '~/helpers/commercetools/moneyToNumber';
import type { LineItemExtended } from '~/types/product/LineItemExtended';

export const getTotalNetPrice = (cartOrOrder: Cart | Order): number => {
  return centAmountToNormalPrice(cartOrOrder?.taxedPrice?.totalNet?.centAmount);
};

export const getTotalTaxPrice = (cartOrOrder: Cart | Order): number => {
  return getTotalGrossPrice(cartOrOrder) - getTotalNetPrice(cartOrOrder);
};

export const getTotalGrossPriceBeforeDiscount = (product: LineItem) => {
  return centAmountToNormalPrice(product?.price?.value?.centAmount);
};

export const getShippingPrice = (cartOrOrder: Cart | Order): number => {
  return centAmountToNormalPrice(cartOrOrder?.shippingInfo?.taxedPrice?.totalGross?.centAmount);
};

export function getTotalPriceForItem(product: LineItemExtended) {
  if (!product.priceBeforeDiscounts || !product.qty) return 0;
  return Math.round(product.priceBeforeDiscounts * product.qty * 100) / 100;
}

const getDiscount = (product: LineItem): LineItem['price']['discounted'] => {
  return product?.price?.discounted;
};

function getSpecialPrice(product: LineItem) {
  const discount = getDiscount(product);

  const { discountedPricePerQuantity } = product;
  const discountsLength = discountedPricePerQuantity.length;
  if (discountsLength > 0) {
    return centAmountToNormalPrice(discountedPricePerQuantity[discountsLength - 1]?.discountedPrice?.value.centAmount);
  }
  if (discount?.discount?.isActive) {
    return centAmountToNormalPrice(discount.value.centAmount);
  }
  return null;
}

export const getItemPrice = (product: LineItem): AgnosticPrice => {
  if (!product) {
    return { regular: null, special: null };
  }
  const quantity = cartGetters.getItemQty(product);
  const regularPrice = centAmountToNormalPrice(product.taxedPrice?.totalGross?.centAmount);
  const specialPriceSingleItem = getSpecialPrice(product);
  const specialPrice = specialPriceSingleItem ? specialPriceSingleItem * quantity : specialPriceSingleItem;
  return {
    regular: regularPrice,
    special: specialPrice,
  };
};

export const getTaxPortions = (cartOrOrder: Cart | Order): {percentage: number, value: number} => {
  const taxes = cartOrOrder?.taxedPrice?.taxPortions;

  if (!taxes?.length) {
    return {
      percentage: 0,
      value: 0,
    };
  }

  const tax = taxes[0];

  return {
    percentage: tax.rate * 100,
    value: moneyToNumber(tax.amount),
  };
};
