const { STANDARD_PRODUCT } = require('../../constants/productType');
const { getTotalGrossPrice } = require('../cart/getTotalPricesModule');
const config = require('../../config');
const { PRODUCT_ATTRIBUTES } = require('../../constants/products');
const { isEligibleForCashAndBank } = require('./cashBankPaymentEligibility');

/**
 * @param {string} country
 * @returns {number}
 */
const cashPriceLimit = (country) => {
  return config.CASH_ON_DELIVERY_PRICE_LIMIT[country];
};

/**
 * @param {string} country
 * @returns {number}
 */
const cashItemLimit = (country) => {
  return config.CASH_ON_DELIVERY_ITEM_LIMIT[country];
};

/**
 * @typedef {import('@vsf-enterprise/commercetools-types').Cart} Cart
 * @param {Cart} cart
 * @param {string} country
 * @returns {boolean}
 */
const isEligibleForCashPayment = (cart, country) => {
  const totalGrossPrice = getTotalGrossPrice(cart);

  const allowedByPriceLimit = totalGrossPrice <= cashPriceLimit(country);
  const standardItemsQuantity = cart?.lineItems?.reduce((counter, lineItem) =>
    counter + (lineItem.productType?.key === STANDARD_PRODUCT ? lineItem.quantity : 0), 0);
  const allowedByNumberOfLineItems = standardItemsQuantity <= cashItemLimit(country);
  const hasFreight = doesCartContainsFreight(cart);
  const freightCashOnDeliveryAllowedForCountry = config.CASH_ON_DELIVERY_FOR_FREIGHT[country];

  return allowedByPriceLimit &&
  allowedByNumberOfLineItems &&
  (freightCashOnDeliveryAllowedForCountry || !hasFreight) &&
  isEligibleForCashAndBank(cart);
};

/**
 * @typedef {import('@vsf-enterprise/commercetools-types').Cart} Cart
 * @param {Cart} cart
 * @returns {boolean}
 */
const doesCartContainsFreight = (cart) => {
  return !!cart?.lineItems.find(
    (lineItem) => lineItem
      ?.variant
      ?.attributesRaw
      .find((rawAttribute) => rawAttribute.name === PRODUCT_ATTRIBUTES.IS_PACKAGE_TYPE_FREIGHT)?.value,
  );
};

module.exports = {
  cashPriceLimit,
  cashItemLimit,
  isEligibleForCashPayment,
};
