
import { ShippingFeeThreshold } from '~/types/product/ShippingFeesObject';
import { ShippingMethodDeliveryInformation, ZoneRate } from '~/types/checkout/ShippingMethod';
import { centAmountToNormalPrice } from '~/helpers/cart/getTotalPricesModule';
import { SHIPPING_TYPE } from '~/constants/shippingTypes';

export function getProductShippingMethod(
  methodsWithoutId: ShippingMethodDeliveryInformation[],
  isFreightFeeApplied?: boolean,
): ShippingMethodDeliveryInformation | undefined {
  const productShippingMethod = methodsWithoutId?.find((method: ShippingMethodDeliveryInformation) =>
    method.key === (isFreightFeeApplied ? SHIPPING_TYPE.FREIGHT : SHIPPING_TYPE.PARCEL));
  return productShippingMethod;
}

export function getShippingRates(
  countryCode: string,
  productShippingMethod?: ShippingMethodDeliveryInformation,
): ZoneRate | undefined {
  const zoneRates = productShippingMethod?.zoneRates;
  const matchingZoneRate = zoneRates?.find((zoneRate: ZoneRate) => {
    return zoneRate?.zone?.key?.toLowerCase() === countryCode;
  });
  return matchingZoneRate;
}

export function getShippingFeeDetails(
  zoneRate?: ZoneRate,
): ShippingFeeThreshold {
  const shippingRate = zoneRate?.shippingRates?.[0];
  return {
    shippingFee: centAmountToNormalPrice(shippingRate?.price.centAmount),
    thresholdForFreeShipping: centAmountToNormalPrice(shippingRate?.freeAbove.centAmount),
  };
}

export function isParcelFeeApplying(shippingFeeDetails: ShippingFeeThreshold, price: number | null): boolean {
  const freeShippingThreshold = shippingFeeDetails?.thresholdForFreeShipping;
  return freeShippingThreshold
    ? (!!price && price < freeShippingThreshold)
    : false;
}
