






















































import { computed, defineComponent, ref } from '@nuxtjs/composition-api';
import { useVSFContext } from '@vue-storefront/core';
import { useStoreVsfExtended, useVatValidation, useI18n } from '~/composables';
import Button from '~/components/atoms/Buttons/Button/Button.vue';
import formatVatNumberForLocale from '~/helpers/formatVatNumberForLocale';
import { VAT_FORMAT } from '~/config/shared/countryConfig';
import VatIdNeedSupport from '~/components/atoms/Inputs/VatIdInput/VatIdNeedSupport.vue';
import ValidatedInput from '~/components/atoms/Inputs/ValidatedInput/ValidatedInput.vue';
import VatValidationModalInfo from '~/components/atoms/VatValidation/VatValidationModalInfo.vue';

const DataMessageType = {
  success: 'vat_val_success',
  failed: 'vat_val_failed',
  failedNoRate: 'vat_val_failed_norate',
  triggered: 'vat_val_triggered',
  buttonClick: 'vat_val_button_click',
  timeout: 'vat_val_timeout',
} as const;

export default defineComponent({
  name: 'VatIdInput',
  components: {
    ValidatedInput,
    Button,
    VatIdNeedSupport,
    VatValidationModalInfo,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    vatNumber: {
      type: String,
      default: '',
    },
    shouldValidateVat: {
      type: Boolean,
      default: true,
    },
    showValidationSupport: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['input'],
  setup(props, { emit }) {
    const { i18n } = useVSFContext();
    const { countryCode } = useI18n();
    const { isChangeTaxRateEnabled } = useStoreVsfExtended();
    const isVatValidationModalOpen = ref<boolean>(false);
    const openVatValidationModal = () => {
      isVatValidationModalOpen.value = true;
    };
    const closeVatValidationModal = () => {
      isVatValidationModalOpen.value = false;
    };
    const {
      messages: validationMessages,
      isValid,
      isErrorCouldNotValidate,
      loading,
      isErrorNotValid,
      isNetPriceActive,
      vatPrefix,
      validateVatNumber,
    } = useVatValidation(isChangeTaxRateEnabled);

    const processedVatNumber = computed(() => formatVatNumberForLocale(countryCode.value, props.vatNumber));

    const successMessage = computed(() =>
      isValid.value ? i18n.t('VAT ID successfully validated.') : '');
    const inputLoading = computed(() =>
      loading.value && !isErrorCouldNotValidate.value);
    const buttonLoading = computed(() =>
      loading.value && isErrorCouldNotValidate.value);

    const dataMessage = computed(() => {
      if (inputLoading.value) {
        return DataMessageType.triggered;
      }
      if (buttonLoading.value) {
        return DataMessageType.buttonClick;
      }
      if (isValid.value) {
        if (isChangeTaxRateEnabled.value && !isNetPriceActive.value) {
          return DataMessageType.failedNoRate;
        }
        return DataMessageType.success;
      }
      if (isErrorCouldNotValidate.value) {
        return DataMessageType.timeout;
      }
      if (isErrorNotValid.value) {
        return DataMessageType.failed;
      }
    });

    const handleVatInput = (vatInput: string) => {
      emit('input', vatInput);
    };
    const onValidate = async (passed: boolean, isVatValButton = false) => {
      if (!passed || !props.shouldValidateVat) {
        return;
      }
      await validateVatNumber(processedVatNumber.value, isVatValButton);
      handleVatInput(processedVatNumber.value);
    };

    const label = computed(() => `${VAT_FORMAT[countryCode.value]?.placeholder}`);

    return {
      onValidate,
      dataMessage,
      buttonLoading,
      inputLoading,
      isErrorCouldNotValidate,
      successMessage,
      validationMessages,
      processedVatNumber,
      handleVatInput,
      label,
      vatPrefix,
      isErrorNotValid,
      isVatValidationModalOpen,
      closeVatValidationModal,
      openVatValidationModal,
    };
  },
});
