import { ClearAllFiltersParameters } from '~/plugins/integrations/tagManager/helpers/ClearAllFiltersParameters';
import { Gtm } from '~/types/@nuxtjs/gtm';
import { TriggerClearAllFiltersParameters } from '~/types/integrations/tagManager/TagManagerIntegration';

export const triggerClearAllFiltersParametersWrapper = ($gtm: Gtm) => {
  return (parameters: TriggerClearAllFiltersParameters) => {
    const clearAllFiltersParameters = new ClearAllFiltersParameters({
      categoryPath: parameters.categoryPath,
      categoryName: parameters.categoryName,
      listingType: parameters.listingType,
    });
    $gtm.push(clearAllFiltersParameters);
  };
};
