module.exports = {
  MAX_SINGLE_PRODUCT_CART_QUANTITY: 1000,
  GRAHAM_REVIEW_ENDPOINT: 'https://expondo-graham.azurewebsites.net/api/review',
  IMAGES_360_URL: 'https://assets.expondo.com/asset/360_photos',
  typesenseHost: 'l132pzkshfg69an4p.a1.typesense.net',
  BLOOMREACH_API: {},
  SALESFORCE_WEBFORM: {
    SUBMIT_URL: 'https://webto.salesforce.com/servlet/servlet.WebToCase',
    ORGANISATION_ID: '00D07000000ZaUD',
    FIELD_ID: {
      ORGANISATION_ID: 'orgid',
      RETURN_URL: 'retURL',
      DEBUG_ACTIVE: 'debug',
      DEBUG_EMAIL: 'debugEmail',
      NAME: 'name',
      COMPANY: 'company',
      EMAIL: 'email',
      CASE_REASON: 'reason',
      CASE_SUBREASON: '00NJ6000000dbom',
      SUBJECT: 'subject',
      DESCRIPTION: 'description',
      PRODUCT_NUMBER: '00NJ6000000dbo3',
      ORDER_NUMBER: '00NJ6000000dbrF',
      INVOICE_NUMBER: '00NJ6000000dbrE',
      RECORD_TYPE: 'recordType',
      COUNTRY: '00NJ6000000dbrR',
      LOCALE: '00NKG000000h2eY',
      VAT_ID: '00NdV0000002yHp',
      QUANTITY: '00NdV0000002yJR',
      PHONE_NUMBER: 'phone',
    },
  },
};
