const { gql } = require('graphql-tag');
module.exports = {
  fragment: gql`
    fragment DefaultCustomLineItem on CustomLineItem {
      id
      name(acceptLanguage: $acceptLanguage)
      slug
      quantity,
      totalPrice {
        centAmount
      }
    }
  `,
};
