import { computed } from '@nuxtjs/composition-api';
import { ProductVariant } from '~/utils/ct/types/product/ProductVariant';
import { useExtraGuarantee, useI18n, useProductsExtraGuarantee } from '~/composables';
import { EXTRA_GUARANTEE_ATTRIBUTES } from '~/constants/extraGuarantee';

export const useExtraGuaranteeOptions = () => {
  const { productsExtraGuarantee } = useProductsExtraGuarantee();
  const { languageAndCountry, currencyCode } = useI18n();
  const { productPriceForExtraGuarantee } = useExtraGuarantee();

  type ProductVariantWithName = ProductVariant & { _name?: string };

  const formatPrice = (productPrice: number, priceRate: number) => {
    const rate = priceRate / 100;
    const extraGuaranteePrice = productPrice * rate;
    const formattedPrice = extraGuaranteePrice.toLocaleString(languageAndCountry.value, {
      style: 'currency',
      currency: currencyCode.value,
      maximumFractionDigits: 2,
    });
    return formattedPrice;
  };

  const guaranteeOptions = computed(() => {
    const productPrice = productPriceForExtraGuarantee.value;
    if (!productPrice) {
      return [];
    }
    return productsExtraGuarantee.value?.map((el: ProductVariantWithName) => {
      const enabledOnMarket = el.attributesRaw.find(attr =>
        attr.name === EXTRA_GUARANTEE_ATTRIBUTES.ENABLED_ON_MARKET);
      const priceRate = el.attributesRaw.find(attr =>
        attr.name === EXTRA_GUARANTEE_ATTRIBUTES.EXTRA_GUARANTEE_RATE);
      return {
        name: el._name,
        priceRate: priceRate?.value,
        enabledOnMarket: enabledOnMarket?.value,
        price: formatPrice(productPrice, priceRate?.value),
        sku: el.sku,
      };
    }) ?? [];
  });

  const hasOnlyOneOption = computed(() => guaranteeOptions.value?.length === 1);

  return {
    guaranteeOptions,
    hasOnlyOneOption,
  };
};
