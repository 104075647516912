import { computed } from '@nuxtjs/composition-api';
import { useVSFContext } from '@vue-storefront/core';
import { useCartExtended, useUserOrder } from '~/composables';
import { getShippingPrice } from '~/helpers/cart/getTotalPrices';

export const useShippingCost = () => {
  const { cart } = useCartExtended();
  const { order } = useUserOrder();
  const { i18n } = useVSFContext();

  const shippingCost = computed(
    () => {
      return getShippingPrice(cart.value || order.value);
    });

  const shippingCostLabel = computed(() => shippingCost.value
    ? i18n.n(
      shippingCost.value,
      'currency',
    )
    : i18n.t('Free'),
  );

  return {
    shippingCostLabel,
    shippingCost,
  };
};
