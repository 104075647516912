import { Logger, sharedRef, useVSFContext } from '@vue-storefront/core';
import type { Category } from '@vsf-enterprise/commercetools-types';
import { CATEGORY_TREE, CategoryTree } from '~/constants/categoryTree';
import { CUSTOM_QUERIES } from '~/constants/customQueries';
import { findCategoryTreeByKey } from '~/helpers/category/categoriesTree';

export const useCategoryTree = () => {
  const { $ct } = useVSFContext();

  const categoryTree = sharedRef<CategoryTree>(CATEGORY_TREE.DEFAULT, 'categoryTree');

  const load = async (slug: string, acceptLanguage: string) => {
    try {
      const categoryResponse = await $ct.api.getCategory(
        { slug, acceptLanguage },
        { categories: CUSTOM_QUERIES.CATEGORIES.TREE },
      );

      const currentCategory: Category = categoryResponse?.data?.categories?.results?.[0];
      const rootCategoryKey = currentCategory?.ancestors?.[0]?.key;
      const rootCategoryTree = findCategoryTreeByKey(rootCategoryKey);
      if (rootCategoryTree) {
        categoryTree.value = rootCategoryTree;
      }
    } catch (err) {
      Logger.error('error.useCategoryTree', String(err));
    }
  };

  return {
    categoryTree,
    load,
  };
};
