import { onUnmounted } from '@nuxtjs/composition-api';
import type { Ref } from '@nuxtjs/composition-api';
import { HINT_HIDING_TIMEOUT } from '~/constants/timeUnits';

export function useHint() {
  let timeoutId: ReturnType<typeof setTimeout> | null = null;

  const toggleHintVisibilityComputed = (computedVisibilityFlag: Ref<boolean>): void => {
    computedVisibilityFlag.value = !computedVisibilityFlag.value;
    if (computedVisibilityFlag.value) {
      timeoutId = setTimeout(() => {
        computedVisibilityFlag.value = false;
      }, HINT_HIDING_TIMEOUT);
    }
  };

  onUnmounted(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  });

  return {
    toggleHintVisibilityComputed,
  };
}
