import { useProduct } from '@vsf-enterprise/commercetools';
import { computed } from '@nuxtjs/composition-api';
import { useI18n, usePrefix } from '~/composables';
import { getProductSearchParams } from '~/helpers/product/getProductSearchParams';
import { CUSTOM_QUERIES } from '~/constants/customQueries';
import type { ProductVariantGql } from '~/types/product/ProductVariantGql';
import extendProducts from '~/helpers/product/extendProducts';
import {
  getProductAttributesExtended,
} from '~/helpers/product/getProductAttributesExtended';
import { PRODUCT_ATTRIBUTES } from '~/constants/products';
import type { ProductsSearchParams } from '~/utils/ct';

export default function () {
  const { languageAndCountry, countryCode } = useI18n();
  const { languagePrefix } = usePrefix();
  const {
    products: oldProducts,
    search: oldSearch,
  } = useProduct<ProductVariantGql>('listing-product');

  const products = computed<ProductVariantGql[]>(() =>
    oldProducts.value?.data?.products?.results || [],
  );

  const masterProduct = computed(() => {
    return extendProducts(products.value, languageAndCountry.value, countryCode.value, { isPublished: true })?.[0];
  });

  const masterProductLocalisedAttributes = computed(() => {
    if (!masterProduct.value) {
      return {};
    }
    return getProductAttributesExtended(
      masterProduct.value,
      {
        attributeNames: [PRODUCT_ATTRIBUTES.HIGHLIGHTS],
        languageAndCountry: languageAndCountry.value,
      },
    );
  });

  const search = async (searchParams: ProductsSearchParams) => {
    const productSearchParams = getProductSearchParams(
      searchParams,
      { languageAndCountry: languageAndCountry.value, languagePrefix },
    );
    await oldSearch({
      ...productSearchParams,
      customQuery: CUSTOM_QUERIES.PRODUCT_SEARCH.productListingQuery,
    });
  };
  return {
    masterProduct,
    masterProductLocalisedAttributes,
    search,
  };
}
