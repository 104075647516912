import { resolveMiddlewareUrl } from './resolveMiddlewareUrl';
import { EXPONDO_MIDDLEWARE_PORT, MAIN_APP_PORT, MIDDLEWARE_PORT } from '~/constants/http';
import { MiddlewareUrlParams } from '~/types/global';
import { isProduction } from '~/helpers/env/checkEnvironmentType';

export const getMiddlewareUrl = ({ $config }: MiddlewareUrlParams) => {
  const resolvedUrl = resolveMiddlewareUrl({
    middlewareUrl: $config?.middlewareUrl,
    ssrMiddlewareUrl: $config?.ssrMiddlewareUrl,
    port: isProduction() ? MIDDLEWARE_PORT : MAIN_APP_PORT,
  });
  return resolvedUrl;
};

export const getExpondoMiddlewareUrl = ({ $config }: MiddlewareUrlParams) => {
  const resolvedUrl = resolveMiddlewareUrl({
    middlewareUrl: process?.server ? '/' : $config?.expondoMiddlewareUrl,
    ssrMiddlewareUrl: $config?.expondoSsrMiddlewareUrl,
    port: EXPONDO_MIDDLEWARE_PORT,
  });
  return resolvedUrl;
};
