import { useVSFContext } from '@vue-storefront/core';

export const useProductAvailabilityDate = (dateStr: string | number | Date) => {
  const { i18n } = useVSFContext();

  const calculateAvailabilityDate = (): string => {
    const date = new Date(dateStr);

    if (isNaN(date.getTime())) {
      return '';
    }

    const monthsAhead = 3;
    const monthLanguage = 'en-US';

    const newDate = new Date(date);
    newDate.setMonth(date.getMonth() + monthsAhead);
    const options: Intl.DateTimeFormatOptions = { month: 'long' };
    const month = newDate.toLocaleDateString(monthLanguage, options);
    const year = newDate.getFullYear();

    const translationKey = `Buy in ${month}`;
    return i18n.t(translationKey, { year });
  };

  return {
    calculateAvailabilityDate,
  };
};
