import { getSlugForRoute } from '~/helpers/routes/getPageInformation';
import { getPrefixedSlug } from '~/helpers/routes/prefixes';
import { ROUTES } from '~/constants/routes';
import { useI18n } from '~/composables';

export default function () {
  const { countryLocalization } = useI18n();

  const changeLanguage = (languageAndCountry: string) => {
    const homePageSlug = getSlugForRoute(ROUTES.HOME);
    const isTargetDefault = countryLocalization.value.isDefaultLanguageAndCountry(languageAndCountry);
    const targetLanguage = countryLocalization.value.getLanguageFromExistingLanguageAndCountry(languageAndCountry);
    const newLocation = isTargetDefault ? homePageSlug : getPrefixedSlug(homePageSlug, targetLanguage);
    window.location.replace(newLocation as string);
  };

  return {
    changeLanguage,
  };
}
