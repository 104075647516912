const { gql } = require('graphql-tag');

module.exports = {
  fragment: gql`
    fragment Images on ProductVariant {
      images {
        url
        label
      }
    }
  `,
};
