export const useIntersectionObserverEvent = ({
  onIntersectCallback,
}: {
  onIntersectCallback: (entry: IntersectionObserverEntry, observer: IntersectionObserver) => void;
}) => {
  const onIntersectOnce = (entries: IntersectionObserverEntry[], observer: IntersectionObserver): void => {
    const entry = entries[0];
    if (entry && entry.isIntersecting) {
      onIntersectCallback(entry, observer);
      observer.unobserve(entry.target);
    }
  };

  return {
    onIntersectOnce,
  };
};
