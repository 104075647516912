import { Logger } from '@vue-storefront/core';
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { getHostname } from '~/helpers/host/getHostname';

interface InterceptorsOptions {
  axios: AxiosInstance;
  req?: Context['req'];
  shouldRejectPromise?: boolean;
  shouldLogError?: boolean;
}

const successFunction = (response: AxiosResponse) => response;

const errorFunction = (
  { error, shouldRejectPromise, shouldLogError }:
  { error: AxiosError; shouldRejectPromise: boolean; shouldLogError: boolean },
) => {
  if (shouldLogError) {
    Logger.error(error);
  }
  if (shouldRejectPromise) {
    return Promise.reject(error);
  }
  return Promise.resolve();
};

export const setAxiosInterceptors =
  ({ axios, req, shouldRejectPromise = true, shouldLogError = true }: InterceptorsOptions) => {
    axios.interceptors.request.use(
      (config) => {
        const hostname = getHostname(req);
        if (hostname) {
          config.headers['x-forwarded-host'] = hostname;
        }
        return config;
      },
      (error) => errorFunction({
        error,
        shouldRejectPromise: true,
        shouldLogError: true,
      }),
    );
    axios.interceptors.response.use(
      successFunction,
      (error) => errorFunction({
        error,
        shouldRejectPromise,
        shouldLogError,
      }),
    );
  };
