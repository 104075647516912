import { extendModule } from '~/helpers/vuex/extendModule/extendModule';
import type { NavigationState } from '~/types/vuex/Navigation';

const state = () => ({
  isNavigationOpen: false,
  currentFirstLevelCategorySlug: '',
});

const mutations = {
  toggleNavigation(state: NavigationState, payload: boolean) {
    state.isNavigationOpen = payload;
  },
  setCurrentFirstLevelCategorySlug(state: NavigationState, payload: string) {
    state.currentFirstLevelCategorySlug = payload;
  },
};

export default extendModule({
  state, mutations,
});
