import { computed, watch } from '@nuxtjs/composition-api';
import { defineModule } from '~/helpers/vuex/defineModule/defineModule';
import {
  ClientEmailMutations,
} from '~/types/vuex/ClientEmail';
import { MODULE_NAMES } from '~/types/vuex/Vuex';
import { useCartExtended } from '~/composables';

export default function () {
  const store = defineModule<
    typeof MODULE_NAMES.CLIENT_EMAIL_MODULE_NAME,
    ClientEmailMutations,
    {},
    {}
  >(MODULE_NAMES.CLIENT_EMAIL_MODULE_NAME);

  const { cart } = useCartExtended();

  const customerEmailFromCart = computed(() => cart.value?.customerEmail);

  const clientEmail = computed(() => store.state.clientEmail);

  const setClientEmail = (email: string) => {
    store.mutations.setClientEmail(email);
  };

  watch(() => customerEmailFromCart.value, (customerEmail) => {
    if (!clientEmail.value && customerEmail) {
      setClientEmail(customerEmail);
    }
  }, { immediate: true });

  return {
    clientEmail,
    ...store.getters,
    ...store.mutations,
    ...store.actions,
    setClientEmail,
  };
}
