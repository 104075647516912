/* eslint-disable no-unused-vars */
export const DiscountType = {
  Relative: 'relative',
  Absolute: 'absolute',
  Fixed: 'fixed',
  GiftLineItem: 'giftLineItem',
  Shipping: 'shipping',
} as const;

// eslint-disable-next-line no-redeclare
export type DiscountType = ValueOf<typeof DiscountType>

export type DiscountTarget =
  | 'lineItems'
  | 'multiBuyLineItems'
  | 'customLineItems'
  | 'multiBuyCustomLineItems'
  | 'shipping'
  | null;

export interface MappedDiscount {
  id: string;
  name: string;
  type: DiscountType;
  target: DiscountTarget;
  value: number;
  code?: string;
  discountedAmount: number;
  requiresDiscountCode: boolean;
}
