const { gql } = require('graphql-tag');

const { CUSTOM_QUERIES } = require('#~/constants/customQueries.js');

module.exports = {
  [CUSTOM_QUERIES.REVIEWS.reviews]: (params) => {
    const variables = params.variables;
    return {
      query: gql`
        query reviews(
          $where: String!,
          $limit: Int,
          $offset: Int,
          $sort: [String!]
        ) {
          reviews(
            where: $where,
            limit: $limit,
            offset: $offset,
            sort: $sort
          ) {
            offset,
            count,
            total,
            results {
              title,
              id,
              text,
              authorName,
              rating,
              createdAt,
              locale
              custom {
                reviewCreatedAt: customFieldsRaw(includeNames:"reviewCreatedAt") {
                  name
                  value
                }
                customerCareResponseText: customFieldsRaw(includeNames:"customerCareResponseText") {
                  name
                  value
                }
                reviewSource: customFieldsRaw(includeNames:"source") {
                  name
                  value
                }
                titleTranslations: customFieldsRaw(includeNames:"titleTranslations") {
                  name
                  value
                }
                textTranslations: customFieldsRaw(includeNames:"textTranslations") {
                  name
                  value
                }
                answerTranslations: customFieldsRaw(includeNames: "answerTranslations") {
                  name
                  value
                }
              }
            }
          }
        }
      `,
      variables,
    };
  },
};
