




















































































































import {
  defineComponent,
  ref,
  onMounted,
  watch,
  computed,
  onUnmounted,
} from '@nuxtjs/composition-api';
import { useIntersectionObserver } from '@vueuse/core';
import { SimpleCarouselArrow } from '~/types/components/SimpleCarousel/SimpleCarouselArrows';
import SimpleCarouselButton from '~/components/molecules/SimpleCarousel/SimpleCarouselButton.vue';
import SimpleCarouselItem from '~/components/molecules/SimpleCarousel/SimpleCarouselItem.vue';
import SimpleCarouselDotNavigation from '~/components/molecules/SimpleCarousel/SimpleCarouselDotNavigation.vue';
import { ROOT_MARGIN, THRESHOLD } from '~/constants/simpleCarousel';

export default defineComponent({
  name: 'SimpleCarousel',
  components: {
    SimpleCarouselButton,
    SimpleCarouselItem,
    SimpleCarouselDotNavigation,
  },
  props: {
    perPage: {
      type: Number,
      default: 1,
    },
    activeIndex: {
      type: Number,
      default: 0,
    },
    height: {
      type: [Number, String],
      default: 'auto',
    },
    shouldScrollToSlideOnOpen: {
      type: Boolean,
      default: false,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    wrapperClasses: {
      type: String,
      default: '',
    },
    gap: {
      type: Number,
      default: 0,
    },
    arrows: {
      type: Boolean,
      default: false,
    },
    hideArrowsOnBoundaries: {
      type: Boolean,
      default: false,
    },
    sliderItems: {
      type: Array,
      default: () => [],
    },
    hasCustomFirstItem: {
      type: Boolean,
      default: false,
    },
    hasCustomLastItem: {
      type: Boolean,
      default: false,
    },
    slideClass: {
      type: String,
      default: '',
    },
    slidesAutoWidth: {
      type: Boolean,
      default: false,
    },
    fixedSlideWidthValue: {
      type: Number,
      default: 0,
    },
    hasControlsOutsideContainer: {
      type: Boolean,
      default: false,
    },
    prevArrowClass: {
      type: String,
      default: 'left-0',
    },
    nextArrowClass: {
      type: String,
      default: 'right-0',
    },
    showPaginationControls: {
      type: Boolean,
      default: false,
    },
    hasCustomGap: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const carouselHeight = computed(() => {
      if (typeof props.height === 'number') {
        return `${props.height}px`;
      } else {
        return props.height;
      }
    });
    const controlClasses = 'absolute z-1 top-carouselControl';
    const carouselContainer = ref<HTMLDivElement | null>(null);

    const currentSlide = ref(0);
    const track = ref<HTMLDivElement | null>(null);
    const slideWidths = ref<number[]>([]);
    const slideRefs = ref([]);
    const customFirstElement = ref<HTMLDivElement | null>(null);
    const customLastElement = ref<HTMLDivElement | null>(null);

    const trackStyle = computed(() => {
      if (props.fixedSlideWidthValue) {
        const offset = currentSlide.value * (props.fixedSlideWidthValue + props.gap);
        return {
          left: `-${offset}px`,
          gap: `${props.gap}px`,
        };
      } else if (props.slidesAutoWidth) {
        const offset = slideWidths.value
          .slice(0, currentSlide.value)
          .reduce((acc, width) => acc + width + props.gap, 0);
        return {
          left: `-${offset}px`,
          gap: `${props.gap}px`,
        };
      } else {
        return {
          left: `-${(currentSlide.value * 100) / props.perPage}%`,
          gap: `${props.gap}px`,
        };
      }
    });

    const itemClass = computed(() => {
      return (props.slidesAutoWidth || props.perPage === 1) ? 'flex-none' : 'flex-shrink-0';
    });

    const itemStyle = computed(() => {
      if (props.fixedSlideWidthValue) {
        return {
          width: `${props.fixedSlideWidthValue}px`,
        };
      } else if (props.slidesAutoWidth) {
        return {
          width: 'auto',
        };
      } else {
        return {
          width: `calc(${100 / props.perPage}% - ${props.gap}px)`,
        };
      }
    });

    const calculateSlideWidths = (): void => {
      if (track.value) {
        slideWidths.value = Array.from(track.value.children).map((child: HTMLElement) => child.offsetWidth);
      }
    };
    const nextSlide = () => {
      if (props.fixedSlideWidthValue && track.value) {
        if ((currentSlide.value + 1) * (props.fixedSlideWidthValue + props.gap) < track.value.scrollWidth) {
          currentSlide.value++;
        }
      } else if (props.slidesAutoWidth) {
        if (currentSlide.value < props.sliderItems.length - 1) {
          currentSlide.value++;
        }
      } else if (currentSlide.value < props.sliderItems.length - props.perPage) {
        currentSlide.value++;
      }
    };

    const prevSlide = () => {
      if (currentSlide.value > 0) {
        currentSlide.value--;
      }
    };
    const shouldDisplayControls = computed(() => props.arrows && props.sliderItems?.length > props.perPage);

    const shouldshowArrows = computed(() => shouldDisplayControls.value && !props.hideArrowsOnBoundaries);
    const onArrowClick = (arrowTypeClicked: SimpleCarouselArrow) => {
      if (arrowTypeClicked === SimpleCarouselArrow.prev) {
        prevSlide();
        emit('leftArrowClick');
      } else {
        nextSlide();
        emit('rightArrowClick');
      }
    };
    const isAtLeftBoundary = computed(() => currentTranslate.value >= 0 && currentSlide.value === 0);
    const isAtRightBoundary = computed(() => {
      const slideWidth = props.fixedSlideWidthValue || 0;
      if (props.fixedSlideWidthValue && track.value) {
        return (currentSlide.value + 1) * (slideWidth + props.gap) >= slideWidth;
      } else if (props.slidesAutoWidth) {
        return currentSlide.value >= props.sliderItems.length - 1;
      } else {
        return currentSlide.value >= props.sliderItems.length - props.perPage;
      }
    });

    const isDragging = ref(false);
    const startPosition = ref(0);
    const currentTranslate = ref(0);
    const previousTranslate = ref(0);

    const startDrag = (e: MouseEvent) => {
      if (!props.draggable) return;
      e.preventDefault();
      isDragging.value = true;
      track.value.style.transition = 'none';
      startPosition.value = e.pageX;
      previousTranslate.value = currentTranslate.value;
    };

    const onDrag = (e: MouseEvent) => {
      if (!isDragging.value) return;
      e.preventDefault();
      const currentPosition = e.pageX;
      if (isAtLeftBoundary.value && currentTranslate.value > previousTranslate.value) {
        return;
      }

      if (isAtRightBoundary.value && currentTranslate.value < previousTranslate.value) {
        return;
      }
      currentTranslate.value = previousTranslate.value + currentPosition - startPosition.value;
      track.value.style.transform = `translateX(${currentTranslate.value}px)`;
    };

    const endDrag = () => {
      if (!props.draggable || !slideRefs.value) return;
      isDragging.value = false;
      const activeItem = slideRefs.value.filter((element) => element.$el.classList.contains('active'))[0];
      if (!activeItem) return;
      const firstActiveItemId = parseInt(activeItem.$el.getAttribute('data-custom-id'));
      track.value.style.transition = 'transform 0.3s ease-out';
      if (firstActiveItemId !== currentSlide.value) {
        currentSlide.value = firstActiveItemId;
        if (firstActiveItemId === 0) {
          currentTranslate.value = 0;
        }
      }
    };

    const touchStartX = ref<number>(0);

    const handleTouchStart = (event:TouchEvent) => {
      touchStartX.value = event.touches[0].clientX;
    };

    const handleTouchMove = (event:TouchEvent) => {
      if (!touchStartX.value) return;
      const touchEndX = event.touches[0].clientX;
      const touchDiff = touchStartX.value - touchEndX;
      if (touchDiff > 0) {
        // touch move to left
        emit('left');
        nextSlide();
      } else {
        // touch move to right
        emit('right');
        prevSlide();
      }
      touchStartX.value = 0;
    };

    const scrollToSlide = (newIndex: number) => {
      currentSlide.value = newIndex;
    };

    watch(() => props.activeIndex, (newValue) => {
      scrollToSlide(newValue);
    });

    const handleItemClick = (index: number) => {
      emit('item-click', { index });
    };

    const handleIntersectionCallback = (entries: IntersectionObserverEntry[]): void => {
      entries.forEach((entry: IntersectionObserverEntry) => {
        if (entry.isIntersecting) {
          (entry.target).classList.add('active');
        } else {
          (entry.target).classList.remove('active');
        }
      });
    };

    const options = computed(() => ({
      root: carouselContainer.value,
      rootMargin: ROOT_MARGIN,
      threshold: THRESHOLD,
    }));

    const setupIntersectionObserver = (): void => {
      if (props.hasCustomFirstItem) {
        slideRefs.value.unshift(customFirstElement.value);
      }

      if (props.hasCustomLastItem) {
        slideRefs.value.push(customLastElement.value);
      }

      slideRefs.value.forEach(item => {
        useIntersectionObserver(item, handleIntersectionCallback, options.value);
      });
    };

    onMounted(async () => {
      if (props.slidesAutoWidth && process.client) {
        calculateSlideWidths();
        window.addEventListener('resize', calculateSlideWidths);
      }
      if (props.perPage === 1 && props.shouldScrollToSlideOnOpen) {
        await scrollToSlide(props.activeIndex);
      }

      setupIntersectionObserver();
    });

    onUnmounted(() => {
      window.removeEventListener('resize', calculateSlideWidths);
    });

    return {
      handleTouchStart,
      handleTouchMove,
      carouselHeight,
      controlClasses,
      carouselContainer,
      currentSlide,
      scrollToSlide,
      onArrowClick,
      shouldDisplayControls,
      SimpleCarouselArrow,
      handleItemClick,
      isAtLeftBoundary,
      isAtRightBoundary,
      trackStyle,
      itemClass,
      itemStyle,
      track,
      startDrag,
      onDrag,
      endDrag,
      slideRefs,
      customFirstElement,
      customLastElement,
      shouldshowArrows,
    };
  },
});
