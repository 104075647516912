

































import { defineComponent, computed } from '@nuxtjs/composition-api';
import { useTranslationCheck } from '~/composables';
import TrustpilotDynamic from '~/components/molecules/TrustPilot/TrustpilotDynamic/TrustpilotDynamic.vue';
import PhoneNumber from '~/components/atoms/PhoneNumber.vue';
import { PHONE_NUMBER_KEYS } from '~/constants/phoneNumber';

export default defineComponent({
  name: 'TrustpilotHeader',
  components: {
    TrustpilotDynamic,
    PhoneNumber,
  },
  setup () {
    const { hasTranslation } = useTranslationCheck();

    const showPhoneNumber = hasTranslation(PHONE_NUMBER_KEYS.CUSTOMER_CARE, { checkFallbacks: false });
    const showWorkingHours = hasTranslation('shopAdvisor.openingHours', { checkFallbacks: false }) && showPhoneNumber;
    const showContactInfo = computed(() => showPhoneNumber || showWorkingHours);

    const sliderHeight = 'h-8';

    return {
      PHONE_NUMBER_KEYS,
      showPhoneNumber,
      showWorkingHours,
      showContactInfo,
      sliderHeight,
    };
  },
});

