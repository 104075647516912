














import { defineComponent } from '@nuxtjs/composition-api';
import Button from '~/components/atoms/Buttons/Button/Button.vue';

export default defineComponent({
  name: 'VatIdNeedSupport',
  components: {
    Button,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['click'],
  setup(_props, { emit }) {
    const onClick = () => {
      emit('click');
    };
    return {
      onClick,
    };
  },
});
