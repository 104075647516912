import { extendModule } from '~/helpers/vuex/extendModule/extendModule';

interface VuexModuleOptions<T> {
  stateKey: string;
  initialValue: T;
  mutationName: string;
}

export default function createVuexModule<T>({ stateKey, initialValue, mutationName }: VuexModuleOptions<T>) {
  type State = {
    [key in typeof stateKey]: T;
  };

  const state = (): State => ({
    [stateKey]: initialValue,
  });

  const mutations = {
    [mutationName](state: State, payload: T) {
      state[stateKey] = payload;
    },
  };

  return extendModule({
    state,
    mutations,
  });
}
