const { gql } = require('graphql-tag');

module.exports = {
  fragment: gql`
    fragment DefaultVariant on ProductVariant {
      id
      sku
      ...Images
      ...Price
      ...Attributes
    }
  `,
};
