import { NuxtAxiosInstance } from '@nuxtjs/axios';
import { NuxtCookies } from 'cookie-universal-nuxt';
import getSimilarProducts from '~/plugins/integrations/bloomreach/request/getSimilarProducts';
import getLastSeen from '~/plugins/integrations/bloomreach/request/getLastSeen';
import { getAxiosConfig } from '~/helpers/axios/getAxiosConfig';
import { BloomreachIntegration } from '~/types/integrations/bloomreach/BloomreachIntegration.js';
import { BloomreachKeyByLanguage } from '~/types/localization/localizationHelperTypes';
import { setAxiosInterceptors } from '~/helpers/axios/setAxiosInterceptors';
import getCartRecommendations from '~/plugins/integrations/bloomreach/request/getCartRecommendations';

export default ({
  $axios,
  $cookies,
  languageAndCountry,
  bloomreachDetails,
}: {
  $axios: NuxtAxiosInstance,
  $cookies: NuxtCookies,
  languageAndCountry: string,
  bloomreachDetails?: BloomreachKeyByLanguage
}) => {
  const axios = $axios.create(
    getAxiosConfig((process?.server ? $axios.defaults.baseURL : '/') + 'bloomreach'),
  );

  setAxiosInterceptors({ axios, shouldRejectPromise: true });

  const $bloomreach = {
    services: {
      axios,
      cookies: $cookies,
    },
    state: {
      languageAndCountry,
      bloomreachDetails,
    },
  } as BloomreachIntegration;
  $bloomreach.getSimilarProducts = getSimilarProducts($bloomreach);
  $bloomreach.getLastSeen = getLastSeen($bloomreach);
  $bloomreach.getCartRecommendations = getCartRecommendations($bloomreach);
  return $bloomreach;
};
