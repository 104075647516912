import type {
  Cart,
  Order,
  DiscountedLineItemPortion,
  LineItem,
  DiscountCodeInfo,
  CartDiscount,
  DiscountCode,
} from '@vsf-enterprise/commercetools-types';
import type { CartDiscountValue } from '@commercetools/platform-sdk';
import { DiscountTarget, DiscountType, MappedDiscount } from '~/types/discounts';

export const mapCartDiscount = (cartDiscount: DiscountedLineItemPortion, quantity = 1): MappedDiscount => {
  const cartDiscountValue = cartDiscount?.discount?.value as CartDiscountValue;
  let discountValue = null;
  const discountType = cartDiscountValue?.type;
  const discountTargetType = (cartDiscount?.discount?.target?.type || null) as DiscountTarget;
  switch (cartDiscountValue?.type) {
    case DiscountType.Relative:
      discountValue = cartDiscountValue.permyriad / 100;
      break;
    case DiscountType.GiftLineItem:
      discountValue = null;
      break;
    case DiscountType.Fixed:
    case DiscountType.Absolute:
      discountValue = cartDiscountValue.money[0]?.centAmount / 100;
  }

  const discountedAmount =
    (cartDiscount.discountedAmount?.centAmount * quantity) / 100;
  return {
    id: cartDiscount?.discount?.id || '',
    name: cartDiscount?.discount?.name || '',
    type: discountType,
    target: discountTargetType,
    value: discountValue,
    discountedAmount,
    requiresDiscountCode: !!cartDiscount?.discount?.requiresDiscountCode,
  };
};

/* we don't use the getCartDiscounts to get the first code because
// getCartDiscounts search in cart.lineItems instead of card.discountCodes
There exists only info about the name(which could be diff from the code) and the price */
export const getFirstDiscountCode = (cartOrOrder: Cart | Order): DiscountCode['code'] => {
  return cartOrOrder?.discountCodes?.[0]?.discountCode?.code || '';
};

export const getCartDiscounts = (cart: Cart): MappedDiscount[] => {
  if (!cart) {
    return [];
  }
  const shippingDiscounts =
    cart?.shippingInfo?.discountedPrice?.includedDiscounts?.map(
      (shippingDiscount) => mapCartDiscount(shippingDiscount),
    ) || [];

  const cartDiscounts = parseCartDiscounts(cart.lineItems) || [];
  return cartDiscounts.concat(shippingDiscounts);
};

export const getDiscountCode = (discount: DiscountCodeInfo): DiscountCode['code'] => {
  return discount?.discountCode?.code || '';
};

export const discountCodeExists = (cart: Cart, promoCode: string) => {
  return !!cart?.discountCodes.find(discount => getDiscountCode(discount) === promoCode);
};

/**
 * @param cart The whole cart object
 * @param couponId The coupon ID
 * @returns Find the discount code which only exists in cart.discountCode
 * and not in lineItems as the other discount info
 */
export const getDiscountCodeByCouponId = (cart: Cart, couponId: CartDiscount['id']): DiscountCode['code'] => {
  const findCartDiscountItem = (discount: DiscountCodeInfo) => discount?.discountCode?.cartDiscounts?.find(c => c.id === couponId);
  return cart?.discountCodes?.find(discount => findCartDiscountItem(discount))?.discountCode?.code || '';
};

export const parseCartDiscounts = (lineItems: LineItem[]): MappedDiscount[] => lineItems?.reduce(
  (currentCartDiscounts, lineItem) => {
    for (const pricePerQuantity of lineItem.discountedPricePerQuantity) {
      const quantity = pricePerQuantity.quantity;
      for (const includedDiscount of pricePerQuantity.discountedPrice
        .includedDiscounts) {
        const discountedAmount =
          (includedDiscount.discountedAmount?.centAmount * quantity) / 100;
        const existingDiscount = currentCartDiscounts.find(
          (cartDiscount) => cartDiscount.id === includedDiscount?.discount?.id,
        );

        if (existingDiscount) {
          existingDiscount.discountedAmount += discountedAmount;
        } else {
          currentCartDiscounts.push(
            mapCartDiscount(includedDiscount, quantity),
          );
        }
      }
    }
    return currentCartDiscounts;
  },
  [],
);
