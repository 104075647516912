import { ShippingMethodDeliveryInformation } from '~/types/checkout/ShippingMethod';

const estimatedDeliveryDate = (languageAndCountry: string,
  shippingData?: ShippingMethodDeliveryInformation): string | undefined => {
  const rawValue = shippingData?.custom?.customFieldsRaw?.[0]?.value?.[languageAndCountry]?.replace(/ /g, '');
  const match = rawValue?.match(/\b\d+-(\d+)\b/);
  if (!match?.[1]) return;

  const date = new Date();
  date.setDate(date.getDate() + +match[1]);

  return date.toISOString().split('T')[0]; // Extracts 'yyyy-mm-dd' from ISO format
};

export default estimatedDeliveryDate;
