import { extendModule } from '~/helpers/vuex/extendModule/extendModule';
import type {
  ComplementaryProductsMutations,
  ComplementaryProductsState,
} from '~/types/vuex/ComplementaryProducts';
import { ProductVariantExtended } from '~/types/product/ProductVariantExtended';

const state = (): ComplementaryProductsState => ({
  complementaryProducts: [],
  complementaryBaseProduct: undefined,
  cacheId: '',
});

export function setComplementaryProducts(state: ComplementaryProductsState,
  complementaryProducts: ProductVariantExtended[]) {
  state.complementaryProducts = complementaryProducts;
}

export function setComplementaryBaseProduct(state: ComplementaryProductsState,
  complementaryBaseProduct: ProductVariantExtended) {
  state.complementaryBaseProduct = complementaryBaseProduct;
}

export function setCacheId(state: ComplementaryProductsState,
  cacheId: string) {
  state.cacheId = cacheId;
}

const mutations: ComplementaryProductsMutations = {
  setComplementaryProducts,
  setComplementaryBaseProduct,
  setCacheId,
};

export default extendModule({
  state,
  mutations,
});
