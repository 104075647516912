import { TagPromotion } from '~/types/integrations/tagManager/TagPromotion';

export class PromotionTags {
  ecommerce: {
    promoView?: {
      promotions: TagPromotion[]
    },
    promoClick?: {
      promotions: TagPromotion[]
    }
  } = {};

  assignViewPromotionValues (tagsPromotion: TagPromotion[]) {
    this.ecommerce.promoView = {
      promotions: tagsPromotion,
    };
  }

  assignClickPromotionValues (tagsPromotion: TagPromotion[]) {
    this.ecommerce.promoClick = {
      promotions: tagsPromotion,
    };
  }
}
