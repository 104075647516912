const { clientQuery } = require('../queries');
const { CUSTOM_QUERIES } = require('../../../../constants/customQueries.js');
const reviewsQuery = require('../../queries/reviews/reviewsQuery');
const handleCtApiError = require('../handleCtApiError');

/** @import {GetReviewParams, GetReviewResponse} from '~/utils/ct' */

/**
 * @param {GetReviewParams} search
 * @returns {string}
 */
const buildReviewWhere = (search) => {
  if (search?.productId) {
    return `target(id = "${search.productId}")`;
  }
  if (search?.where) {
    return search.where;
  }
  return '';
};

/**
 * @param {Context} context
 * @param {GetReviewParams} params
 * @param {Maybe<CustomQuery>} customQuery
 * @returns {GetReviewResponse}
 */
const getReview = async (context, params, customQuery) => {
  const defaultVariables = {
    where: buildReviewWhere(params),
    limit: params.limit,
    offset: params.offset,
    sort: params.sort,
  };

  const { reviews } = context.extendQuery(customQuery, {
    reviews: reviewsQuery[CUSTOM_QUERIES.REVIEWS.reviews]({
      variables: defaultVariables,
    }),
  });

  try {
    const request = await clientQuery(context, {
      query: reviews.query,
      variables: reviews.variables,
    });
    const extendedReviews = {
      ...request.data.reviews,
      limit: params?.limit || 0,
    };
    return extendedReviews;
  } catch (error) {
    handleCtApiError(error);
  }
};

module.exports = getReview;
