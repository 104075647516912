import { TagProduct } from '~/types/integrations/tagManager/TagProduct';
import {
  PurchaseOrderInformations,
  PurchaseAttributes,
  PurchaseCategory,
  PurchaseEventInfo,
  PurchaseEcommerceType,
} from '~/types/integrations/tagManager/PurchaseTypes';
import {
  EVENT_PAY_NOW,
  EVENT_PURCHASE_DEFAULT_EVENT_TYPE,
  PRIMARY_CATEGORY,
} from '~/constants/googleTagManager';

export class PayNowTags {
  private event: string = EVENT_PAY_NOW;
  private eventInfo: PurchaseEventInfo = {
    detail: EVENT_PAY_NOW,
  };

  private category: PurchaseCategory = {
    event_type: EVENT_PURCHASE_DEFAULT_EVENT_TYPE,
    primary_category: PRIMARY_CATEGORY,
  };

  private attributes?: PurchaseAttributes = undefined;
  private ecommerce: PurchaseEcommerceType = {
    value: undefined,
    tax: undefined,
    shipping: undefined,
    currency: undefined,
    coupon: undefined,
    payment_type: undefined,
    items: [],
  };

  assignOrderInformation (orderInformation: PurchaseOrderInformations) {
    this.ecommerce = {
      items: this.ecommerce.items,
      ...orderInformation,
    };
  }

  assignProductValues (tagProducts: TagProduct[]) {
    this.ecommerce.items = tagProducts;
  }

  assignAttributes (eventAttributes: PurchaseAttributes) {
    this.attributes = eventAttributes;
  }
}
