












import { defineComponent } from '@nuxtjs/composition-api';
import DisclaimerLogos from '~/components/atoms/DisclaimerLogos.vue';
import Copyright from '~/components/molecules/AppFooter/Copyright.vue';

export default defineComponent({
  name: 'Disclaimers',
  components: {
    Copyright,
    DisclaimerLogos,
  },
});
