























































import { defineComponent, onMounted } from '@nuxtjs/composition-api';
import { SfHeading } from '@storefront-ui/vue';
import LazyHydrate from 'vue-lazy-hydration';
import Button from '~/components/atoms/Buttons/Button/Button.vue';
import ImgView from '~/components/atoms/ImgView/ImgView.vue';
import { useInternalCms } from '~/composables';
import { COMPONENTS } from '~/constants/cms';
import { STATUS_RESOURCE_NOT_FOUND } from '~/constants/http';

export default defineComponent({
  name: 'ErrorLayout',
  components: {
    SfHeading,
    Button,
    ImgView,
    NoResults: () => import(/* webpackChunkName: "NoResults" */
      '~/components/atoms/NoResults/NoResults.vue'),
    LazyHydrate,
    USPBlock: () => import(/* webpackChunkName: "USPBlock" */ '~/cms/USPBlock.vue'),
  },
  props: { error: Object },
  setup(props) {
    const {
      loadComponent: departmentBlockSearch,
      internalCmsContent: departmentBlockContent,
      canShowContent: isDepartmentBlockExists,
    } = useInternalCms(COMPONENTS.SHOP_BY_DEPARTMENT.SLUG);
    const {
      loadComponent: trustedBrandsSearch,
      internalCmsContent: trustedBrandsContent,
      canShowContent: isTrustedBrandsExists,
    } = useInternalCms(COMPONENTS.TRUSTED_BRANDS.SLUG);

    // If we are getting 404 error the onSSR hook isn't firing, so we use onMounted instead
    onMounted(() => {
      if (props.error.statusCode === STATUS_RESOURCE_NOT_FOUND) {
        departmentBlockSearch();
        trustedBrandsSearch();
      }
    });

    return {
      departmentBlockContent,
      trustedBrandsContent,
      STATUS_RESOURCE_NOT_FOUND,
      isDepartmentBlockExists,
      isTrustedBrandsExists,
    };
  },
});
