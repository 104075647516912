import { useUserBilling, userBillingGetters } from '@vsf-enterprise/commercetools';
import { computed } from '@nuxtjs/composition-api';
import { sharedRef } from '@vue-storefront/core';
import { getCurrentCountryAddresses } from '~/helpers/getCurrentCountryAddresses';
import { BillingAddressType } from '~/types/checkout/BillingAddressType';
import { useCookies } from '~/composables';
import getUserAddressErrorOccurred from '~/helpers/getUserAddressErrorOccurred';

export default function () {
  const {
    billing,
    addAddress,
    deleteAddress,
    updateAddress,
    load,
    setDefaultAddress,
    loading,
    error,
  } = useUserBilling();

  const { country } = useCookies();

  const errorOccurred = computed(() => {
    return getUserAddressErrorOccurred(error.value);
  });

  const currentCountryBillingAddresses = computed(() => {
    return getCurrentCountryAddresses(billing.value, userBillingGetters, country);
  });

  const billingAddressType = sharedRef(BillingAddressType.NEW_BILLING_ADDRESS);
  const setBillingAddressType = (addressType: BillingAddressType) => {
    billingAddressType.value = addressType;
  };

  return {
    billing,
    addAddress,
    deleteAddress,
    updateAddress,
    load,
    setDefaultAddress,
    loading,
    error,
    currentCountryBillingAddresses,
    billingAddressType,
    setBillingAddressType,
    errorOccurred,
  };
}
