import type { IVueI18n } from 'vue-i18n';
import VueI18n from 'vue-i18n';
import { domainNewPrefix } from '~/constants/domainNewPrefix';
import { STATUS_RESOURCE_NOT_FOUND } from '~/constants/http';
import { adjustHostnameForPreProduction } from '~/helpers/env/preprod';
import { i18nToCurrentDomain } from '~/helpers/locales/i18nCurrentDetails';

interface MetaTitleParams {
  title: string | VueI18n.TranslateResult;
  i18n: IVueI18n;
  statusCode?: number;
}

export const generateMetaTitleForInvalidPage = (i18n: IVueI18n): string => {
  return i18n.t('page-not-found').toString();
};

export const generateMetaTitleWithCTFormat =
  ({ title, i18n, statusCode }: MetaTitleParams): string => {
    if (statusCode === STATUS_RESOURCE_NOT_FOUND) {
      return generateMetaTitleForInvalidPage(i18n);
    }
    const currentDomain = i18nToCurrentDomain(i18n);
    if (!currentDomain) {
      return `${title.toString()}`;
    }
    const domainSplitted = currentDomain.split(domainNewPrefix);
    // domain after removing the new. in front
    const domainAfterRemovingPrefix = domainSplitted[domainSplitted.length - 1];
    const normalisedDomain = adjustHostnameForPreProduction(domainAfterRemovingPrefix);
    return `${title.toString()} | ${normalisedDomain}`;
  };
