const { validatePostalCode } = require('../../../../helpers/validatePostalCode');

const validateAddress = (address) => {
  return validatePostalCode(address.country, address.postalCode);
};

module.exports.validateAddresses = (requestActions) => {
  requestActions.forEach(action => {
    if (action.setBillingAddress && !validateAddress(action.setBillingAddress.address)) {
      throw new Error('Postal code is incorrect in billing address');
    }
    if (action.setShippingAddress && !validateAddress(action.setShippingAddress.address)) {
      let address = '';
      try {
        address = JSON.stringify(action.setShippingAddress.address);
      } catch (error) {
        address = '[Unable to convert address to JSON]';
      }
      throw new Error(`Postal code is incorrect in shipping address: ${address}`);
    }
  });
};
