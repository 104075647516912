import { computed } from '@nuxtjs/composition-api';
import { defineModule } from '~/helpers/vuex/defineModule/defineModule';
import {
  PaymentSelectedMutations,
} from '~/types/vuex/PaymentSelected';
import { MODULE_NAMES } from '~/types/vuex/Vuex';
import CustomPayment from '~/constants/customPayment';

export default function () {
  const store = defineModule<
    typeof MODULE_NAMES.PAYMENT_SELECTED_MODULE_NAME,
    PaymentSelectedMutations,
    {},
    {}
  >(MODULE_NAMES.PAYMENT_SELECTED_MODULE_NAME);

  const paymentSelected = computed(() => store.state.paymentSelected);

  const setPaymentSelected = (paymentSelected: ValueOf<typeof CustomPayment>) => {
    store.mutations.setPaymentSelected(paymentSelected);
  };

  return {
    paymentSelected,
    ...store.getters,
    ...store.mutations,
    ...store.actions,
    setPaymentSelected,
  };
}
