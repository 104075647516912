const { gql } = require('graphql-tag');

module.exports = {
  fragment: gql`
    fragment DefaultProductPrice on ProductPrice {
      discounted {
        value {
          type
          currencyCode
          centAmount
          fractionDigits
        }
        discount {
          validFrom
          validUntil
          isActive
          name(acceptLanguage: $acceptLanguage)
          id
          value {
            type
            ... on AbsoluteDiscountValue {
              money {
                currencyCode
                centAmount
                fractionDigits
              }
            }
            ... on RelativeDiscountValue {
              permyriad
            }
          }
        }
      }
      value {
        type
        currencyCode
        centAmount
        fractionDigits
      }
    }
  `,
};
