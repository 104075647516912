export const SIMPLE_CAROUSEL_BREAKPOINTS = {
  SM: 640,
  MD: 768,
  LG: 1023,
  XL: 1200,
  '2XL': 1440,
  '3XL': 1919,
};

export const RELATED_PRODUCTS_CONFIGURATION = {
  [SIMPLE_CAROUSEL_BREAKPOINTS.SM]: {
    perPage: 1.5,
    gap: 20,
    draggable: true,
  },
  [SIMPLE_CAROUSEL_BREAKPOINTS.MD]: {
    perPage: 3,
    gap: 20,
    draggable: true,
  },
  [SIMPLE_CAROUSEL_BREAKPOINTS.LG]: {
    perPage: 3,
    gap: 30,
    draggable: true,
  },
  [SIMPLE_CAROUSEL_BREAKPOINTS['2XL']]: {
    perPage: 3,
    gap: 30,
    draggable: false,
  },
  [SIMPLE_CAROUSEL_BREAKPOINTS['3XL']]: {
    perPage: 3,
    gap: 30,
    draggable: false,
  },
};

export const ROOT_MARGIN = '0%';
export const THRESHOLD = 0.5;
