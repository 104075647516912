import { ClickFilter } from '~/plugins/integrations/tagManager/helpers/ClickFilter';
import { Gtm } from '~/types/@nuxtjs/gtm';
import { TriggerClickFilterParameters } from '~/types/integrations/tagManager/TagManagerIntegration';

export const triggerClickFilterWrapper = ($gtm: Gtm) => {
  return (parameters: TriggerClickFilterParameters) => {
    const clickFilter = new ClickFilter({
      filterInteraction: parameters.filterInteraction,
      filterSection: parameters.filterSection,
      filterName: parameters.filterName,
      filterValue: parameters.filterValue,
      categoryPath: parameters.categoryPath,
      categoryName: parameters.categoryName,
      listingType: parameters.listingType,
    });
    $gtm.push(clickFilter);
  };
};
