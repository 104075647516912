
import { countryCodes } from '~/i18n/enums/countryCodes';
import { CountryCodeValue } from '~/types/localization/localizationHelperTypes';

/**
 * IMPORTANT: If you add a country to the list below,
 * make sure to add the disclaimer translation for that country/language too!
 */
const PRICE_DISCLAIMER_COUNTRIES: Array<CountryCodeValue> = [
  countryCodes.AT,
  countryCodes.BE,
  countryCodes.BG,
  countryCodes.CZ,
  countryCodes.DE,
  countryCodes.DK,
  countryCodes.ES,
  countryCodes.FI,
  countryCodes.FR,
  countryCodes.GR,
  countryCodes.HR,
  countryCodes.HU,
  countryCodes.IE,
  countryCodes.IT,
  countryCodes.LT,
  countryCodes.NL,
  countryCodes.PL,
  countryCodes.PT,
  countryCodes.RO,
  countryCodes.SE,
  countryCodes.SI,
  countryCodes.SK,
];

export { PRICE_DISCLAIMER_COUNTRIES };
