import { useVSFContext } from '@vue-storefront/core';
import type { Route } from 'vue-router';
import type { IVueI18n } from 'vue-i18n';
import type { Ref } from '@nuxtjs/composition-api';
import { DEFAULT_REDIRECTION_CODE } from '~/constants/seo';
import { i18nToCurrentDomain } from '~/helpers/locales/i18nCurrentDetails';
import { EXPONDO_MIDDLEWARE_ROUTES } from '~/constants/routes';
import { RedirectionData } from '~/types/composables/SeoRedirection';
import { adjustUrlForSeoRedirection } from '~/helpers/url/adjustUrlForSeoRedirection';
import { useExpondoMiddleware } from '~/composables';
import { AllowedMethods } from '~/types/expondoMiddleware';

const prepareSourceUrl = (i18n: IVueI18n, route: Ref<Route>): string => {
  const domain = i18nToCurrentDomain(i18n);
  if (!domain) return '';
  const currentRootDomain = adjustUrlForSeoRedirection(domain);
  const currentPath = route.value.path;
  return `${currentRootDomain}${currentPath}`;
};

export const useSeoRedirection = () => {
  const { route, i18n, redirect } = useVSFContext();
  const { makeMiddlewareRequest } = useExpondoMiddleware();

  const seoRedirection = async (): Promise<boolean> => {
    if (!process?.server) return false;
    try {
      const sourceUrl = prepareSourceUrl(i18n, route);
      const redirectionData = await makeMiddlewareRequest<RedirectionData>({
        endpoint: EXPONDO_MIDDLEWARE_ROUTES.SEO_REDIRECTION,
        method: AllowedMethods.get,
        params: { sourceUrl },
      });
      if (redirectionData) {
        redirect(redirectionData.redirectionCode || DEFAULT_REDIRECTION_CODE, redirectionData.targetURL);
        return true;
      }
    } catch (_) {
      // Expected when no redirection exists
    }
    return false;
  };

  return {
    seoRedirection,
  };
};
