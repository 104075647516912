import type { Ref } from '@nuxtjs/composition-api';
import { ref } from '@nuxtjs/composition-api';
import { useNavigation } from '~/composables';

export const useNavigationState = () => {
  const navigationStore = useNavigation();
  const isNavigationOpen = navigationStore.isNavigationOpen;

  const toggleNavigation = () => {
    setFirstLevelCategory();
    navigationStore.toggleNavigation(!isNavigationOpen.value);
  };

  const setFirstLevelCategory = (slug: string = '') => {
    navigationStore.setCurrentFirstLevelCategorySlug(slug);
  };

  const currentFirstLevelCategorySlug = navigationStore.currentFirstLevelCategorySlug;
  const currentSecondLevelCategoryName: Ref<Maybe<string>> = ref('');
  const currentSecondLevelCategorySlug: Ref<Maybe<string>> = ref('');

  return {
    isNavigationOpen,
    toggleNavigation,
    currentFirstLevelCategorySlug,
    setFirstLevelCategory,
    currentSecondLevelCategoryName,
    currentSecondLevelCategorySlug,
  };
};
