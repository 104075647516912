const { gql } = require('graphql-tag');

module.exports = {
  fragment: gql`
    fragment Availability on ProductVariant {
      availability {
        noChannel {
            isOnStock
            availableQuantity
        }
       }
    }
  `,
};
