const buildOrderWhere = (search) => {
  if (search?.id) {
    return `id="${search.id}"`;
  }
  if (search?.orderNumber) {
    return `orderNumber="${search.orderNumber}"`;
  }
  return null;
};

module.exports = { buildOrderWhere };
