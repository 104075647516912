import type { ProductVariantExtended } from '~/types/product/ProductVariantExtended';
import type { ProductProcessedAttributes } from '~/types/product/attribute/ProductProcessedAttributes';
import type { ExtraGuaranteeAttributes } from '~/types/product/attribute/ProductExtraGuarantee';
import type { ProductAttributes } from '~/types/product/attribute/ProductAttributes';
import { productGetters } from '~/utils/ct';
import getAttributeLocales from '~/helpers/product/getAttributeLocales';

export const getProductAttributesExtended = (
  product: ProductVariantExtended,
  options: {
    attributeNames: ProductAttributes[] | ExtraGuaranteeAttributes[];
    languageAndCountry?: string;
  },
): ProductProcessedAttributes => {
  const { attributeNames, languageAndCountry } = options;

  const baseAttributes = productGetters.getAttributes(product, attributeNames);

  const localizedAttributes = languageAndCountry
    ? getAttributeLocales(baseAttributes, languageAndCountry)
    : baseAttributes;

  return {
    ...localizedAttributes,
  };
};
