import Currency from '../types/localization/currency';
import { currencyCodes } from './enums/currencyCodes';

const currencies: Record<ValueOf<typeof currencyCodes>, Currency> = {
  [currencyCodes.EUR]: new Currency(currencyCodes.EUR, 'Euro'),
  [currencyCodes.CHF]: new Currency(currencyCodes.CHF, 'CHF'),
  [currencyCodes.GBP]: new Currency(currencyCodes.GBP, 'Pound'),
  [currencyCodes.CZK]: new Currency(currencyCodes.CZK, 'Koruna', 'narrowSymbol'),
  [currencyCodes.DKK]: new Currency(currencyCodes.DKK, 'Krone', 'narrowSymbol'),
  [currencyCodes.SEK]: new Currency(currencyCodes.SEK, 'Krona', 'narrowSymbol'),
  [currencyCodes.HUF]: new Currency(currencyCodes.HUF, 'Forint'),
  [currencyCodes.RON]: new Currency(currencyCodes.RON, 'Leu'),
  [currencyCodes.NOK]: new Currency(currencyCodes.NOK, 'Krone'),
  [currencyCodes.PLN]: new Currency(currencyCodes.PLN, 'Zloty'),
  [currencyCodes.BGN]: new Currency(currencyCodes.BGN, 'Lev'),
  [currencyCodes.USD]: new Currency(currencyCodes.USD, 'U$D'),
};

export default currencies;
