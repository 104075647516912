















import { defineComponent } from '@nuxtjs/composition-api';
import Link from '~/components/atoms/Link/Link.vue';

export default defineComponent({
  components: {
    Link,
  },
  data() {
    return {
      social: [
        { page: 'svg--youtube', url: 'https://www.youtube.com/channel/UCLXBm1xvD5h_KRWO1bzhrTw' },
        { page: 'svg--facebook', url: 'https://www.facebook.com/expondo/' },
        { page: 'svg--linkedin', url: 'https://de.linkedin.com/company/expondo' },
        { page: 'svg--xing', url: 'https://www.xing.com/pages/expondogmbh' },
      ],
    };
  },
});
