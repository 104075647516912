import { useUserShipping, userShippingGetters } from '@vsf-enterprise/commercetools';
import { computed } from '@nuxtjs/composition-api';
import { getCurrentCountryAddresses } from '~/helpers/getCurrentCountryAddresses';
import { useCookies } from '~/composables';
import getUserAddressErrorOccurred from '~/helpers/getUserAddressErrorOccurred';

export default function () {
  const {
    shipping,
    addAddress,
    deleteAddress,
    updateAddress,
    load,
    setDefaultAddress,
    loading,
    error,
  } = useUserShipping();

  const { country } = useCookies();
  const errorOccurred = computed(() => {
    return getUserAddressErrorOccurred(error.value);
  });

  const currentCountryShippingAddresses = computed(() => {
    return getCurrentCountryAddresses(shipping.value, userShippingGetters, country);
  });

  return {
    shipping,
    addAddress,
    deleteAddress,
    updateAddress,
    load,
    setDefaultAddress,
    loading,
    error,
    currentCountryShippingAddresses,
    errorOccurred,
  };
}
