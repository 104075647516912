import {
  useIntegrations,
  useProductForTagManager,
} from '~/composables';
import {
  ADD_TO_CART_EVENT_BUY_NOW,
} from '~/constants/googleTagManager';

type UseAddToCartTagManagerParams = {
  slug: string;
  addToCartText: string;
};

type TriggerTagsParams = {
  quantity: number;
  triggeredIn: string;
  shouldOpenCart?: boolean;
};

type UseAddToCartTagManagerReturn = {
  triggerTags: (params: TriggerTagsParams) => void;
};

export const useAddToCartTagManager = ({
  slug,
  addToCartText,
}: UseAddToCartTagManagerParams): UseAddToCartTagManagerReturn => {
  const { $tagManager } = useIntegrations();
  const { masterProduct: product, search: searchProduct } = useProductForTagManager();
  const triggerTags = ({
    quantity, triggeredIn, shouldOpenCart,
  }: TriggerTagsParams) => {
    const searchParams = { slug };
    searchProduct(searchParams).then(() => {
      if (product.value) {
        $tagManager.events.triggerAddToCartTags({
          product: product.value,
          quantity,
          textDisplayed: shouldOpenCart ? ADD_TO_CART_EVENT_BUY_NOW : addToCartText,
          triggeredIn,
          shouldOpenCart,
        });
      }
    });
  };

  return {
    triggerTags,
  };
};
