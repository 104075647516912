import getPostHogDistincId from '~/helpers/posthog/getPostHogDistincId';

export default ({
  app: {
    context: {
      route,
      $integrations: { $cia },
      $cookies,
    },
  },
  redirect,
}) => {
  // Handling old Shopware url's for confirming acceptance of newsletter
  // and showing manage subscriptions page without logging in
  switch (true) {
    case route.path.endsWith('/exConsents/confirm'):
      handleConfirmRequest(route, redirect, $cia, $cookies);
      break;
    case route.path.endsWith('/exConsents/manageSubscriptions'):
      handleMSPageRequest(route, redirect);
      break;
  }
};

function handleConfirmRequest(route, redirect, $cia, $cookies) {
  const {
    query: {
      hash,
      ...queryParams
    },
  } = route;
  if (!hash) {
    return redirect('/', queryParams);
  }
  const posthogDistinctId = getPostHogDistincId($cookies);
  if (posthogDistinctId) {
    $cia.mutations.setPosthogDistinctId(posthogDistinctId);
  }
  $cia.event.consent({ newsletter: true }, '', {}, hash);

  const stringStartWithLanguageCodeRegEx = /^\/[a-z][a-z]\//;
  const routePrefix = route.path.slice(0, 4);
  const languagePrefix = stringStartWithLanguageCodeRegEx.test(routePrefix) ? routePrefix : '/';
  const buffer = Buffer.from(hash, 'base64');
  const decodedHash = JSON.parse(buffer.toString('ascii'));
  if (decodedHash?.redirect_to) {
    return redirect(languagePrefix + decodedHash.redirect_to, queryParams);
  }
  return redirect('/', queryParams);
}

function handleMSPageRequest(route, redirect) {
  // TODO perform full redirection to new Management Subscription page with keeping all query parameters
  // After loading this page, hash from url will be used to fetch and save user consents without logging in
  return redirect('/', route.query);
}
