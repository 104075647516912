import { computed } from '@nuxtjs/composition-api';
import { useShippingMethods, useI18n } from '~/composables';
import getDeliveryDate from '~/helpers/getDeliveryDate';
import { getProductShippingMethod } from '~/helpers/product/getProductShippingDetails';

export const useProductDeliveryDate = (shortFormat: boolean = false) => {
  const { loadMethodsWithoutId, methodsWithoutId } = useShippingMethods();
  const { languageAndCountry } = useI18n();

  const parcelShippingMethod = computed(() =>
    getProductShippingMethod(methodsWithoutId.value, false),
  );
  const freightShippingMethod = computed(() =>
    getProductShippingMethod(methodsWithoutId.value, true),
  );

  const parcelDeliveryDate = computed(() => getDeliveryDate(
    languageAndCountry.value, parcelShippingMethod.value, shortFormat),
  );
  const freightDeliveryDate = computed(() => getDeliveryDate(
    languageAndCountry.value, freightShippingMethod.value, shortFormat),
  );

  const search = async () => {
    await loadMethodsWithoutId();
  };

  return {
    search,
    parcelDeliveryDate,
    freightDeliveryDate,
  };
};
