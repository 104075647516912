export default function getAttributeLocales(attributesRaw: Object, languageAndCountry: string) {
  const result = {} as any;
  Object.keys(attributesRaw).forEach(key => {
    const rawAttribute = attributesRaw[key];

    const isAttributeLocalized = rawAttribute && typeof rawAttribute === 'object';
    if (!isAttributeLocalized) {
      result[key] = rawAttribute;
      return;
    }

    const rawAttributeLocalized = getByStringKey(rawAttribute, languageAndCountry);
    if (rawAttributeLocalized?.toString()?.startsWith('[')) {
      result[key] = JSON.parse(rawAttributeLocalized ?? '[]');
    } else {
      result[key] = rawAttributeLocalized;
    }
    if (rawAttributeLocalized === '{}') {
      result[key] = [];
    }
  });
  return result;
}

const getByStringKey = (obj: object, attributeKey: string): string | null => {
  for (const [key, value] of Object.entries(obj)) {
    if (key?.toString() === attributeKey) {
      return value;
    }
  }

  return null;
};
