import VueI18n from 'vue-i18n';
import { ROBOT_META_INSTRUCTION, SCHEMA_ITEM_PROP } from '../../constants/seo';
import COPYRIGHT_YEAR from '../../constants/copyrightYear';
import { EXPONDO_LOGO_DARK_PATH } from '../../constants/expondoLogoPath';
import { MetaTag } from './types/MetaTag';

export function generateMetaDescription(input: string | VueI18n.TranslateResult): MetaTag {
  return {
    hid: 'description',
    name: 'description',
    content: input.toString(),
  };
}
export function generateMetaRobots(isPageIndexed: boolean): MetaTag {
  return {
    hid: 'robots',
    name: 'robots',
    content: isPageIndexed ? 'index, follow' : 'noindex, nofollow',
  };
}

export function generateMetaCopyrightHolder(input: string): MetaTag {
  return {
    hid: SCHEMA_ITEM_PROP.COPYRIGHT_HOLDER,
    name: SCHEMA_ITEM_PROP.COPYRIGHT_HOLDER,
    itemprop: SCHEMA_ITEM_PROP.COPYRIGHT_HOLDER,
    content: input,
  };
}

export function generateMetaCopyrightYear(): MetaTag {
  return {
    hid: SCHEMA_ITEM_PROP.COPYRIGHT_YEAR,
    name: SCHEMA_ITEM_PROP.COPYRIGHT_YEAR,
    itemprop: SCHEMA_ITEM_PROP.COPYRIGHT_YEAR,
    content: COPYRIGHT_YEAR,
  };
}

export function generateMetaIsFamilyFriendly(): MetaTag {
  return {
    hid: SCHEMA_ITEM_PROP.IS_FAMILY_FRIENDLY,
    name: SCHEMA_ITEM_PROP.IS_FAMILY_FRIENDLY,
    itemprop: SCHEMA_ITEM_PROP.IS_FAMILY_FRIENDLY,
    content: 'True',
  };
}

export function generateMetaExpondoImage(): MetaTag {
  return {
    hid: SCHEMA_ITEM_PROP.IMAGE,
    name: SCHEMA_ITEM_PROP.IMAGE,
    itemprop: SCHEMA_ITEM_PROP.IMAGE,
    content: EXPONDO_LOGO_DARK_PATH,
  };
}

export function generateMetaTitleName(input: string): MetaTag {
  return {
    hid: SCHEMA_ITEM_PROP.NAME,
    name: SCHEMA_ITEM_PROP.NAME,
    itemprop: SCHEMA_ITEM_PROP.NAME,
    content: input,
  };
}

export function setRobotsInstructionAsIndexed(inputText: string): string {
  return inputText.replace(
    ROBOT_META_INSTRUCTION.NOINDEX_NOFOLLOW,
    ROBOT_META_INSTRUCTION.INDEX_FOLLOW,
  );
}

export function setRobotsInstructionAsNotIndexed(inputText: string): string {
  return inputText.replace(
    ROBOT_META_INSTRUCTION.INDEX_FOLLOW,
    ROBOT_META_INSTRUCTION.NOINDEX_NOFOLLOW,
  );
}
