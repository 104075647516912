import { currencyCodes } from '../../i18n/enums/currencyCodes';

class Currency {
  public style: string = 'currency';
  public name: string;
  constructor (
    public currency: ValueOf<typeof currencyCodes>,
    public label: string,
    public currencyDisplay: string = 'symbol',
  ) {
    /**
     * name attribute is necessary to be provided to nuxt config i18n
     * and currency is necessary to be provided to nuxt config vueI18n numberFormats
    */
    this.name = this.currency;
  }
}

export default Currency;
