import { BundleBuilderAddToCartTags } from '~/plugins/integrations/tagManager/helpers/BundleBuilderAddToCartTags';
import { Gtm } from '~/types/@nuxtjs/gtm';
import { BundleBuilderBaseParameter } from '~/types/integrations/tagManager/TagManagerIntegration';

export const triggerAddToCartBundleBuilderWrapper = ($gtm: Gtm) => {
  return (parameters: BundleBuilderBaseParameter) => {
    const bundleBuilderAddToCart = new BundleBuilderAddToCartTags(parameters);
    $gtm.push(bundleBuilderAddToCart);
  };
};
